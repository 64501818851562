import { Component, Input, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {ContentModel } from './../../models/content.model';
import { Observable } from 'rxjs';
import * as contentActions from './../../actions/content.actions';
import * as fromFeature from './../../reducers/content.reducer';

@Component({
    selector: 'lidl-lib-content-fe-list',
    templateUrl: './content-list.component.html',
    styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnChanges {

    @Input() filter;
    contents$: Observable<ContentModel[]>;

    constructor(public store: Store<fromFeature.AppState>) { }

    ngOnChanges() {
        if (this.filter === undefined) {
            console.log('filter is not defined');
            return;
        } else {
            this.contents$ = this.store.pipe(select(fromFeature.selectEntitiesByFilter, {filter: this.filter}));
        }
        this.store.dispatch(contentActions.getAllContent());
    }

}
