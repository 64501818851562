import { Component, Output, EventEmitter, OnInit, OnDestroy, Input } from '@angular/core';
import { PaymentOpionModel } from '../../models/payment-option.model';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../../reducers/payment.reducer';
import * as paymentActions from './../../actions/payment.actions';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import Debug from 'debug';
import { Router } from '@angular/router';
import { PaymentProcessStatusResponse } from '../../models/payment-process-status-response';
import { IPaymentStatusResponse } from '../../models/IPaymentStatusResponse';
import { PaymentProcessStatus } from '../../models/paymentProcessStatus.enum';
const debug = Debug('modeso:lidl-lib-payment-fe:CheckoutPaymentOptionsComponent');

@Component({
  selector: 'modeso-checkout-payment-options',
  templateUrl: './checkout-payment-options.component.html',
  styleUrls: ['./checkout-payment-options.component.scss']
})

export class CheckoutPaymentOptionsComponent implements OnInit {
  @Input() isPaymentDone: boolean;
  @Input() paymentOrderId: string;
  @Output() paymentStatus: EventEmitter<IPaymentStatusResponse> = new EventEmitter<IPaymentStatusResponse>();

  @Input() public set showLoadingSpinner(showLoading: boolean) {
    if (showLoading) {
      this.loading = true;
    }
  }

  paymentOptions$: Observable<PaymentOpionModel[]> = this.store.pipe(select(fromFeature.selectFeaturePaymentOptions));
  selectedOption: PaymentOpionModel = new PaymentOpionModel('', '');
  paymentProcessStatus: PaymentProcessStatusResponse = new PaymentProcessStatusResponse();
  loading = false;
  hasError = false;
  errorMessage = '';
  orderId: string;
  constructor(private store: Store<fromFeature.AppState>, private router: Router) {
  }

  onPaymentOptionChanged(paymentOptionId, paymentOptionName) {
    this.store.dispatch(paymentActions.onPaymentOptionsValidSelected(
      { payload: new PaymentOpionModel(paymentOptionId, paymentOptionName) }
    ));
  }

  ngOnInit(): void {
    this.store.dispatch(paymentActions.getAllPaymentOptions());
    this.getSelectedOption();
    if (this.isPaymentDone && this.paymentOrderId) {
      this.loading = true;
      this.orderId = this.paymentOrderId;
      this.getPaymentProcessStatus().subscribe((status: PaymentProcessStatusResponse) => {
        this.paymentProcessStatus = status;

        // if status is initialized only send request
        if (this.paymentProcessStatus.paymentProcessStatus === PaymentProcessStatus.INITIALIZED) {
          this.store.dispatch(paymentActions.getPaymentStatus({ orderId: this.orderId }));

        }
      });

      this.getPaymentStatus();
    }
  }

  async getPaymentStatus() {
    this.store.pipe(select(fromFeature.paymentStatus)).subscribe(paymentStatus => {
      if (paymentStatus) {
        this.loading = false;
        this.paymentStatus.emit(paymentStatus);
      }
      debug(paymentStatus);
    });
  }

  /**
   * check the selected payment option in the store
   */
  async getSelectedOption() {
    this.selectedOption = await this.store.pipe(select(fromFeature.selectPaymentOptionModel), take(1)).toPromise();
    debug(this.selectedOption);

  }

  getPaymentProcessStatus() {
    this.store.dispatch(paymentActions.getPaymentProcessStatus({ orderId: this.paymentOrderId }));
    return this.store.pipe(select(fromFeature.paymentProcessStatusFeature),
      filter((status: PaymentProcessStatusResponse) => status.paymentProcessStatus !== undefined || 'paymentProcessStatus' in status));
  }

}
