import { InjectionToken } from '@angular/core';
import { Config } from '@modeso/modeso-lib-core-fe';

export interface ModesoAuthConfig extends Config {
  serviceId: string;
  apiGateway: string;
}

export const ModesoAuthConfigService = new InjectionToken<ModesoAuthConfig>(
  'Config',
);
