/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./static-content.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../microservices/lidl-lib-content-fe/components/content/content.component.ngfactory";
import * as i3 from "../../microservices/lidl-lib-content-fe/components/content/content.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../microservices/lidl-lib-content-fe/components/static-content-list/static-content-list.component.ngfactory";
import * as i6 from "../../microservices/lidl-lib-content-fe/components/static-content-list/static-content-list.component";
import * as i7 from "./static-content.page";
import * as i8 from "@angular/router";
var styles_StaticContentPage = [i0.styles];
var RenderType_StaticContentPage = i1.ɵcrt({ encapsulation: 0, styles: styles_StaticContentPage, data: {} });
export { RenderType_StaticContentPage as RenderType_StaticContentPage };
export function View_StaticContentPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shop-content-block", [], null, null, null, i2.View_ShopContentComponent_0, i2.RenderType_ShopContentComponent)), i1.ɵdid(1, 573440, null, 0, i3.ShopContentComponent, [i4.Store], { contentId: [0, "contentId"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-static-content-list", [], null, null, null, i5.View_ShopStaticContentListComponent_0, i5.RenderType_ShopStaticContentListComponent)), i1.ɵdid(3, 573440, null, 0, i6.ShopStaticContentListComponent, [i4.Store], { filter: [0, "filter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.introRef; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.filter; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StaticContentPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-static-content", [], null, null, null, View_StaticContentPage_0, RenderType_StaticContentPage)), i1.ɵdid(1, 245760, null, 0, i7.StaticContentPage, [i1.Injector, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StaticContentPageNgFactory = i1.ɵccf("app-page-static-content", i7.StaticContentPage, View_StaticContentPage_Host_0, {}, {}, []);
export { StaticContentPageNgFactory as StaticContentPageNgFactory };
