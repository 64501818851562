import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AddressLocalStorageService } from '../../services/addressLocalStorageService.service';
import { Observable, of } from 'rxjs';
import { AddressModel } from './../../models/address.model';
import * as fromAddress from './../../reducers/address.reducer';
import { Store, select } from '@ngrx/store';
import Debug from 'debug';
import { AddressType } from '../../models/addressType.enum';
import { OrderResponse, OrderService } from '@modeso/giftcardshop-lib-order-fe';

const debug = Debug('modeso:lidl-lib-address-fe:CompactAddressComponent');

@Component({
  selector: 'modeso-compact-address',
  templateUrl: './compact-address.component.html',
  styleUrls: ['./compact-address.component.scss']
})
export class CompactAddressComponent implements OnInit {
  @Input() ChangeAddress;

  deliveryAddress$: Observable<AddressModel>;
  billingAddress$: Observable<AddressModel>;

  isSetBillingAddressId: boolean;
  isSetDeliveryAddressId: boolean;


  /**
   *
   * @param router
   * @param addressLocalStorageService
   * @param store
   */
  constructor(
    private router: Router,
    private addressLocalStorageService: AddressLocalStorageService,
    private store: Store<fromAddress.AppState>,
    private orderService: OrderService) { }

  async ngOnInit() {
    if(this.router.url.includes('order')) {
      const urlSegments = this.router.url.split('/');
      const orderId = urlSegments[urlSegments.indexOf('order') + 1]
      // this.store.dispatch(orderActions.getById({ orderId }));
      // this.orderStore.pipe(select(orderReducer.selectOrderByID, { id: orderId }))
      this.orderService.getById(orderId)
      .subscribe( (response: OrderResponse) => {
        this.isSetDeliveryAddressId = true;
        if(this.addressLocalStorageService.isBillingAddressSameAsDeliveryAddress() === false) {
          this.deliveryAddress$ = of(response.order.address[0]);
          this.billingAddress$ = of(response.order.address[1]);
          this.isSetBillingAddressId = true;
          this.addressLocalStorageService.removeBillingAddressSameAsDeliveryAddressFlag();
        } else {
          this.billingAddress$ = this.deliveryAddress$ = of(response.order.address[0]);
        }
      })
    } else {
      this.getDeliveryAddressById();
      this.getBillingAddressById();
    }
  }

  getDeliveryAddressById() {
    this.store.pipe(select(fromAddress.selectEntitiesByID,
      { id: AddressType.DELIVERYADDRESS })).subscribe( (address) => {
        this.isSetDeliveryAddressId = !!address;
        // if user tries to access payment page and address is not in store redirect back to address page to fill it
        if(!this.isSetDeliveryAddressId) {
          this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/address`);
        }
      });
    this.deliveryAddress$ = this.store.pipe(select(fromAddress.selectEntitiesByID,
      { id: AddressType.DELIVERYADDRESS }));
  }

  getBillingAddressById() {
    if (this.addressLocalStorageService.isBillingAddressSameAsDeliveryAddress() === false) {
      this.store.pipe(select(fromAddress.selectEntitiesByID,
        { id: AddressType.BILLINGADDRESS })).subscribe( (address) => {
          this.isSetBillingAddressId = !!address;
        });
      this.billingAddress$ = this.store.pipe(select(fromAddress.selectEntitiesByID,
        { id: AddressType.BILLINGADDRESS }));
    } else {
      this.isSetBillingAddressId = false;
    }
  }

  /**
   * Check if there is a currentLanguage in localstorage will return it else will return 'de-ch' as a default language.
   */
  private getLanguageFromLocalStorage(): string {
    return localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : `de-ch`;
  }


}
