import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class JWTService {
  decodeJwt(jwtToken: string) {
    return jwt_decode(jwtToken);
  }

  getCheckInDateFromJWT(jwtToken: string) {
    const decodedToken  =  this.decodeJwt(jwtToken) as {checkInDate: string};
    return decodedToken.checkInDate;
  }

}
