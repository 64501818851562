import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModesoCartConfigService } from '../cart.config';
import { CartResponseModel } from '../models/response/cart-response.model';
import { Observable } from 'rxjs';
import { CartLocalStorageService } from './cartLocalStorageService.service';
import { CartProductModel } from '../models/cart-product.model';
import { ProductUpdateRequestModel } from '../models/request/product-update-request.model';
import { ProductRemoveRequestModel } from '../models/request/product-remove-request.model';
import { AddDeliveryToCartRequest } from '../models/request/add-delivery-to-cart-request';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private server = '';
  private microserviceName = 'cart';

  // tslint:disable-next-line: whitespace
  constructor(private http: HttpClient, @Inject(ModesoCartConfigService) private config,
    private cartLocalStorageService: CartLocalStorageService) {
    this.server = config.apiGateway;
  }

  addToCart(product: { payload: CartProductModel }) {
    const url = `${this.config.apiGateway}${this.microserviceName}/${this.cartLocalStorageService.getCartId()}`;
    return this.http.post<CartResponseModel>(url, product.payload);
  }

  addNewCart() {
    const url = `${this.config.apiGateway}${this.microserviceName}/`;
    return this.http.post<CartResponseModel>(url, {});
  }

  updateProductInCart(product: { payload: ProductUpdateRequestModel }): Observable<any> {
    const url = `${this.config.apiGateway}${this.microserviceName}s/${product.payload.cartId}/${product.payload._id}`;
    return this.http.put(url, product.payload);
  }

  getCartById(cartId: string) {
    const url = `${this.config.apiGateway}${this.microserviceName}/${cartId}`;
    return this.http.get(url);
  }

  removeProduct(request: { payload: ProductRemoveRequestModel }) {
    const url = `${this.config.apiGateway}${this.microserviceName}s/${request.payload.cartId}/${request.payload._id}`;
    return this.http.delete<CartResponseModel>(url);
  }

  addDeliveryOptionToCart(addDeliveryOptionToCartRequest: { payload: AddDeliveryToCartRequest }) {
    const url = `${this.config.apiGateway}${this.microserviceName}/${this.cartLocalStorageService.getCartId()}`;
    return this.http.put<CartResponseModel>(url, addDeliveryOptionToCartRequest.payload);
  }

}
