/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-alert.component";
var styles_ErrorAlertComponent = [i0.styles];
var RenderType_ErrorAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorAlertComponent, data: {} });
export { RenderType_ErrorAlertComponent as RenderType_ErrorAlertComponent };
export function View_ErrorAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "alert alert-danger"], ["role", "alert"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modeso-error-alert", [], null, null, null, View_ErrorAlertComponent_0, RenderType_ErrorAlertComponent)), i1.ɵdid(1, 114688, null, 0, i2.ErrorAlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorAlertComponentNgFactory = i1.ɵccf("modeso-error-alert", i2.ErrorAlertComponent, View_ErrorAlertComponent_Host_0, { errorMessage: "errorMessage" }, {}, []);
export { ErrorAlertComponentNgFactory as ErrorAlertComponentNgFactory };
