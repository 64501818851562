import { NgModule } from '@angular/core';
// import { StoreModule } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';
// import { DeliveryService } from './services/delivery.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
// import * as fromDelivery from './reducers/delivery.reducer';
// import { DeliveryEffects } from './effects/delivery.effects';
import { DeliveryConfig, DeliveryConfigService } from './delivery.config';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { DeliveryOptionComponent } from './components/delivery-option/delivery-option.component';

@NgModule({
  declarations: [DeliveryOptionComponent],
  // providers: [DeliveryService],
  imports: [
    CommonModule,
    InjectedTextFeautureModule,
    // StoreModule.forFeature(fromDelivery.featureKey, fromDelivery.reducer),
    // EffectsModule.forFeature([DeliveryEffects])
  ],
  exports: [DeliveryOptionComponent]
})
export class DeliveryModule {
  static forRoot(config: DeliveryConfig): ModuleWithProviders {
    return {
      ngModule: DeliveryModule,
      providers: [
        // DeliveryService,
        {
          provide: DeliveryConfigService,
          useValue: config
        }
      ]
    };
  }
}
