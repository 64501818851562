import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
// import { Store, select } from '@ngrx/store';
// import * as fromFeature from './../../reducers/delivery.reducer';
// import * as fromActions from './../../actions/delivery.actions';
import { DeliveryOptionModel } from '../../models/deliveryOption.model';
// import { Observable } from 'rxjs';
import Debug from 'debug';
// import { HttpErrorResponse } from '@angular/common/http';
const debug = Debug('modeso:lidl-lib-delivery-fe:DeliveryOptionComponent');

@Component({
  selector: 'lidl-lib-delivery-fe-select-option',
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.scss']
})
export class DeliveryOptionComponent implements OnInit, OnChanges {


  // TODO can be deleted currentDate and date?
  // currentDate: Date = new Date();
  // date: any;
  public currentSelectedDeliveryOption: DeliveryOptionModel;
  @Input() deliveryOptions: DeliveryOptionModel[] = [];
  check: boolean;
  /** delegate method to notify about the change of delivery option */
  // @Input() onChangeAction: (deliveryOption: DeliveryOptionModel) => void;

  @Output() selectedDeliveryOption = new EventEmitter<number>();
  // private _selectedDeliveryOption: DeliveryOptionModel;
  // get selectedDeliveryOption() {
  //   return this._selectedDeliveryOption;
  // }
  // @Input('selectedDeliveryOption')
  // set selectedDeliveryOption(value: DeliveryOptionModel) {
  //   debug('selectedDeliveryOption$ is set');
  //   this._selectedDeliveryOption = value;
  // }


  @Input() currentSelectedRadioButton: number = 0;
  // error$: Observable<HttpErrorResponse>;
  // constructor(private store: Store<fromFeature.AppState>) { }

  ngOnInit(): void {
    // this.check = true;

    // TODO set first delviery option by default if there is no selected delivery option
    // Must be done from outside the component, because the app state keeps the state which one is selected

    // this.deliveryOptions$ = this.store.pipe(select(fromFeature.selectDeliveryOptions));
    // this.store.dispatch(fromActions.getAll({ cartId: this.cartId }));
    // this.error$ = this.store.select(fromFeature.featureError);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.deliveryOptions.length === 1) {

      this.currentSelectedRadioButton = 0;
    }
    this.currentSelectedDeliveryOption = this.deliveryOptions[this.currentSelectedRadioButton];
    this.emitSelectedRadioButton(this.currentSelectedRadioButton);

  }

  // changeDeliveryOption(deliveryOption: DeliveryOptionModel) {
  //   if (this.onChangeAction !== undefined) {
  //     this.onChangeAction(deliveryOption);
  //   }
  // }

  emitSelectedRadioButton(index: number) {
    this.currentSelectedRadioButton = index;

    this.selectedDeliveryOption.emit(this.currentSelectedRadioButton);

  }

  isSelected(index: number) {

    if (index === this.currentSelectedRadioButton) {

      return true;
    }

    return false;

  }
}
