import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-static-content',
  templateUrl: './static-content.page.html',
  styleUrls: ['./static-content.page.scss']
})
export class StaticContentPage extends BasePageComponent implements OnInit {

  introRef: string;
  filter: string;
  constructor(injector: Injector, private route: ActivatedRoute) {
    super(injector);
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(routeParams => {
      this.introRef = routeParams.contentFilter + '-intro';
      this.filter = routeParams.contentFilter;
    });
  }

}
