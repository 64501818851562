/**
 * Payment Option Model that return
 */
export class PaymentOpionModel {
    /**
     * @param paymentId the option id.
     * @param paymentName the option name.
     */
    constructor(
      public paymentId: string,
      public paymentName: string
    ) { }
  }
