import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import * as fromFeature from '../reducers/auth.reducer';
import * as fromActions from '../actions/auth.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JWTService } from '../shared/services/jwt.service';
import { CookieService } from 'ngx-cookie-service';
const debug = Debug('auth:AuthProvider');

@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  constructor(public store: Store<fromFeature.AppState>,
              private cookieService: CookieService,
              private jwtService: JWTService) {}

  public getNewAccsessToken(): void {
    this.store.dispatch(fromActions.onGetNewAccesToken());
  }

  public getNewSession(): void {
    this.store.dispatch(fromActions.onGetNewSession());
  }

  public isAllowedToCheckout$(): Observable<boolean>{
    return this.store.pipe(
      select(fromFeature.selectCheckInate),
      map((checkInDate: Date) => {
        const savedCheckinDate = localStorage.getItem('checkInDate');
        // if store is empty load checkin date from local storage
        checkInDate = checkInDate ? checkInDate : (savedCheckinDate ?
          new Date(savedCheckinDate) : null);

        if (checkInDate === null || checkInDate === undefined) {
          return false;
        }
        const offset15min = 15 * 60 * 1000;
        try {
          if (
            new Date(checkInDate).getTime() + offset15min >
            new Date().getTime()
          ) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          debug(error);
          return false;
        }
      }),
    );
  }
}
