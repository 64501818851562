import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { CartOrchestrator } from '../../orchestrator/cart.orchestrator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { Cart, CartProductModel } from '@modeso/giftcardshop-lib-cart-fe';
import { ProductDetailsModel } from '@modeso/giftcardshop-lib-products-fe';
import { ProductNotificationModel } from '@modeso/giftcardshop-lib-cart-fe';

@Component({
  selector: 'app-page-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss']
})
export class ProductDetailPage extends BasePageComponent implements OnInit {

  public productId$: Observable<string>;
  public product$: Observable<ProductDetailsModel>;
  public id: string;
  public isProductAddedToCart: boolean = false;
  public cart$: Observable<Cart>;
  public cartProduct: CartProductModel;
  public hasError: boolean;
  public notificationMessage: string;
  public addToCartHasError: boolean = false;
  public showNotification = false;
  /**
   *
   * @param route
   * @param injector
   */
  constructor(
    private route: ActivatedRoute,
    injector: Injector,
    private router: Router,
    private appStateDispatcher: AppStateDispatcher,
    public cartOrchestrator: CartOrchestrator) {
    super(injector);
    this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, 'Zurück zur Übersicht', false);
    this.productId$ = this.route.params.pipe(map((params) => {
      if (params.id) {
        this.id = params.id;
        this.product$ = this.appStateDispatcher.getProductDetailsById$(this.id);
        this.subscriptions.push(this.product$.subscribe((product: ProductDetailsModel) => {
          if (!product) return;
          this.cartProduct = this.cartOrchestrator.constructCartProductFromProduct(product);
        }));
      }
      return this.id;
    }));

    this.cart$ = this.appStateDispatcher.getCart$();
    this.subscriptions.push(this.cart$.subscribe(data => {
      this.isProductAddedToCart = sessionStorage.getItem('isProductAddedToCart') ? true : false;
    }));
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
  }

  ngOnInit() {
    super.ngOnInit();
    this.clearSessionAndResetNotificationFlag();
  }

  handleProductNotificationEvent(notification: ProductNotificationModel) {
    if (notification) {
      this.notificationMessage = notification.message;
      this.hasError = notification.isError;
      this.showNotification = true;
      this.clearSessionAndResetNotificationFlag();
    } else {
      this.showNotification = false;
    }
  }
  clearSessionAndResetNotificationFlag() {
    sessionStorage.removeItem('isProductAddedToCart');
    this.isProductAddedToCart = false;
    this.addToCartHasError = false;
  }

  navigateToCart() {
    this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}/cart/${localStorage.getItem('cartId')}`);
  }
}
