/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../microservices/lidl-lib-content-fe/components/content/content.component.ngfactory";
import * as i3 from "../../microservices/lidl-lib-content-fe/components/content/content.component";
import * as i4 from "@ngrx/store";
import * as i5 from "./balance.page";
import * as i6 from "@angular/platform-browser";
var styles_BalancePage = [i0.styles];
var RenderType_BalancePage = i1.ɵcrt({ encapsulation: 0, styles: styles_BalancePage, data: {} });
export { RenderType_BalancePage as RenderType_BalancePage };
export function View_BalancePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shop-content-block", [], null, null, null, i2.View_ShopContentComponent_0, i2.RenderType_ShopContentComponent)), i1.ɵdid(1, 573440, null, 0, i3.ShopContentComponent, [i4.Store], { contentId: [0, "contentId"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [], [[8, "src", 5]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "balance-intro"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.balanceCheckUrl; _ck(_v, 3, 0, currVal_1); }); }
export function View_BalancePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-balance", [], null, null, null, View_BalancePage_0, RenderType_BalancePage)), i1.ɵdid(1, 245760, null, 0, i5.BalancePage, [i1.Injector, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalancePageNgFactory = i1.ɵccf("app-page-balance", i5.BalancePage, View_BalancePage_Host_0, {}, {}, []);
export { BalancePageNgFactory as BalancePageNgFactory };
