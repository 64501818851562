<ng-template #content let-modal>
  <h2 class="cookie-alert-extended-title">{{ "lidl-home-cookies-title-popup" | content:'text' | async }}</h2>

  <p class="cookie-alert-extended-description" [innerHTML]="'lidl-home-cookies-content' | content:'text' | async"></p>

  <div class="cookie-alert-extended-controls">
    <button (click)=toggleDisplayDiv() class="btn btn-link">{{"lidl-home-cookies-details" | content:'text' |
      async}}</button>

    <button (click)="modal.close()" (click)="agreeAll()" [disabled]="!isShowDiv" class="cookie-alert-extended-button">{{
      "lidl-home-cookies-button-accept" |
      content:'text' | async }}</button>

  </div>
  <div class="modal-footer">

    <div [hidden]="isShowDiv">

      <div class="cookie-alert-configuration-settings">
        <div class="cookie-alert-configuration-control">
          <input type="checkbox" class="cookie-alert-configuration-input" [disabled]="true" [checked]="true"
            id="technical" />
          <label class="cookie-alert-extended-checkbox-label"
            for="technical">{{"lidl-home-cookies-content-item-technical" | content:'text' |
            async}}</label>
        </div>

        <div class="cookie-alert-configuration-control">

          <input type="checkbox" class="cookie-alert-configuration-input" id="gkomfort" name="gkomfort"
            [(ngModel)]="gkomfort" />
          <label class="cookie-alert-extended-checkbox-label" for="gkomfort">{{"lidl-home-cookies-content-item-komfort"
            | content:'text' |
            async}}</label>

        </div>
        <div class="cookie-alert-configuration-control">
          <input type="checkbox" class="cookie-alert-configuration-input" id="gstatistic" name="gstatistic"
            [(ngModel)]="gstatistic" />
          <label class="cookie-alert-extended-checkbox-label"
            for="gstatistic">{{"lidl-home-cookies-content-item-statistik" | content:'text'
            | async}}</label>
        </div>
        <div class="cookie-alert-configuration-control">
          <input type="checkbox" class="cookie-alert-configuration-input" id="gmarketing" name="gmarketing"
            [(ngModel)]="gmarketing" />

          <label class="cookie-alert-extended-checkbox-label"
            for="gmarketing">{{"lidl-home-cookies-content-item-marketing" | content:'text'
            | async}}</label>
        </div>
      </div>

      <button (click)="agreeToShare()" class="cookie-alert-extended-button-secondary">{{
        "lidl-home-cookies-button-config" | content:'text' | async }}</button>

    </div>
  </div>
</ng-template>
