import { PaymentModel } from './payment.model';
import { Address } from './address.model';
import { Cart } from './cart.model';
import { DeliveryOption } from './DeliveryOption.model';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-order-fe:Order');

export class Order {
  // tslint:disable-next-line:variable-name
  _id: string;
  userId: string;
  totalPrice: number;
  paymentMethod: PaymentModel;
  deliveryOption: DeliveryOption;
  address: Address[];
  cart: Cart;
  status?: string;
  isMarkedForDigitalConfirmationMailSent?: boolean;
  constructor() { }

  setOrderModel(payment, cart, address, deliveryOption) {
    this.userId = localStorage.getItem('UID');
    this.deliveryOption = deliveryOption;
    const paymentObj = new PaymentModel();
    if (payment) {
      paymentObj.setPaymentModel(payment);
      this.paymentMethod = paymentObj;
    }
    const cartObj = new Cart();
    cartObj.setCartModel(cart);
    this.cart = cartObj;
    this.totalPrice = cart.totalPrice;
    this.address = [];
    address.forEach(add => {
      const addressObj = new Address();
      if (add) {
        addressObj.setAddressModel(add);
        this.address.push(addressObj);
      }
    });
  }

  setOrderModelNew(userId, payment, cart, cartTotal, address, deliveryOption) {
    this.userId = userId;
    this.deliveryOption = deliveryOption;
    const paymentObj = new PaymentModel();
    if (payment) {
      paymentObj.setPaymentModel(payment);
      this.paymentMethod = paymentObj;
    }
    this.address = [];
    address.forEach(add => {
      const addressObj = new Address();
      if (add) {
        addressObj.setAddressModel(add);
        this.address.push(addressObj);
      }
    });
    const cartObj = new Cart();
    cartObj.setCartModel(cart);
    this.cart = cartObj;
    this.totalPrice = cartTotal;
  }

  validateOrderModel(): boolean {
    if (this.userId && this.totalPrice && this.deliveryOption && (this.address.length === 1 || this.address.length === 2)) {
      const paymentValid = this.paymentMethod.validatePaymentModel();
      const cartValid = this.cart.validateCartModel();
      //TODO validate delivery option
      this.address.forEach(add => {
        if (!add.validateAddressModel()) {
          return false;
        }
      });
      return (paymentValid && cartValid) ? true : false;
    }
    debug('could not validate order model');
    return false;
  }
}


