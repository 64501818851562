// TODO: this must exist, but with only the display options, and the options that the cart can calculate everything
export class DeliveryOptionModel {
  /**
   * @param key key of delivery option
   * @param name name of delivery option
   * @param description description of delivery option (time to deliver)
   * @param price price of delivery option
   * @param vatTaxRate rate of tax (e.g. 7.7 it mean 7.7%)
   * @param _id id of the delivery option
   * @param currency currency of delivery option
   * @param sleected is this option selected or not. true means selected
   */
  constructor(
    public key: string,
    public name: string,
    public description: string,
    public price: number,
    public currency: string,
    public vatTaxRate: number,
    public _id: string,
    public selected: boolean
  ) { }
}
