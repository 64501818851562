export class ServerSideStorage implements Storage {

    map: Map<string, string>;
    length: number;

    constructor() {
        this.map = new Map<string, string>();
        this.length = this.map.size;
    }
    clear(): void {
        this.map.clear();
        this.length = this.map.size;
    }
    getItem(key: string): string {
        return this.map.get(key);
    }

    /**
     * 
     * @param index Should return the key for the index
     */
    key(index: number): string {
        // TODO check if this works
        return Array.from( this.map.keys())[index];
    }
    removeItem(key: string): void {
        this.map.delete(key);
        this.length = this.map.size;
    }
    setItem(key: string, value: string): void {
        this.map.set(key, value);
        this.length = this.map.size;
    }
}
