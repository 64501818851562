<footer class="footer">
    <div class="footer__prefooter">
        <div class="container">
            <div class="row">
                <div class="col-xs-4 service-block">
                    <span class="service-block__icon">
                        <img src="/assets/skeleton/footer/guest-order.png" />
                    </span>
                    <span class="service-block__link-text">
                        {{ "modeso_shop_prefooter_1" | localize | async }}
                    </span>
                </div>
                <div class="col-xs-4 service-block">
                    <span class="service-block__icon">
                        <img src="/assets/skeleton/footer/lastminute.png" />
                    </span>
                    <span class="service-block__link-text">
                        {{ "modeso_shop_prefooter_2" | localize | async }}
                    </span>
                </div>
                <div class="col-xs-4 service-block">
                    <span class="service-block__icon">
                        <img src="/assets/skeleton/footer/payment.png" />
                    </span>
                    <span class="service-block__link-text">
                        {{ "modeso_shop_prefooter_3" | localize | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer__mainfooter">
        <div class="container-sm">
            <div class="row footer-styling">
                <div class="col-lg-3 col-xs-3">
                    <p class="footer-title">{{ "modeso_shop_footer_section-1-sitemap" | localize | async }}</p>
                    <ul class="footer-list">
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-action' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-action" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-assortment' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-assortment" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-said-and-done' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-said-and-done" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-wine-moments' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-wine-moments" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-cuisine' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-cuisine" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-zero-foodwaste-kitchen' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-zero-foodwaste-kitchen" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-online' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-online" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                href="{{ 'modeso_shop_footer_section-1-item-extras' | content:'link' | async }}">{{ "modeso_shop_footer_section-1-item-extras" | content:'text' | async }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-xs-3 footer-titles">
                    <p class="footer-title">{{ "modeso_shop_footer_section-2-mobile-app" | localize | async }}</p>
                    <ul class="footer-list">
                        <li class="footer-list-items icon-list">
                            <a href="{{ 'modeso_shop_footer_section-2-item-app-store' | content:'link' | async }}">
                                <span class="icon large-icon">
                                    <img src="{{ 'modeso_shop_footer_section-2-item-app-store' | content:'source' | async }}"
                                        alt="">
                                </span>
                            </a>
                        </li>
                        <li class="footer-list-items icon-list">
                            <a href="{{ 'modeso_shop_footer_section-2-item-play-store' | content:'link' | async }}">
                                <span class="icon large-icon">
                                    <img src="{{ 'modeso_shop_footer_section-2-item-play-store' | content:'source' | async }}"
                                        alt="">
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-xs-3 footer-titles">
                    <p class="footer-title">{{ "modeso_shop_footer_section-3-legal" | localize | async }}</p>
                    <ul class="footer-list">
                        <li class="footer-list-items">
                            <a target="_blank"
                                href="{{ 'modeso_shop_footer_section-3-item-imprint' | content:'link' | async }}">{{ "modeso_shop_footer_section-3-item-imprint" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                routerLink="{{ 'modeso_shop_footer_section-3-item-general-terms' | content:'link' | async }}">{{ "modeso_shop_footer_section-3-item-general-terms" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                routerLink="{{ 'modeso_shop_footer_section-3-item-data-protection' | content:'link' | async }}">{{ "modeso_shop_footer_section-3-item-data-protection" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a
                                routerLink="{{ 'modeso_shop_footer_section-3-item-usage-terms' | content:'link' | async }}">{{ "modeso_shop_footer_section-3-item-usage-terms" | content:'text' | async }}</a>
                        </li>
                        <li class="footer-list-items">
                            <a target="_blank"
                                href="{{ 'modeso_shop_footer_section-3-item-compliance' | content:'link' | async }}">{{ "modeso_shop_footer_section-3-item-compliance" | content:'text' | async }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-xs-3 footer-titles">
                    <p class="footer-title">{{ "modeso_shop_footer_section-4-contact" | localize | async }}</p>
                    <ul class="footer-list">
                        <li class="footer-list-items">
                            {{ "modeso_shop_footer_section-4-item-hotline" | content:'text' | async }} <br>
                            {{ "modeso_shop_footer_section-4-item-hotline" | content:'value' | async }}
                        </li>
                        <li class="footer-list-items">
                            <a href="mailto:hotline@lidl.ch">hotline@lidl.ch</a>
                        </li>
                        <li class="footer-list-items mr-top">
                            {{ "modeso_shop_footer_section-4-opening-hours" | localize | async}}
                        </li>
                        <li class="footer-list-items">
                            ({{ "modeso_shop_footer_section-4-holidays-excluded" | localize | async}})
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col" [innerHTML]="'modeso_shop_footer_service-of-bhn' | localize | async"></div>
            </div>
        </div>
    </div>
</footer>