import { Component, Input } from '@angular/core';

@Component({
  selector: 'lidl-lib-order-fe-sub-title',
  templateUrl: './order-sub-title.component.html',
  styleUrls: ['./order-sub-title.component.scss']
})
export class OrderSubTitleComponent {
  @Input() subTitleText;

  constructor() { }
}
