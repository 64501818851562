import { Injectable } from '@angular/core';
import * as fromReducers from '../reducers/localization.reducer';
import * as fromActions from '../actions/localization.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationModel } from './../models/localization.model';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizationStoreProvider');

@Injectable({
  providedIn: 'root'
})
export class LocalizationStoreProvider {
  private observers: Set<any>;

  constructor(public store: Store<fromReducers.AppState>) {
    // Attention: this.loadLocalizations() cannot be called here, because the language is not known.
    // it must be called from the project using this microservice
    this.observers = new Set<any>();
  }

  public loadLocalizations() {
    this.store.dispatch(fromActions.getLocalizations());
  }

  // TODO add a method to access the store property by key, which generates an observable which can be used in a pipe
  // Is this the solution?
  public getLocalizedStringForKey(key: string): Observable<string> {
    return this.store.pipe(
      select(fromReducers.selectEntitiesByID, { id: key }),
      map((localization: LocalizationModel) => {
        debug('got ' + key);
        debug(localization);
        if (localization) {
          debug('return ' + localization.text);
          return localization.text;
        } else {
          // TODO this must return the undefined
          // key in case we are in development mode.
          // TODO In production it should always
          // return "" if the key is not defined.
          // or return a default value if possible
          // return 'UNDEFINED KEY: ' + key;
          return undefined;
        }
      }));
  }

  public addObserver(namespace: string, observer: any) {
    //TODO use the dependecy localization, if added
    debug('observer added to localization... ' + namespace);
    this.observers.add(observer);
    debug(this.observers.size);
  }

  public notifyObservers(localizations: Array<LocalizationModel>) {
    debug('noify observers');
    debug(this.observers.size);
    this.observers.forEach(observer => {
      observer.setInjectedText(localizations);
    });
  }
}
