import { CountriesJson } from '../shared/countriesJson';
export class AddressUtils {

    public static countries = CountriesJson;
    public static getCountryNameByCode(countryCode: string) {
        const selectedCountry  = AddressUtils.countries.find(country => country.code === countryCode);
        if (selectedCountry) {
            return selectedCountry.name[this.getCurrentLanguage()];
        }
    }
    public static getCurrentLanguage(): string {
        return localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : `de-ch`;
    }
    constructor() {
    }
}
