import { createAction, props, Action } from '@ngrx/store';
import { NamedString } from './namedString.model';

export const getLocalizations = createAction('[modeso-core] getLocalizations');
export const onLocalizationsLoadedSuccessfully = createAction(
    '[modeso-core] all localizations loaded successfully',
    props<{payload: Array<NamedString>}>()
);
export const onLocalizationsLoadingFailed = createAction(
    '[modeso-core] all localizations loading failed',
    props<{payload: any}>()
);
export const getLocalizationByKey = createAction(
    '[modeso-core] getLocalizationByKey',
    props<{key: string}>()
);
export const onLocalizationByKeyLoadedSuccessfully = createAction(
    '[modeso-core] LocalizationByKey loaded successfully',
    props<{payload: NamedString}>()
);
export const onLocalizationByKeyLoadingFailed = createAction(
    '[modeso-core] LocalizationByKey loading failed',
    props<{payload: any}>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}

export interface ActionWithContentId extends Action {
    key: string;
}
