import { createAction, props, Action } from '@ngrx/store';
import { ProductDetailsModel } from '../models/product-details.model';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */

/**
 * We can change the product details state using the actions.
 * This is action of product details.
 */
export const getProductById = createAction('[Product] getProductById', props<{ productId: string }>());

/**
 * This is action of product details when it return successfully.
 */
export const onProductDetailsLoadedSuccessfully = createAction(
  '[Product Detail]  productById loaded successfully',
  props<{ payload: ProductDetailsModel }>()
);

/**
 * This is action of product details when it failed.
 */
export const onProductDetailsLoadingFailed = createAction(
  '[Product Detail]  productById loading failed',
  props<{ payload: any }>()
);

/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export interface ActionWithProductId extends Action {
  productId: string;
}
