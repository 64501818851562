import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModesoAddressConfigService } from './../address.config';
import { AddressModel } from '../models/address.model';
import { AddressLocalStorageService } from './addressLocalStorageService.service';


export interface AddressResponse {
  address: AddressModel;
}

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private server = '';
  private microserviceName = 'address';
  private readonly route = '/';

  /**
   *
   * @param http
   * @param config
   * @param addressLocalStorageService
   */
  constructor(
    private http: HttpClient, @Inject(ModesoAddressConfigService) private config,
    private addressLocalStorageService: AddressLocalStorageService) {
    this.server = config.apiGateway;

  }

}
