import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as productActions from './../actions/products.actions';
import { ProductModel } from '../models/product.model';
import { ProductDetailsModel } from '../models/product-details.model';

export const productsFeatureKey = 'modesoProductsMicroservice';

// State Declarations - START

export interface FeatureState {
    products: Array<ProductDetailsModel>;
    productsByCategory: Array<ProductDetailsModel>;
}

export interface AppState {
    modesoProductsMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoProductsMicroservice;
export const selectFeatureProducts = createSelector(
    selectFeature,
    (state: FeatureState) => state.products
);

/**
 * selector to get product by id from products array of the store
 */
export const selectProductById = createSelector(
    selectFeature,
    (entities, props) => {
        return entities.products.find(product => product.productId === props.id);
    }
);

export const selectProductsByCategory = createSelector(
    selectFeature,
    (entities) => {
        return entities.productsByCategory;
    }
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    products: new Array<ProductDetailsModel>(),
    productsByCategory: new Array<ProductDetailsModel>()
};

const productsReducer = createReducer(
    initialState,
    on(productActions.getAllProducts, state => ({ ...state })),
    on(productActions.onAllProductsLoadedSuccessfully, (state, action) => ({ ...state, products: action.payload })),
    on(productActions.onAllProductsLoadingFailed, (state) => ({ ...state })),
    on(productActions.getProductsByCategory, state => ({ ...state })),
    on(productActions.onGetProductByCategorySuccessfully, (state, action) => ({ ...state, productsByCategory: action.payload })),
    on(productActions.onGetProductByCategoryFailed, (state) => ({ ...state })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return productsReducer(state, action);
}
// Reducer Declarations - END