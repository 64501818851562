export class Address {
    public zipCode: string;
    public city: string;
    public country: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public street: string;
    public streetNumber: string;
    public company: string;
    public addressSupplement: string;

    constructor() { }

    setAddressModel(address: any) {
        this.zipCode = address.zipCode;
        this.city = address.city;
        this.country = address.country;
        this.firstName = address.firstName;
        this.lastName = address.lastName;
        this.email = address.email;
        this.street = address.street;
        this.streetNumber = address.streetNumber;
        this.company = address.company;
        this.addressSupplement = address.addressSupplement;
    }

    validateAddressModel() {
        return (this.zipCode && this.city &&
            this.country && this.firstName &&
            this.lastName && this.email &&
            this.street && this.streetNumber) ? true : false;
    }
}
