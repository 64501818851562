import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs/';
import * as fromActions from './../actions/address.action';
import { map, mergeMap } from 'rxjs/operators';
import * as fromFeature from './../reducers/address.reducer';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AddressModel } from './../models/address.model';
import { AddressLocalStorageService } from './../services/addressLocalStorageService.service';
import Debug from 'debug';
import { AddressType } from '../models/addressType.enum';
const debug = Debug('modeso:lidl-lib-address-fe:AddressStoreProvider');

@Injectable({
  providedIn: 'root'
})
export class AddressStoreProvider {

  public canNavigate = new BehaviorSubject<boolean>(false);

  subscriptions = new Subject<void>();

  isBillingPosted = false;
  isBillingPuted = false;
  isDeliveryPosted = false;
  isDeliveryPuted = false;
  constructor(private actions$: Actions,
    public store: Store<fromFeature.AppState>,
    private addressLocalStorageService: AddressLocalStorageService) {
  }

  public addressNavigation() {
    this.billingPostAction();
    this.billingPutAction();
    this.deliveryPostAction();
    this.deliveryPutAction();
  }

  /**
   * check billing address marked
   */
  public isBillingAddress(): Observable<boolean> {
    return this.store.pipe(
      select(fromFeature.selectIsBillingAddress),
      map((isMarked: boolean) => {
        return !isMarked;
      }));
  }

  /**
   * When delivery address posted successfully send 'onDeliveryAddressPostedSuccessfully'
   * as parameter tp function checkSuccessStatus
   */
  private deliveryPostAction() {
    this.actions$.pipe(takeUntil(this.subscriptions)).pipe(
      ofType(fromActions.onDeliveryAddressPostedSuccessfully.type)
    ).subscribe(() => this.checkSuccessStatus('onDeliveryAddressPostedSuccessfully'));
  }

  /**
   * When delivery address puted successfully send 'onPutDeliveryAddressSuccessfully'
   * as parameter tp function checkSuccessStatus
   */
  private deliveryPutAction() {
    this.actions$.pipe(takeUntil(this.subscriptions)).pipe(
      ofType(fromActions.onPutDeliveryAddressSuccessfully.type)).subscribe(() =>
        this.checkSuccessStatus('onPutDeliveryAddressSuccessfully'));
  }

  /**
   * When billing address puted successfully send 'onBillingAddressPostedSuccessfully'
   * as parameter tp function checkSuccessStatus
   */
  private billingPostAction() {
    this.actions$.pipe(takeUntil(this.subscriptions)).pipe(
      ofType(fromActions.onBillingAddressPostedSuccessfully.type)
    ).subscribe(() =>
      this.checkSuccessStatus('onBillingAddressPostedSuccessfully'));
  }

  /**
   * When billing address puted successfully send 'onBillingAddressPostedSuccessfully'
   * as parameter tp function checkSuccessStatus
   */
  private billingPutAction() {
    this.actions$.pipe(takeUntil(this.subscriptions)).pipe(
      ofType(fromActions.onPutBillingAddressSuccessfully.type)
    ).subscribe(() => this.checkSuccessStatus('onBillingAddressPostedSuccessfully'));
  }

  /**
   * depend on parameter while set special variable to true
   * @param actionName
   */
  private checkSuccessStatus(actionName: string): any {
    switch (actionName) {
      case 'onDeliveryAddressPostedSuccessfully':
        this.isDeliveryPosted = true;
        break;
      case 'onPutDeliveryAddressSuccessfully':
        this.isDeliveryPuted = true;
        break;
      case 'onBillingAddressPostedSuccessfully':
        this.isBillingPosted = true;
        break;
      case 'onBillingAddressPostedSuccessfully':
        this.isBillingPuted = true;
        break;
      default:
        break;
    }

    /**
     * if there is billing address check check status of post or put billing or delivery address
     * if there is not billing address check status of post or put delivery address
     */
    this.isBillingAddress().subscribe(isBillingAddress => {
      if (isBillingAddress) {
        if (this.checkBillingAndDeliveryAddressStatus()) {
          this.canNavigate.next(true);
        } else {
          this.canNavigate.next(false);
        }
      } else {
        if (this.checkDeliveryAddressStatus()) {
          this.canNavigate.next(true);
        } else {
          this.canNavigate.next(false);
        }
      }
    });

  }

  /**
   * This is in case there is billing address
   * return true if post or put delivery address successfuly and post or put
   * billing address successfuly.
   */
  checkBillingAndDeliveryAddressStatus() {
    return ((this.isDeliveryPosted || this.isDeliveryPuted) && (this.isBillingPosted || this.isBillingPuted));
  }

  /**
   * This is in case there is no billing address.
   * return true if post or put delivery address successfuly.
   */
  checkDeliveryAddressStatus() {
    return ((this.isDeliveryPosted || this.isDeliveryPuted));
  }

  /**
   * return each value to its default statue and unsuvscribe all subscription function.
   */
  destroy() {
    this.isBillingPosted = false;
    this.isBillingPuted = false;
    this.isDeliveryPosted = false;
    this.isDeliveryPuted = false;
    this.subscriptions.unsubscribe();

  }

  public getDeliveryAddress$(): Observable<AddressModel> {
    return this.store.pipe(select(fromFeature.selectEntitiesByID,
      { id: AddressType.DELIVERYADDRESS }));
  }

  public getBillingAddress$(): Observable<AddressModel> {
    // tslint:disable-next-line:max-line-length
    return this.isBillingAddress().pipe(mergeMap( (isBillingAddressSet) => {
      if(isBillingAddressSet) {
        return this.store.pipe(select(fromFeature.selectEntitiesByID,
          { id: AddressType.BILLINGADDRESS }));
      } else {
        debug('Billing Address is equal to delivery address');
        return this.getDeliveryAddress$();
      }
    }));
  }
}
