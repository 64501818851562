import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class RedirectionHandler {
  constructor(private router: Router) {}
  redirectUser() {
      this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}`);
  }

//   setRouterStrategy() {
//     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
//     this.router.onSameUrlNavigation = 'reload';
//   }
}
