import { Store } from '@ngrx/store';
import { onDeliveryOptionSeleced, onBillingAddressSet, onDeliveryAddressSet } from './app.state.actions';
import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@modeso/giftcardshop-lib-address-fe";
import * as i3 from "@modeso/giftcardshop-lib-cart-fe";
import * as i4 from "@modeso/giftcardshop-lib-products-fe";
const debug = Debug('lidl:shop:AppStateDispatcher');
export class AppStateDispatcher {
    constructor(store, 
    // private deliveryStoreProvider: DeliveryStoreProvider,
    addressStore, cartStore, productStore) {
        this.store = store;
        this.addressStore = addressStore;
        this.cartStore = cartStore;
        this.productStore = productStore;
        // this.readSelectedDeliveryOptionAndAddToStore();
    }
    // private readSelectedDeliveryOptionAndAddToStore() {
    //   this.store.pipe(select(fromReducer.selectedDeliveryOption)).pipe(take(1))
    //     .subscribe((deliveryOption: DeliveryOptionModel) => {
    //       if (deliveryOption === undefined || deliveryOption === null) {
    //         this.deliveryStoreProvider.getLoadedDeliveryOptions$()
    //           .pipe(
    //             skipWhile((deliveryOptions: DeliveryOptionModel[]) => deliveryOptions.length === 0),
    //             take(1))
    //           .subscribe((deliveryOptions: DeliveryOptionModel[]) => {
    //             debug('AppStateDispatcher read options...');
    //             debug(deliveryOptions);
    //             this.dispatchChangeSelectedDelvieryOption(deliveryOptions[0]);
    //           });
    //         debug('AppStateDispatcher SET default Delivery option');
    //       } else {
    //         debug('AppStateDispatcher SET selected Delivery option');
    //       }
    //     });
    // }
    dispatchChangeSelectedDelvieryOption(deliveryOption) {
        const data = { payload: deliveryOption };
        this.store.dispatch(onDeliveryOptionSeleced(data));
    }
    dispatchChangeDeliveryAddress(address) {
        const data = { payload: address };
        this.store.dispatch(onDeliveryAddressSet(data));
    }
    dispatchChangeBillingAddress(address) {
        const data = { payload: address };
        this.store.dispatch(onBillingAddressSet(data));
    }
    // public getSelectedDeliveryOption$(): Observable<DeliveryOptionModel> {
    //   debug('AppStateDispatcher getSelectedDeliveryOption$() is called');
    //   return this.store.pipe(select(fromReducer.selectedDeliveryOption));
    // }
    /*public getSelectedPaymentOption$(): Observable<PaymentOpionModel> {
        return this.store.pipe(select(fromReducer.selectedPaymentOption));
    }*/
    getDeliveryAddress$() {
        return this.addressStore.getDeliveryAddress$();
    }
    getBillingAddress$() {
        return this.addressStore.getBillingAddress$();
    }
    loadCart() {
        this.cartStore.loadCart();
    }
    addNewCart() {
        this.cartStore.addNewCart();
    }
    getCart$() {
        return this.cartStore.getActiveCart$();
    }
    addSelectedDeliveryOption$(deliveryOptionToBeAdded) {
        this.cartStore.addSelectedDeliveryOption$(deliveryOptionToBeAdded);
    }
    getDeliveryOptions$() {
        return this.cartStore.getDeliveryOptions$();
    }
    getProductDetailsById$(productId) {
        return this.productStore.getProduct$(productId);
    }
}
AppStateDispatcher.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppStateDispatcher_Factory() { return new AppStateDispatcher(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.AddressStoreProvider), i0.ɵɵinject(i3.CartStoreProvider), i0.ɵɵinject(i4.ProductStoreProvider)); }, token: AppStateDispatcher, providedIn: "root" });
