import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { ModesoAddressConfig, ModesoAddressConfigService } from './address.config';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompactAddressComponent } from './components/compact-address/compact-address.component';
import { AddressComponent } from './components/address/address.component';
import { AddressService } from './services/address.service';
import { AddressLocalStorageService } from './services/addressLocalStorageService.service';
import { AddressEffects } from './effects/address.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromAddress from './reducers/address.reducer';
import { CompactAddressDetailsComponent } from './components/compact-address-details/compact-address-details.component';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { CompactAddressViewComponent } from './components/compact-address-view/compact-address-view.component';
import { CompactAddressDetailsViewComponent } from './components/compact-address-detail-view/compact-address-details-view.component';

@NgModule({
  declarations: [AddressDetailsComponent, CompactAddressComponent, AddressComponent,
    CompactAddressDetailsComponent, CompactAddressViewComponent, CompactAddressDetailsViewComponent],
  providers: [AddressService, AddressLocalStorageService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InjectedTextFeautureModule,
    StoreModule.forFeature(fromAddress.featureKey, fromAddress.reducer),
    EffectsModule.forFeature([AddressEffects]),
  ],
  exports: [AddressComponent, CompactAddressComponent, CompactAddressViewComponent]
})
export class ModesoAddressModule {
  static forRoot(config: ModesoAddressConfig): ModuleWithProviders {
    return {
      ngModule: ModesoAddressModule,
      providers: [AddressService,
        AddressLocalStorageService,
        {
          provide: ModesoAddressConfigService,
          useValue: config
        },
      ]
    };
  }
}
