import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as productDetailsActions from './../actions/product-details.actions';
import { ProductDetailsModel } from '../models/product-details.model';

/**
 * Using feature keys to register each reducer.
 * The feature key will define the name of the property in the state.
 */
export const productDetailsFeatureKey = 'modesoProductDetailsMicroservice';

// State Declarations - START

/**
 * These methods are provided by the adapter object returned when using createEntityAdapter.
 * The methods are used inside your reducer function to manage the entity collection based on your provided actions.
 */
export const adapter: EntityAdapter<ProductDetailsModel> = createEntityAdapter<ProductDetailsModel>({
  selectId: selectproductId,
  sortComparer: sortById
});

/**
 *
 * @param a product detail model selected.
 */
export function selectproductId(a: ProductDetailsModel): string {
  return a.productId;
}

/**
 *
 * @param a product detail model selected.
 * @param b product detail model selected.
 */
export function sortById(a: ProductDetailsModel, b: ProductDetailsModel): number {
  return a.productId.localeCompare(b.productId);
}

export interface FeatureState extends EntityState<ProductDetailsModel> {
}

export interface AppState {
  modesoProductDetailsMicroservice: FeatureState;
}
// State Declarations - END

// Selectors Declarations - START

/**
 *
 * @param state state of AppState
 */
export const selectFeature = (state: AppState) => state.modesoProductDetailsMicroservice;

export const selectFeatureProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

/**
 * Get the selectors.
 * The getSelectors method returned by the created entity adapter provides functions for selecting information from the entity.
 * The getSelectors method takes a selector function as its only argument to select the piece of state for a defined entity.
 */
export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();

export const selectProductEntities = createSelector(
  selectFeatureProducts,
  selectEntities
);
export const selectProductAllEntities = createSelector(
  selectFeatureProducts,
  selectAll
);

export const selectEntitiesByID = createSelector(
  selectProductEntities,
  (entities, props) => {
    return entities[props.id];
  }
);

export const selectEntitiesByFilter = createSelector(
  selectProductAllEntities,
  (entities, props) => {
    return entities.filter(
      product => product.price === props.filter
    );
  }
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = adapter.getInitialState();

/**
 * The reducer function's responsibility is to handle the state transitions in an immutable way.
 * Create a reducer function that handles the actions for managing the state of the scoreboard using the createReducer function.
 */
const productDetailsReducer = createReducer(
  initialState,
  on(productDetailsActions.getProductById, state => ({ ...state })),
  on(productDetailsActions.onProductDetailsLoadedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, { ...state });
  }
  ),
  on(productDetailsActions.onProductDetailsLoadingFailed, (state) => ({ ...state })),
);

/**
 *
 * @param state state of product details.
 * @param action action of product details.
 */
export function reducer(state: FeatureState | undefined, action: Action) {
  return productDetailsReducer(state, action);
}
// Reducer Declarations - END
