import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpEvent
  } from '@angular/common/http';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import Debug from 'debug';

const debug = Debug('modeso:modeso-core:LanguageInterceptor');
export const LANGUAGE_PROVIDER = new InjectionToken<ILanguageProvider>('LanguageInterceptor');

export interface ILanguageProvider {
    getCurrentLanguage(): string;
}

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(@Inject(LANGUAGE_PROVIDER) private languageProvider: ILanguageProvider) { }

    /**
     * intercept auttomatically all http request to the backend
     * and set the content-language header according to current language from url
     * @param req
     * @param next // to call next interceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({
        setHeaders: {
            'content-language': this.languageProvider.getCurrentLanguage()
        }
        });
        debug('add language to request... ' + this.languageProvider.getCurrentLanguage());
        return next.handle(modifiedReq);
    }
}

