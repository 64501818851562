import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../reducers/cart.reducer';
import { addNewCart, onLoadCart, addSelectedDeliveryOption } from '../actions/cart.actions';
import { NamedString, InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import { Cart } from './../models/cart.model';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CartResponseModel } from '../models/response/cart-response.model';
import Debug from 'debug';
import { AddDeliveryToCartRequest } from '../models/request/add-delivery-to-cart-request';
import { DeliveryOptionModel } from '../models/delivery-options.model';
const debug = Debug('modeso:lidl-lib-cart-fe:CartStoreProvider');

const strings = [
  { 'modeso_cart_add-to-cart-button_caption': 'In den Warenkorb' },
  { 'modeso_cart_cart-icon-label': 'Warenkorb' },
  { 'modeso_cart_cart_empty': 'There are no Products In Cart' },
  { 'modeso_cart_cart-product-list_table-col-product-title': 'Artikel' },
  { 'modeso_cart_cart-product-list_table-col-amount-title': 'Menge' },
  { 'modeso_cart_cart-product-list_table-col-price-title': 'Preis' },
  { 'modeso_cart_cart-product-list_remove-button-caption': 'Entfernen' },
  { 'modeso_cart_cart_cart-total_summary-total-sum': 'Gesamtsumme' },
  { 'modeso_cart_cart_cart-total_summary-total-vat': 'inkl. MwSt. und Versand' },
  { 'modeso_cart_compact-cart_title': 'Ihre Bestellung' },
];
@Injectable({
  providedIn: 'root'
})
export class CartStoreProvider {

  constructor(public store: Store<fromFeature.AppState>, private injectedTextProvider: InjectedTextProvider) {
    const defaultStrings = new Array<NamedString>();
    strings.forEach((element) => {
      const x = new NamedString(Object.keys(element)[0], element[Object.keys(element)[0]]);
      defaultStrings.push(x);
    });
    debug('set default text');
    this.setInjectedText(defaultStrings);
  }

  public addNewCart(): void {
    this.store.dispatch(addNewCart());
  }

  public getActiveCart$(): Observable<Cart> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CartResponseModel) => {
      return cartResponse.cart;
    }));
  }
  public getActiveCartTotal$(): Observable<number> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CartResponseModel) => {
      return cartResponse.totalPrice;
    }));
  }

  public getDeliveryOptions$(): Observable<DeliveryOptionModel[]> {
    return this.store.pipe(select(fromFeature.featureDeliveryOptionsOfCart)
      ,
      filter((deliveryOptions: DeliveryOptionModel[]) =>
        deliveryOptions != null));
  }

  public loadCart(): void {
    this.store.dispatch(onLoadCart());
  }

  public addSelectedDeliveryOption$(deliveryOptionToBeAdded: AddDeliveryToCartRequest) {
    this.store.dispatch(addSelectedDeliveryOption({ payload: deliveryOptionToBeAdded }));
  }

  private setInjectedText(injectedText: Array<NamedString>) {
    this.injectedTextProvider.setInjectedText(injectedText);
  }

}
