import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductsService } from './services/products.service';
import { ProductDetailsService } from './services/product-details.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromProducts from './reducers/products.reducer';
import * as fromProductDetails from './reducers/product-details.reducer';
import { ProductsEffects } from './effects/products.effects';
import { ProductDetailsEffects } from './effects/product-details.effects';
import { ProductsConfig, ProductsConfigService } from './products.config';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductPriceComponent } from './components/product-price/product-price.component';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';

@NgModule({
  declarations: [ProductListComponent, ProductDetailComponent, ProductPriceComponent, ProductCardComponent],
  providers: [ProductsService, ProductDetailsService],
  imports: [
    CommonModule,
    InjectedTextFeautureModule,
    StoreModule.forFeature(fromProducts.productsFeatureKey, fromProducts.reducer),
    StoreModule.forFeature(fromProductDetails.productDetailsFeatureKey, fromProductDetails.reducer),
    EffectsModule.forFeature([ProductsEffects]),
    EffectsModule.forFeature([ProductDetailsEffects])

  ],
  exports: [ProductListComponent, ProductDetailComponent, ProductPriceComponent, ProductCardComponent]
})
export class ProductsModule {
  static forRoot(config: ProductsConfig): ModuleWithProviders {
    return {
      ngModule: ProductsModule,
      providers: [
        ProductsService,
        {
          provide: ProductsConfigService,
          useValue: config
        },
        ProductDetailsService
      ]
    };
  }
}
