import { Component, Input } from '@angular/core';
import { AddressModel } from './../../models/address.model';
import { AddressUtils } from '../../utils/address-utils';

@Component({
  selector: 'modeso-compact-address-details-view',
  templateUrl: './compact-address-details-view.component.html',
  styleUrls: ['./compact-address-details-view.component.scss']
})
export class CompactAddressDetailsViewComponent {
  addressData: AddressModel;
  @Input() set address(addressModel: AddressModel) {
    if (addressModel) {
      this.addressData = addressModel;
      this.setCountryName();
    }
  }

  @Input() addressType: string;
  @Input() isBillingAddress: boolean;

  constructor() {}

  setCountryName() {
    this.addressData.countryName = AddressUtils.getCountryNameByCode(this.addressData.country);
  }

}
