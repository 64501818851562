<modeso-loading-spinner [hidden]="!isLoading"></modeso-loading-spinner>

<div class="payment-container">
  <div class="row">
    <modeso-error-alert [errorMessage]="'Es tut uns Leid, ihre Bestellung konnte nicht durchgefuhrt werden.'"
    [hidden]="!isPaymentInitializedError"></modeso-error-alert>
  </div>

  <div class="row">
    <modeso-title [titleText]="('modeso_payment_page-title' | localize | async)"> </modeso-title>
  </div>


  <div class="row">
    <modeso-checkout-steps [activeStep]="2"></modeso-checkout-steps>
  </div>
  <div *ngIf="hasError" class="error-message">
    <span>
      {{ 'modeso_payment_assert_payment_faliure' | localize | async }}
    </span>
  </div>
  <div class="payment-details-container">
    <div class="row">
      <div class="col-lg-6">
        <modeso-checkout-payment-options 
        [isPaymentDone]="isPaymentDone" [paymentOrderId]="(orderId$ | async)" 
        [showLoadingSpinner]="isLoading" (paymentStatus)="handlePaymentStatus($event)"></modeso-checkout-payment-options>
        <modeso-checkout-terms></modeso-checkout-terms>
        <modeso-compact-address [ChangeAddress]="true">
          <div class="change-address">
            <a (click)="onEditAddressClick()">{{ 'modeso_payment_change-address' | localize | async }}</a>
          </div>
        </modeso-compact-address>
      </div>
      <div class="col-lg-6">
        <modeso-compact-cart [additionalCharges]="selectedDeliveryOption.price">
          <tr afterProductList>
            <td id="shippingTitle" class="shipping-title"> {{ ("modeso_shop_page_"+selectedDeliveryOption.key+"_name") | localize | async }}</td>
            <td id="shippingPrice" class="price"> CHF {{ selectedDeliveryOption.price | number : '1.2' }}
            </td>
          </tr>
          <div afterTotal class="continue-button">
            <button class="btn-cta" id="continueButton" [disabled]="disableSubmit || !isPaymentValid || buttonSpinner" (click)="submit()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
              [hidden]="!buttonSpinner"></span>
              {{ 'modeso_payment_pay-now' | localize | async }}
            </button>
          </div>
        </modeso-compact-cart>

      </div>
    </div>
  </div>
</div>
