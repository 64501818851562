import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModesoContentConfigService } from './../content.config';
import { ContentModel } from '../models/content.model';

export interface ContentResponse {
    content: Array<ContentModel>;
}

export interface ContentDetailResponse {
    content: ContentModel;
}


@Injectable({
    providedIn: 'root',
})
export class ContentService {

    private server = '';

    private microserviceName = 'content';
    private readonly route = '/';
    private readonly detailRoute = ':id';
    private cacheHeader =  new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      });

    constructor(private http: HttpClient, @Inject(ModesoContentConfigService) private config) {
        this.server = config.apiGateway;
    }

    getAllContent() {
        const url = `${this.server}${this.microserviceName}${this.route}`;
        return this.http.get<ContentResponse>(url, {headers: this.cacheHeader});
    }

    getContentById(id: string) {
        const contentId = this.detailRoute.replace(':id', id);
        const url = `${this.server}${this.microserviceName}${this.route}${contentId}`;
        return this.http.get<ContentDetailResponse>(url, {headers: this.cacheHeader});
    }

}

