/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./breadcrumb.component";
import * as i5 from "@ngrx/store";
var styles_BreadcrumbComponent = [i0.styles];
var RenderType_BreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
export function View_BreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "app-breadcrumb row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_3_0 = null; var currVal_3 = (((tmp_3_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.breadcrumb$))) == null) ? null : tmp_3_0.url); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.breadcrumb$))) == null) ? null : tmp_0_0.hidden); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.breadcrumb$))) == null) ? null : tmp_4_0.text); _ck(_v, 7, 0, currVal_4); }); }
export function View_BreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i1.ɵdid(1, 49152, null, 0, i4.BreadcrumbComponent, [i5.Store], null, null)], null, null); }
var BreadcrumbComponentNgFactory = i1.ɵccf("app-breadcrumb", i4.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, {}, {}, []);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
