import { NgModule, LOCALE_ID } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { HeaderBannerComponent } from './skeleton/header-banner/header-banner.component';
import { FooterComponent } from './skeleton/footer/footer.component';
import { BreadcrumbComponent } from './skeleton/breadcrumb/breadcrumb.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppProductsModule } from './microservices/products/products.module';
import { environment } from '../environments/environment';
import { ShopContentComponent } from './microservices/lidl-lib-content-fe/components/content/content.component';
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieBannerComponent } from "./skeleton/cookie-banner/cookie-banner.component";

// tslint:disable-next-line: max-line-length
import { IntroPage } from './pages/intro/intro.page';
import { HomePage } from './pages/home/home.page';
import { ProductDetailPage } from './pages/products/product-detail.page';
// tslint:disable-next-line:max-line-length
import { LocalStorageService } from './shared/services/localstorage.service';
import { BasePageComponent } from './pages/base.page';
import { SharedModule } from './shared/shared.module';
import { CartPage } from './pages/cart/cart.page';
import * as fromAppState from './state/app.state.reducer';
import { AddressPage } from './pages/address/address.page';
import { PaymentPage } from './pages/payment/payment-detail.page';
import { UnderConstructionPage } from './pages/under-construction/under-construction.page';
import { OrderConfirmationPage } from './pages/order/orderConfirmation.page';
import { LanguageInterceptor, LANGUAGE_PROVIDER } from '@modeso/modeso-lib-core-fe';
import { ModesoAddressConfig, ModesoAddressModule } from '@modeso/giftcardshop-lib-address-fe';
import { ModesoContentConfig, ModesoContentModule } from '@modeso/giftcardshop-lib-content-fe';
import { ModesoCartModule, ModesoCartConfig } from '@modeso/giftcardshop-lib-cart-fe';
import { DeliveryConfig, DeliveryModule } from '@modeso/giftcardshop-lib-delivery-fe';
import { ModesoLocalizationConfig, ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { OrderConfig, OrderModule } from '@modeso/giftcardshop-lib-order-fe';
import { PaymentConfig, PaymentModule } from '@modeso/giftcardshop-lib-payment-fe';
import { ProductsConfig, ProductsModule } from '@modeso/giftcardshop-lib-products-fe';
import localeDECH from '@angular/common/locales/de-CH';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BalancePage } from './pages/balance/balance.page';
import { StaticContentPage } from './pages/static-content/static-content.page';
// tslint:disable-next-line: max-line-length
import { ShopStaticContentListComponent } from './microservices/lidl-lib-content-fe/components/static-content-list/static-content-list.component';
// tslint:disable-next-line: max-line-length
import { ShopStaticSubContentComponent } from './microservices/lidl-lib-content-fe/components/static-sub-content/static-sub-content.component';

import { ModesoAuthConfig } from '@modeso/modeso-lib-auth-fe';
import { AuthHandlerInterceptor } from '@modeso/modeso-lib-auth-fe';

import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie';
import { AUTHORIZATION_PROVIDER } from './shared/interceptors/authorization.interceptor';
import { ModesoAuthModule } from '@modeso/modeso-lib-auth-fe';

registerLocaleData(localeDECH);
registerLocaleData(localeFRCH);
registerLocaleData(localeITCH);


const productsServiceConfig: ProductsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'products service'
};
const contentServiceConfig: ModesoContentConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'content service'
};
const cartServiceConfig: ModesoCartConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'cart service'
};
const addressServiceConfig: ModesoAddressConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'address service'
};

const paymentServiceConfig: PaymentConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'payment service'
};

const orderServiceConfig: OrderConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'order service'
};

const localizationServiceConfig: ModesoLocalizationConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'localization service'
};

const deliveryServiceConfig: DeliveryConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'delivery service'
};

const authServiceConfig: ModesoAuthConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'auth service',
};
@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    HeaderComponent,
    HeaderBannerComponent,
    FooterComponent,
    BreadcrumbComponent,
    ContentAreaComponent,
    ShopContentComponent,
    ShopStaticContentListComponent,
    ShopStaticSubContentComponent,
    IntroPage,
    HomePage,
    StaticContentPage,
    ProductDetailPage,
    CartPage,
    AddressPage,
    PaymentPage,
    UnderConstructionPage,
    OrderConfirmationPage,
    BalancePage,
    CookieBannerComponent
    //LoginPage,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot([]),
    StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ProductsModule.forRoot(productsServiceConfig),
    ModesoAddressModule.forRoot(addressServiceConfig),
    ModesoContentModule.forRoot(contentServiceConfig),
    ModesoCartModule.forRoot(cartServiceConfig),
    PaymentModule.forRoot(paymentServiceConfig),
    OrderModule.forRoot(orderServiceConfig),
    DeliveryModule.forRoot(deliveryServiceConfig),
    ModesoLocalizationModule.forRoot(localizationServiceConfig),
    ModesoAuthModule.forRoot(authServiceConfig),
    ModesoAddressModule,
    ModesoLocalizationModule,
    AppProductsModule,
    NgbModule,
    FormsModule,
    CommonModule,
    CookieModule.forRoot()
  ],
  providers: [
    LocalStorageService,
    {
      provide: AUTHORIZATION_PROVIDER,
      useExisting: LocalStorageService,
    },
    {
      provide: LANGUAGE_PROVIDER,
      useExisting: LocalStorageService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHandlerInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: LOCALE_ID, useValue: 'fr-ch' },
    { provide: LOCALE_ID, useValue: 'it-ch' },
    Title,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
