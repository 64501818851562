import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modeso-short-email-details',
  templateUrl: './short-email-details.component.html',
  styleUrls: ['./short-email-details.component.scss']
})
export class ShortEmailDetailsComponent implements OnInit {
  @Input() deliveryDate: string;
  @Input() orderId: string;
  @Input() language: string;

  constructor() {
  }

  /**
   * add tow days for the current date.
   */
  ngOnInit() {
  }

}
