import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class JWTService {
  decodeJwt(jwtToken: string) {
    return jwt_decode(jwtToken);
  }

  getUserFromJWT(jwtToken: string) {
    const decodedToken  =  this.decodeJwt(jwtToken) as {userId: string};
    return decodedToken.userId;
  }

}
