import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductsConfigService } from './../products.config';
import Debug from 'debug';
import { ProductDetailsModel } from '../models/product-details.model';
const debug = Debug('modeso:lidl-lib-products-fe:ProductsService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

export interface ProductsResponse {
  products: Array<ProductDetailsModel>;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  private server = '';
  private microserviceName = 'products';
  private readonly productsRoute = '/';
  private readonly singleProductRoute = ':id';
  private readonly categoryParam = ':cat';
  private readonly categoryRoute = 'category';

  constructor(private http: HttpClient, @Inject(ProductsConfigService) private config) {
    this.server = config.apiGateway;
    debug('ProductServiceId: ' + config.serviceId);
  }

  getAllProducts() {
    debug('get all products called');
    const url = `${this.server}${this.microserviceName}${this.productsRoute}`;
    return this.http.get<ProductsResponse>(url);
  }

  getProductsByCategory(category: string){
    const productCategory = this.categoryParam.replace(':cat', category);
    debug('get products by category called');
    const url =
    `${this.server}${this.microserviceName}${this.productsRoute}${this.categoryRoute}${this.productsRoute}${productCategory}`;

    return this.http.get<ProductsResponse>(url);
  }
}
