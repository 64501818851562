import { Pipe, PipeTransform } from '@angular/core';
import * as fromReducers from '../reducers/content.reducer';
import { ContentModel } from '../models/content.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
const debug = Debug('modeso:modeso-content:ContentPipe');

@Pipe({ name: 'content' })
export class ContentPipe implements PipeTransform {

  constructor(private store: Store<fromReducers.AppState>) {
    debug('ContentPipe constructed');
  }

  transform(key: string, selector: string): Observable<string> {
    return this.store.pipe(
      select(fromReducers.selectEntitiesByID, { id: key }),
      map((content: ContentModel) => {
        if (content) {
          return content.text[selector];
        } else {
          return undefined;
        }
      }),
      filter((text: string) => text !== undefined));
  }
}
