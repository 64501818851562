import Debug from 'debug';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@modeso/modeso-lib-auth-fe";
const debug = Debug('giftcardshop:project-shop:NavigationAuthGuard');
export class NavigationAuthGuard {
    constructor(cookieService, redirectionHandler, sessionProvider) {
        this.cookieService = cookieService;
        this.redirectionHandler = redirectionHandler;
        this.sessionProvider = sessionProvider;
    }
    canActivate(route, state) {
        return this.sessionProvider.isAllowedToCheckout$().pipe(tap((isAllowed) => {
            debug(isAllowed);
            if (!isAllowed) {
                this.redirectionHandler.redirectUser();
            }
        }));
    }
}
NavigationAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationAuthGuard_Factory() { return new NavigationAuthGuard(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.RedirectionHandler), i0.ɵɵinject(i2.AuthProvider)); }, token: NavigationAuthGuard, providedIn: "root" });
