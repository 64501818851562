import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ContentService } from './services/content.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromContent from './reducers/content.reducer';
import { ContentEffects } from './effects/content.effects';
import { ModesoContentConfig, ModesoContentConfigService } from './content.config';
import { ContentComponent } from './components/content/content.component';
import { ContentListComponent } from './components/content-list/content-list.component';
import { ContentPipe } from './pipes/content.pipe';

@NgModule({
  declarations: [ContentComponent, ContentListComponent, ContentPipe],
  providers: [ContentService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromContent.featureKey, fromContent.reducer),
    EffectsModule.forFeature([ContentEffects])
  ],
  exports: [ContentComponent, ContentListComponent, ContentPipe]
})
export class ModesoContentModule {
  static forRoot(config: ModesoContentConfig): ModuleWithProviders {
    return {
      ngModule: ModesoContentModule,
      providers: [
        ContentService,
        {
          provide: ModesoContentConfigService,
          useValue: config
        }
      ]
    };
  }
}
