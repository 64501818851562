/**
 * Payment Option Model that return
 */
export class PaymentModel {
  public paymentId: string;
  public paymentName: string;

  constructor() { }

  setPaymentModel(payment: any) {
    this.paymentId = payment.paymentId;
    this.paymentName = payment.paymentName;
  }

  validatePaymentModel() {
    return (this.paymentId && this.paymentName) ? true : false;
  }
}
