import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromFeature from './../../reducers/products.reducer';
import { Observable } from 'rxjs';
import { ProductModel } from '../../models/product.model';

@Component({
  selector: 'lib-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {

  @Input() product: ProductModel;
  @Input() showDetailsButtonCaption: string;
  @Output() detailsClickEvent: EventEmitter<string> = new EventEmitter<string>();
  product$: Observable<ProductModel>;

  constructor() {}

  ngOnInit() {}

  /**
   * emit the card productId to the parent "product-list" to navigate with it
   * @param productId product Id of the card
   */
  onDetailsClick(productId: string) {
    this.detailsClickEvent.emit(productId);
  }

  /**
   * destroy the eventEmitter on component destroy
   */
  ngOnDestroy(): void {
    this.detailsClickEvent.unsubscribe();
  }

}
