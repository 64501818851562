import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartService } from '../services/cart.service';
import * as fromCartActions from '../actions/cart.actions';
import { mergeMap, retry, tap, map, catchError, take } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { CartLocalStorageService } from '../services/cartLocalStorageService.service';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
import { DeliveryOptionModel } from '../models/delivery-options.model';


const debug = Debug('modeso:lidl-lib-cart-fe:CartEffect');

@Injectable()
export class CartEffect {
  constructor(private action$: Actions, private cartService: CartService, private cartLocalStorageService: CartLocalStorageService) { }

  addToCart$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.addToCart.type),
      mergeMap(
        payload => {
          return this.cartService.addToCart(payload)
            .pipe(
              retry(1),
              tap(
                response => {
                  this.saveProductIdToSessionStorage();
                }
              ),
              map(
                response => (fromCartActions.onAddToCartSuccess({ payload: response })),
              ),
              catchError((error) => of(fromCartActions.onAddToCartFail({ payload: error })))
            );
        }

      )
    )
  );

  packageChanged$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onChangeProductPackage.type),
      mergeMap(
        payload => {
          return this.cartService.updateProductInCart(payload)
            .pipe(
              retry(1),
              map(
                response => (fromCartActions.onChangeProductPackageSuccess({ payload: response }))
              ),
              catchError(
                () => EMPTY
              )
            );
        }
      )
    )
  );

  cartLoaded$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onLoadCart.type),
      mergeMap(
        () => {
          return this.cartService.getCartById(this.cartLocalStorageService.getCartId())
            .pipe(
              retry(1),
              map(
                response => (fromCartActions.onLoadCartSuccess({ payload: response }))
              ),
              catchError(
                () => EMPTY
              )
            );
        }
      )
    )
  );

  removeProduct$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onRemoveProduct.type),
      mergeMap(
        payload => {
          return this.cartService.removeProduct(payload)
            .pipe(
              retry(1),
              tap(
                response => this.saveAddToCartResponseToLocalStorage(response)
              ),
              map(
                response => fromCartActions.onRemoveProductSuccess({ payload: response })
              ),
              catchError(
                () => EMPTY
              )
            );
        }
      )
    )
  );

  amountChanged$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onChangeProductAmount.type),
      mergeMap(
        payload => {
          return this.cartService.updateProductInCart(payload)
            .pipe(
              retry(1),
              map(
                response => (fromCartActions.onChangeProductAmountSuccess({ payload: response }))
              ),
              catchError((error) => of(fromCartActions.onChangeProductAmountFail()))
            );
        }
      )
    )
  );


  addNewCart$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.addNewCart.type),
      mergeMap(
        payload => {
          return this.cartService.addNewCart()
            .pipe(
              take(1),
              tap(
                response => {
                  this.saveAddToCartResponseToLocalStorage(response);
                }
              ),
              map(
                response => fromCartActions.onNewToCartSuccess({ payload: response })
              ),
              catchError(
                () => EMPTY
              )
            );
        }
      )
    )
  );

  addDeliveryOptionToCart$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.addSelectedDeliveryOption),
      mergeMap(
        payload => {
          return this.cartService.addDeliveryOptionToCart(payload)
            .pipe(
              map(
                response => fromCartActions.onAddSelectedDeliveryOptionSuccessfully(
                  {
                    payload: this.getSelectedDeliveryOptionFromCartResponse(response.cart.deliveryOptions)
                  }
                )
              ),
              catchError((error: HttpErrorResponse) => {
                return of(fromCartActions.onAddSelectedDeliveryOptionFailed({ payload: error }));
              })
            );
        }
      )
    )
  );

  errorOnGetAllLocalization$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onAddSelectedDeliveryOptionFailed.type),
      tap(
        (action: fromCartActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    ), { dispatch: false }
  );


  errorOnAddToCart$ = createEffect(
    () => this.action$.pipe(
      ofType(fromCartActions.onAddToCartFail.type),
      tap(
        (action: fromCartActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });


  private handleOnLoadAllCartErrors(error) {
    debug(error);
    return error;
  }
  private saveAddToCartResponseToLocalStorage(response: any) {
    this.cartLocalStorageService.setCartId(response.cart._id);
  }

  private saveProductIdToSessionStorage() {
    sessionStorage.setItem('isProductAddedToCart', 'true');
  }

  private getSelectedDeliveryOptionFromCartResponse(deliveryOptions: DeliveryOptionModel[]): DeliveryOptionModel {
    const selectedDeliveryOption: DeliveryOptionModel = deliveryOptions.find(
      (deliveryOption: DeliveryOptionModel) => deliveryOption.selected === true);

    return selectedDeliveryOption;
  }
}
