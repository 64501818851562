/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-banner.component";
import * as i3 from "../../shared/services/localstorage.service";
var styles_HeaderBannerComponent = [i0.styles];
var RenderType_HeaderBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderBannerComponent, data: {} });
export { RenderType_HeaderBannerComponent as RenderType_HeaderBannerComponent };
export function View_HeaderBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "srcset", 4], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(3, "\n    ../../../assets/skeleton/banner/Lidl_giftcard_Header_1920x500_", _co.currentLanguage, ".jpg 1920w,\n    ../../../assets/skeleton/banner/Lidl_giftcard_Header_1440x500_", _co.currentLanguage, ".jpg 1440w,\n    ../../../assets/skeleton/banner/Lidl_giftcard_Header_480x215_", _co.currentLanguage, ".jpg 480w"); var currVal_1 = i1.ɵinlineInterpolate(1, "../../../assets/skeleton/banner/Lidl_giftcard_Header_1920x500_", _co.currentLanguage, ".jpg"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_HeaderBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-banner", [], null, null, null, View_HeaderBannerComponent_0, RenderType_HeaderBannerComponent)), i1.ɵdid(1, 114688, null, 0, i2.HeaderBannerComponent, [i3.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderBannerComponentNgFactory = i1.ɵccf("app-header-banner", i2.HeaderBannerComponent, View_HeaderBannerComponent_Host_0, {}, {}, []);
export { HeaderBannerComponentNgFactory as HeaderBannerComponentNgFactory };
