export class DeliveryOption {
    name: string;
    description: string;
    price: number;
    deliveryDate: Date;

    constructor() { }

    setDeliveryOptionModel(deliveryOption: any) {
        this.name = deliveryOption.name;
        this.description = deliveryOption.description;
        this.price = deliveryOption.price;
        this.deliveryDate = deliveryOption.deliveryDate;
    }

    validateDeliveryOptionModel() {
        return (this.name && this.description && this.price && this.deliveryDate) ? true : false;
    }
}

