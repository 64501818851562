import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as appActions from './app.state.actions';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { PaymentOpionModel } from '@modeso/giftcardshop-lib-payment-fe';
import { AddressModel } from '@modeso/giftcardshop-lib-address-fe';

export const appStateFeatureKey = 'modesoAppState';

export interface FeatureState {
    selectedDeliveryOption: DeliveryOptionModel;
    selectedPaymentOption: PaymentOpionModel;
    deliveryAddress: AddressModel;
    billingAddress: AddressModel;
}

export interface AppState {
    modesoAppState: FeatureState;
}

export const selectFeature = (state: AppState) => state.modesoAppState;
export const selectedDeliveryOption = createSelector(
    selectFeature,
    (state: FeatureState) => state.selectedDeliveryOption
);
export const selectedPaymentOption = createSelector(
    selectFeature,
    (state: FeatureState) => state.selectedPaymentOption
);
export const deliveryAddress = createSelector(
    selectFeature,
    (state: FeatureState) => state.deliveryAddress
);
export const billingAddress = createSelector(
    selectFeature,
    (state: FeatureState) => state.billingAddress
);

export const initialState: FeatureState = {
    selectedDeliveryOption: undefined,
    selectedPaymentOption: undefined,
    deliveryAddress: undefined,
    billingAddress: undefined,
};

const appStateReducer = createReducer(
    initialState,
    on(appActions.onDeliveryOptionSeleced, (state, action) => ({ ...state, selectedDeliveryOption: action.payload })),
    on(appActions.onPaymentOptionSeleced, (state, action) => ({ ...state, selectedPaymentOption: action.payload })),
    on(appActions.onDeliveryAddressSet, (state, action) => ({ ...state, deliveryAddress: action.payload })),
    on(appActions.onBillingAddressSet, (state, action) => ({ ...state, billingAddress: action.payload })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return appStateReducer(state, action);
}
