import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as paymentActions from './../actions/payment.actions';
import { PaymentOpionModel } from '../models/payment-option.model';
import { PaymentStatusModel } from '../models/paymentStatus.model';
import { PaymentProcessStatusResponse } from '../models/payment-process-status-response';

export const paymentOptionsFeatureKey = 'modesoPaymentMicroservice';

// State Declarations - START

export interface FeatureState {
  paymentOptions: Array<PaymentOpionModel>;
  paymentOptionSelected: PaymentOpionModel;
  termsAndConditions: boolean;
  paymentStatus: PaymentStatusModel;
  redirectUrl: string;
  paymentProcessStatus: PaymentProcessStatusResponse;
}

export interface AppState {
  modesoPaymentMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoPaymentMicroservice;

export const selectFeaturePaymentOptions = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentOptions
);

export const selectPaymentOptionModel = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentOptionSelected
);

export const TermsAndConditionsState = createSelector(
  selectFeature,
  (state: FeatureState) => state.termsAndConditions
);

export const isPaymentValid = createSelector(
  selectFeature,
  (state: FeatureState) => (state.termsAndConditions &&
    state.paymentOptionSelected &&
    state.paymentOptionSelected.paymentId) ? true : false
);

export const paymentStatus = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentStatus

)

export const paymentRedirectUrlFeature = createSelector(
  selectFeature,
  (state: FeatureState) => state.redirectUrl
);

export const paymentProcessStatusFeature = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentProcessStatus
);

// Reducer Declarations - START

export const initialState: FeatureState = {
  paymentOptions: new Array<PaymentOpionModel>(),
  paymentOptionSelected: undefined,
  termsAndConditions: false,
  paymentStatus: {
    validPayment: false,
    message: '',
    paymentProcessStatus: ''
  },
  redirectUrl: null,
  paymentProcessStatus: new PaymentProcessStatusResponse()
};

const paymentReducer = createReducer(
  initialState,
  on(paymentActions.getAllPaymentOptions, state => ({ ...state })),
  on(paymentActions.onAllPaymentOptionsLoadedSuccessfully, (state, action) => ({ ...state, paymentOptions: action.payload })),
  on(paymentActions.onAllPaymentOptionsLoadingFailed, (state) => ({ ...state })),
  on(paymentActions.onPaymentOptionsValidSelected, (state, action) => ({ ...state, paymentOptionSelected: action.payload })),
  on(paymentActions.onTermsAndConditionsAccepted, (state, action) => ({ ...state, termsAndConditions: true })),
  on(paymentActions.onTermsAndConditionsRejected, (state, action) => ({ ...state, termsAndConditions: false })),
  on(paymentActions.getPaymentStatus, state => ({ ...state })),
  on(paymentActions.onGetPaymentStatusSuccess, (state, action) => ({ ...state, paymentStatus: action.payload })),
  on(paymentActions.onGetPaymentStatusReject, (state, action) => ({ ...state, paymentStatus: action.payload })),

  on(paymentActions.intializePayment, (state, action) => ({ ...state })),
  on(paymentActions.onIntializePaymentSuccessfully, (state, action) => ({
    ...state, redirectUrl: action.payload.redirectUrl, paymentStatus: {
      validPayment: false, message: '',
      paymentProcessStatus: 'INITIALIZED'
    }
  })),
  on(paymentActions.onIntializePaymentFailed, (state, action) => ({ ...state, redirectUrl: null })),

  on(paymentActions.getPaymentProcessStatus, (state) => ({ ...state })),
  on(paymentActions.onGetPaymentProcessStatusSuccessful, (state, action) => ({ ...state, paymentProcessStatus: action.payload })),
  on(paymentActions.onGetPaymentProcessStatusFailed, (state) => ({ ...state }))
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return paymentReducer(state, action);
}
// Reducer Declarations - END
