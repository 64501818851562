import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Order } from '../../models/order.model';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../../reducers/order.reducer';
import * as orderActions from './../../actions/order.action';
import { Observable, Subscription } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-order-fe:OrderInformationComponent');

@Component({
  selector: 'lidl-lib-order-fe-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss']
})
export class OrderInformationComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  @Input() orderId$: Observable<string>;
  @Input() public deliveryDate: Date;
  @Input() language: string;
  public postedSuccessfully = false;
  @Input() public set setOrderPostedStatus(isOrderPostedSuccessfully: boolean) {
    this.postedSuccessfully = isOrderPostedSuccessfully;
  }

  orderState$: Observable<any> = this.store.pipe(select(fromFeature.selectFeatureOrder));
  public order$: Observable<Order>;

  constructor(private store: Store<fromFeature.AppState>) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.orderId$.subscribe((orderId: string) => {
      //On change of the order Id request it from the store
      if (orderId === undefined) {
        debug('orderId is not defined');
        return;
      } else {
        this.order$ = this.store.pipe(select(fromFeature.selectOrderByID, { id: orderId }));
        this.store.dispatch(orderActions.getById({ orderId }));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
