import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../../reducers/payment.reducer';
import * as paymentActions from './../../actions/payment.actions';
import { take } from 'rxjs/operators';


@Component({
  selector: 'modeso-checkout-terms',
  templateUrl: './checkout-terms.component.html',
  styleUrls: ['./checkout-terms.component.scss']
})
export class CheckoutTermsComponent implements OnInit {

  acceptTerm1 = false;
  acceptTerm2 = false;
  acceptTerm3 = false;

  @Output() termsAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  async ngOnInit() {
    this.acceptTerm1 = await this.store.pipe(select(fromFeature.TermsAndConditionsState), take(1)).toPromise();
    this.acceptTerm2 = this.acceptTerm1;
    this.acceptTerm3 = this.acceptTerm1;
  }

  constructor(private store: Store<fromFeature.AppState>) { }

  emitAcceptedTerms() {
    if (this.acceptTerm1 && this.acceptTerm2 && this.acceptTerm3) {
      this.store.dispatch(paymentActions.onTermsAndConditionsAccepted());
    } else {
      this.store.dispatch(paymentActions.onTermsAndConditionsRejected());
    }
  }

}

