export class BreadcrumbModel {
    constructor(
        public url: string,
        public text: string,
        public hidden: boolean
    ) {}

    public static createDefaultInstance() {
        return new BreadcrumbModel('#', '', true);
    }
}
