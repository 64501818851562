import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '../../models/product.model';
import { ProductStoreProvider } from '../../provider/productStore.provider';
import Debug from 'debug';
import { ProductDetailsModel } from '../../models/product-details.model';
import { ProductNotificationModel } from '../../models/product-notification.model';
const debug = Debug('lidl:shop:product-list page');
declare global { interface Window { dataLayer:any; } }

@Component({
  selector: 'modeso-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  @Input() templateRef: TemplateRef<ProductModel>;
  @Input() showDetailsButtonCaption: string;
  @Input() productId: string;
  @Input() notificationMessage: string;
  @Input() hasError: boolean;
  @Input() showNotification = false;
  @Output() redirectToCartEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() public set productNotificationSetter(productNotification: ProductNotificationModel) {
    if (productNotification) {
      this.hasError = productNotification.isError;
      this.notificationMessage = productNotification.message;
      this.productId = productNotification.productId;
      this.showNotification = true;
    } else {
      this.showNotification = false;
      this.notificationMessage = '';
    }
  }

  productsByCategory: ProductDetailsModel[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productStoreProvider: ProductStoreProvider) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.loadProducts(routeParams.cat);
      this.hasError = false;
      this.showNotification = false;
      setTimeout(() => {
        this.gtagProductList(this.productsByCategory);
      }, 1000);
    });
  }

  loadProducts(productsCategory: string) {
    this.productStoreProvider.getProductsByCategory$(productsCategory).subscribe((products: ProductDetailsModel[]) => {
      if (products) {
        debug('products are set...');
        debug(products);
        this.productsByCategory = products;
      } else {
        debug('products is not set...');
        debug(products);
      }
    }, error => {
      debug('products by category error ...');
      debug(error);
    });
  }

  /**
   * will navigate on details click to the selected product
   * @param productId productId of the card get it from child card
   */
  onDetailsClick(productId: string) {
    this.navigateToProductPage(productId, this.getLanguageFromLocalStorage());
  }

  /**
   * check if there is a currentLanguage in localstorage will return it else will return 'de-ch' as a default language
   */
  private getLanguageFromLocalStorage(): string {
    return localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : `de-ch`;
  }

  /**
   * navigate to product page
   * @param productId product id
   * @param language default language
   */
  private navigateToProductPage(productId: string, language: string) {
    this.router.navigateByUrl(`/${language}/giftcard/${productId}`);
  }

  fireNavigateToCartEvent() {
    this.redirectToCartEvent.emit();
  }

  public gtagProductList(products: ProductDetailsModel[]) {
    const finalProducts:any = [];
    window.dataLayer = window.dataLayer || [];
    for (var i = 0; i < products.length; i++) {
      let productImpression: any = {};
      productImpression.name = products[i].name;
      productImpression.id = products[i].productId;
      productImpression.category = products[i].category;
      productImpression.position = i + 1;
      finalProducts.push(productImpression);
    }
    window.dataLayer.push({ 'ecommerce': null });
    window.dataLayer.push({
      'event': 'EECproductImpression',
      'ecommerce': {
        'impressions': finalProducts,
      }
    })
  }
}
