import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import Debug from 'debug';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthProvider } from '@modeso/modeso-lib-auth-fe';
import { RedirectionHandler } from '@modeso/modeso-lib-auth-fe';
const debug = Debug('giftcardshop:project-shop:NavigationAuthGuard');
@Injectable({
  providedIn: 'root'
})
export class NavigationAuthGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private redirectionHandler: RedirectionHandler,
    private sessionProvider: AuthProvider
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.sessionProvider.isAllowedToCheckout$().pipe(
      tap((isAllowed) => {
        debug(isAllowed);
        if (!isAllowed) {
            this.redirectionHandler.redirectUser();
        }
      })
    );
  }
}