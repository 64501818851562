import { Action, createAction, props } from '@ngrx/store';

export const onGetNewAccesToken = createAction(
  '[modeso auth] get new access token',
);

export const onGetNewSession = createAction(
  '[modeso auth] get new session',
);

export const onGetNewAccesTokenFailed = createAction(
  '[modeso auth] get new Acces token Failed',
);

export const onGetNewSessionFailed = createAction(
  '[modeso auth] get new session failed',
);

export const onGetNewSessionSuccess = createAction(
  '[modeso auth] get new session success',
);
export const onGetNewAccesTokenSuccess = createAction(
  '[modeso auth] get new Acces token success',
);
export interface IActionWithPayload<T> extends Action {
  payload: T;
}
