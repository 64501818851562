import { OnInit, Component, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../shared/services/localstorage.service';
import { environment } from '../../environments/environment';
import { BreadcrumbModel } from '../shared/models/breadcrumb.model';
import * as fromBreadcrumbReducer from './../shared/reducers/breadcrumb.reducer';
import * as fromBreadcrumbActions from './../shared/actions/breadcrumb.actions';
import { Store } from '@ngrx/store';
import { BreadcrumbStoreProvider } from '../shared/provider/breadcrumbStore.provider';
import { LocalizationStoreProvider } from '@modeso/modeso-lib-localization-fe';
import { ContentStoreProvider } from '@modeso/giftcardshop-lib-content-fe';
import { ProductStoreProvider } from '@modeso/giftcardshop-lib-products-fe';
import { InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import Debug from 'debug';
import { CartStoreProvider } from '@modeso/giftcardshop-lib-cart-fe';
import { JWTService} from '../shared/services/jwt.service';
import { CookieService } from 'ngx-cookie';

const debug = Debug('lidl:shop:BasePageComponent');

@Component({
  selector: 'base-add',
  template: `<ng-content></ng-content>`
})
export class BasePageComponent implements OnInit, OnDestroy {

  private activatedRoute: ActivatedRoute;
  private localStorageService: LocalStorageService;
  private breadcrumbStore: Store<fromBreadcrumbReducer.AppState>;
  protected localizationStoreProvider: LocalizationStoreProvider;
  private contentStoreProvider: ContentStoreProvider;
  private injectedTextProvider: InjectedTextProvider;
  // private deliveryStoreProvider: DeliveryStoreProvider;
  private cartStoreProvider: CartStoreProvider;
  private productStoreProvider: ProductStoreProvider;
  protected subscriptions = [];
  private cookieService: CookieService;
  private jwtService: JWTService;
  protected cart;

  // tslint:disable-next-line:variable-name
  protected _breadcrumb: BreadcrumbModel;
  set breadcrumb(breadcrumb: BreadcrumbModel) {
    this._breadcrumb = breadcrumb;
    const payload = { payload: breadcrumb };
    this.breadcrumbStore.dispatch(fromBreadcrumbActions.updateBreadcrumb(payload));
  }
  get breadcrumb(): BreadcrumbModel {
    return this._breadcrumb;
  }

  constructor(injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.localStorageService = injector.get(LocalStorageService);
    this.breadcrumbStore = injector.get(BreadcrumbStoreProvider).store;
    this.injectedTextProvider = injector.get(InjectedTextProvider);
    // this.deliveryStoreProvider = injector.get(DeliveryStoreProvider);
    this.localizationStoreProvider = injector.get(LocalizationStoreProvider);
    this.contentStoreProvider = injector.get(ContentStoreProvider);
    this.cartStoreProvider = injector.get(CartStoreProvider);
    this.productStoreProvider = injector.get(ProductStoreProvider);
    const languageFromURL = this.activatedRoute.snapshot.params['language'];
    debug(this.activatedRoute);
    this.saveCurrentLanguage(languageFromURL);
    this.breadcrumb = BreadcrumbModel.createDefaultInstance();
    this.localizationStoreProvider.addObserver('modeso_core', this.injectedTextProvider);
    // this.deliveryStoreProvider.loadAll();
    this.cookieService = injector.get(CookieService);
    this.jwtService = injector.get(JWTService);
  }

  ngOnInit(): void {
    this.loadLocalizedStrings();
    this.loadContent();
    this.setDebuggingMode();
    this.checkAndAddNewCartOnLoad();
  }

  checkAndAddNewCartOnLoad() {
    if (!localStorage.getItem('cartId')) {
      this.cartStoreProvider.addNewCart();
    } else {
      this.cartStoreProvider.getActiveCart$().subscribe( cart => {
      if (!cart || cart._id !== localStorage.getItem('cartId')) {
        this.cartStoreProvider.addNewCart();
      } else {
        const token = this.cookieService.get('token');
        const userIdFromToken  =  this.jwtService.getUserFromJWT(token);
        if (cart.userId !== userIdFromToken) {
          this.cartStoreProvider.addNewCart();
        }
      }
    });
   }
  }

  setDebuggingMode() {
    localStorage.debug = environment.enableDebugging;
  }

  loadLocalizedStrings() {
    this.localizationStoreProvider.loadLocalizations();
  }

  loadContent() {
    this.contentStoreProvider.loadContents();
  }

  isLanguageAvaliable(LanguageFromURL): boolean {
    return environment.availableLanguages.find(lang => lang === LanguageFromURL) ? true : false;
  }

  saveCurrentLanguage(language) {
    debug('current language is ' + language);
    if (this.isLanguageAvaliable(language)) {
      this.localStorageService.setCurrentLanguage(language);
    }
  }

  getCurrentLanguage() {
    return this.localStorageService.getCurrentLanguage();
  }

  saveSelectedDeliveryOption(selectedDeliveryOption: string) {
    this.localStorageService.setSelectedDeliveryOption(selectedDeliveryOption);
  }

  getSelectedDeliveryOption(): string {
    return this.localStorageService.getSelectedDeliveryOption();
  }

  setSelectedDeliveryOptionIndex(selectedDeliveryOptionIndex: string) {
    this.localStorageService.setSelectedDeliveryOptionIndex(selectedDeliveryOptionIndex);
  }

  getSelectedDeliveryOptionIndex() {
    return this.localStorageService.getSelectedDeliveryOptionIndex();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }
}
