import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountriesJson } from '../../shared/countriesJson';
import { AddressModel } from '../../models/address.model';
import { Observable } from 'rxjs';


@Component({
  selector: 'lidl-lib-address-fe-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})

export class AddressDetailsComponent implements OnInit {
  /**
   * src:x image for wrong validation.
   */
  @Input() title;
  @Input() src;
  @Input() isBillingAddress;

  @Input() address: Observable<AddressModel>;

  @Output() inputsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() inputsData: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();
  @Output() alertMsg: EventEmitter<boolean> = new EventEmitter<boolean>();

  address$: Observable<AddressModel>;
  addressForm: FormGroup;

  fieldErrorFlags = {};
  /**
   * countries  dropdown list.
   * default:choose default country is switherland.
   */
  countries = [];
  default: string = 'CH';

  /**
   *
   * @param fb.
   * @param store.
   */
  constructor(fb: FormBuilder) {
    this.initializeAddressForm(fb);
  }

  ngOnInit() {
    this.countries = CountriesJson;
    this.onChanges();
    this.emitInputsValid();
    this.emitInputsData();
    this.insertAddressFormData();
  }

  /**
   * address form to insert initial data and validation.
   * @param fb.
   */
  initializeAddressForm(fb) {
    const allowedCharacters = 'A-Za-z \\-ÁáÀàÅåÃãÄäÂâĆćÇçĈĉḐḑÉéÈèËëÊêĢģĜĝĤĥÍíÌìĨĩÏïÎîĴĵḰḱĶķĹĺĻļŃńŅņÑñÓóÒòØøÕõÔôŔŕŖŗŚśŞşŜŝŢţÚúÙùŨũÛûŴŵÝýŸÿŶŷŹźÆæŒœ»«ÖöüÜ\'`'
    this.addressForm = fb.group({
      company: [null,[Validators.pattern(`^[,.&0-9${allowedCharacters}]+$`)]],
      firstName: [null, [Validators.required, Validators.pattern(`^[${allowedCharacters}]+$`)]],
      lastName: [null, [Validators.required, Validators.pattern(`^[${allowedCharacters}]+$`)]],
      street: [null, [Validators.required, Validators.pattern(`^[${allowedCharacters}]+$`)]],
      streetNumber: [null, [Validators.required, Validators.pattern('^[0-9a-zA-Z]{1,4}$')]],
      addressSupplement: [null, [Validators.pattern(`^[${allowedCharacters},.0-9]*$`)]],
      zipCode: [null, [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      city: [null, [Validators.required, Validators.pattern(`^[${allowedCharacters}]*$`)]],
      country: [this.default],
      email: [null, [Validators.required, Validators.pattern('^[^@]+@[^.]+\.[^@]+$')]],
    }, { updateOn: 'change' });
  }

  /**
   * for update address ,insert old data in each input.
   */
  insertAddressFormData() {
    if (this.address) {
      this.address.subscribe(data => {
        if (data) {
          this.addressForm.patchValue({
            company: data.company,
            firstName: data.firstName,
            lastName: data.lastName,
            street: data.street,
            streetNumber: data.streetNumber,
            addressSupplement: data.addressSupplement,
            zipCode: data.zipCode,
            city: data.city,
            country: data.country,
            email: data.email,
          });
        }
      });
    }
  }

  get company() {
    return this.addressForm.get('company');
  }

  get firstName() {
    return this.addressForm.get('firstName');
  }

  get lastName() {
    return this.addressForm.get('lastName');
  }

  get street() {
    return this.addressForm.get('street');
  }

  get streetNumber() {
    return this.addressForm.get('streetNumber');
  }

  get addressSupplement() {
    return this.addressForm.get('addressSupplement');
  }

  get zipCode() {
    return this.addressForm.get('zipCode');
  }

  get city() {
    return this.addressForm.get('city');
  }

  get email() {
    return this.addressForm.get('email');
  }

  /**
   * not allow input contain only white space as input.
   * @param control.
   */
  noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  emitInputsValid() {
    this.inputsValid.emit(this.addressForm.valid);
  }

  emitInputsData() {
    this.inputsData.emit(this.addressForm.value);
  }

  emitAlertMsg() {
    this.alertMsg.emit(document.getElementsByName('requiredField').length === 0 ? false : true);
  }

  onChanges(): void {
    this.addressForm.valueChanges.forEach(val => {
      this.emitInputsValid();
      this.emitInputsData();
      this.emitAlertMsg();
    });
  }

  getCurrentLanguage(): string {
    return localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : `de-ch`;
  }

  checkForFieldError(field: any, fieldName: string) {
    console.log(field);
    if (field && fieldName) {
      if (field.touched) {
        this.fieldErrorFlags[fieldName] =  field.invalid;
      } else {
        this.fieldErrorFlags[fieldName] = false;
      }
    }
  }
}
