import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { CartOrchestrator } from '../../orchestrator/cart.orchestrator';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductNotificationModel } from '../../shared/models/product-notification.model';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { AuthenticationService } from '@modeso/modeso-lib-auth-fe';

@Component({
  selector: 'app-page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage extends BasePageComponent implements OnInit {

  errorMessage: string;
  hasError: boolean;
  categoryContentRef: string;
  productNotificationModel: ProductNotificationModel;
  isError: boolean;
  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    public cartOrchestrator: CartOrchestrator,
    private authenticationService: AuthenticationService) {
    super(injector);
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
    .subscribe((breadcrumbTitle) =>
      this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(routeParams => {
      this.categoryContentRef = 'modeso_shop_products_category-' + routeParams.cat;
    });
  }

  handleProductNotificationEvent(productNotification: ProductNotificationModel) {
    this.productNotificationModel = productNotification;
  }

  handleRedirectToCartEvent() {
    this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}/cart/${localStorage.getItem('cartId')}`);
  }

}
