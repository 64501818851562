import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderConfig, OrderConfigService } from '../order.config';
import { Order } from '../models/order.model';


export interface OrderResponse {
  order: Order;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private server = '';
  private microserviceName = 'order';
  private readonly route = '/';
  private readonly detailRoute = ':id';
  private readonly sendConfirmDigital = 'sendConfirmDigitalEmails';
  /**
   *
   * @param http
   * @param config
   * @param addressLocalStorageService
   */
  constructor(
    private http: HttpClient, @Inject(OrderConfigService) private config) {
    this.server = config.apiGateway;

  }

  /**
   * service to post delivery address.
   * @param data
   */
  postOrder(data: any) {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.post<OrderResponse>(url, data);
  }

  getById(id: string) {
    const contentId = this.detailRoute.replace(':id', id);
    const url = `${this.server}${this.microserviceName}${this.route}${contentId}`;
    return this.http.get<OrderResponse>(url);
  }

  sendDigitalConfirmEmailsByOrderId(orderId: string) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.sendConfirmDigital}${this.route}${orderId}`;
    return this.http.get<any>(url);
  }
}
