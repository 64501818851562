import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromPayment from './reducers/payment.reducer';

import { PaymentConfig, PaymentConfigService } from './payment.config';
import { CheckoutTermsComponent } from './components/checkout-terms/checkout-terms.component';
import { CheckoutPaymentOptionsComponent } from './components/checkout-payment-options/checkout-payment-options.component';
import { PaymentService } from './services/payment.service';
import { PaymentEffects } from './effects/payment.effects';
import { CheckoutPaymentSelectedComponent } from './components/checkout-payment-selected/checkout-payment-selected.component';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { PaymentSelectedViewComponent } from './components/payment-selected-view/payment-selected-view.component';
@NgModule({
  declarations: [CheckoutTermsComponent,
    CheckoutPaymentOptionsComponent, CheckoutPaymentSelectedComponent, PaymentSelectedViewComponent],
  providers: [PaymentService],
  imports: [
    CommonModule,
    InjectedTextFeautureModule,
    StoreModule.forFeature(fromPayment.paymentOptionsFeatureKey, fromPayment.reducer),
    EffectsModule.forFeature([PaymentEffects]),
  ],
  exports: [CheckoutTermsComponent,
    CheckoutPaymentOptionsComponent,
    CheckoutPaymentSelectedComponent,
    PaymentSelectedViewComponent]
})
export class PaymentModule {
  static forRoot(config: PaymentConfig): ModuleWithProviders {
    return {
      ngModule: PaymentModule,
      providers: [
        PaymentService,
        {
          provide: PaymentConfigService,
          useValue: config
        },
      ]
    };
  }
}
