import { Component, Input } from '@angular/core';
import { PaymentOpionModel } from '../../models/payment-option.model';
@Component({
  selector: 'lidl-lib-payment-fe-payment-selected-view',
  templateUrl: './payment-selected-view.component.html',
  styleUrls: ['./payment-selected-view.component.scss']
})
export class PaymentSelectedViewComponent {

  @Input() paymentOption: PaymentOpionModel;

  constructor() { }

}
