import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
declare var gtag;

@Component({
  selector: "app-cookie-banner",
  templateUrl: "./cookie-banner.component.html",
  styleUrls: ["./cookie-banner.component.scss"],
})
export class CookieBannerComponent implements OnInit {
  public showBanner: boolean;
  gkomfort: any = false;
  gstatistic: any = false;
  gmarketing: any = false;
  isShowDiv = true;
  @ViewChild("content", { static: true }) content: TemplateRef<any>;
  modalReference: any;

  public constructor(private modalService: NgbModal) {}

  ngOnInit() {
    const consent = JSON.parse(localStorage.getItem("cookieconsent"))
    if (!consent || consent.ad_storage === undefined || consent.analytics_storage === undefined) {
      this.triggerModal();
    }
  }

  agreeAll() {
    const consent =  {
      ad_storage: "granted",
      analytics_storage: "granted",
    }
    localStorage.setItem("cookieconsent", JSON.stringify(consent));
    gtag("consent", "update", consent);
    this.modalReference.close();
  }

  agreeToShare() {
    const consent = {
      ad_storage: this.gmarketing === true ? "granted" : "denied",
      analytics_storage: this.gstatistic === true ? "granted" : "denied",
    }
    if (this.gkomfort == true) {
      localStorage.setItem("cookieconsent", JSON.stringify(consent));
    }

    if (typeof gtag !== "undefined") {
      gtag("consent", "update", consent);
    }

    this.modalReference.close();
  }

  triggerModal() {
    this.modalReference = this.modalService.open(this.content, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      size: "lg",
      windowClass: "cookie-popup",
    });
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }
}
