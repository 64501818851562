import { StoreModule } from '@ngrx/store';
import * as fromBreadcrumb from './reducers/breadcrumb.reducer';
import { NgModule } from '@angular/core';
import { BreadcrumbStoreProvider } from './provider/breadcrumbStore.provider';
import { CheckoutStepsComponent } from './components/checkout-steps/checkout-steps.component';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './components/modeso-title/title.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';

@NgModule({
    declarations: [
        CheckoutStepsComponent,
        TitleComponent,
        LoadingSpinnerComponent,
        ErrorAlertComponent
    ],
    providers: [
        BreadcrumbStoreProvider
    ],
    imports: [
        CommonModule,
        InjectedTextFeautureModule,
        StoreModule.forFeature(fromBreadcrumb.breadcrumbFeatureKey, fromBreadcrumb.reducer)
    ],
    exports: [CheckoutStepsComponent, TitleComponent, LoadingSpinnerComponent, ErrorAlertComponent]
})
export class SharedModule { }
