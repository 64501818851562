/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./static-content-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../static-sub-content/static-sub-content.component.ngfactory";
import * as i3 from "../static-sub-content/static-sub-content.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./static-content-list.component";
var styles_ShopStaticContentListComponent = [i0.styles];
var RenderType_ShopStaticContentListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShopStaticContentListComponent, data: {} });
export { RenderType_ShopStaticContentListComponent as RenderType_ShopStaticContentListComponent };
function View_ShopStaticContentListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-static-content", [], null, null, null, i2.View_ShopStaticSubContentComponent_0, i2.RenderType_ShopStaticSubContentComponent)), i1.ɵdid(1, 573440, null, 0, i3.ShopStaticSubContentComponent, [i4.Store], { contentId: [0, "contentId"], requestDataFromBackend: [1, "requestDataFromBackend"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.contentId; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ShopStaticContentListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "static-content-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ShopStaticContentListComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.contents$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShopStaticContentListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-static-content-list", [], null, null, null, View_ShopStaticContentListComponent_0, RenderType_ShopStaticContentListComponent)), i1.ɵdid(1, 573440, null, 0, i6.ShopStaticContentListComponent, [i4.Store], null, null)], null, null); }
var ShopStaticContentListComponentNgFactory = i1.ɵccf("app-static-content-list", i6.ShopStaticContentListComponent, View_ShopStaticContentListComponent_Host_0, { filter: "filter" }, {}, []);
export { ShopStaticContentListComponentNgFactory as ShopStaticContentListComponentNgFactory };
