import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderService } from '../services/order.service';
import * as fromActions from './../actions/order.action';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { EMPTY } from 'rxjs';

const debug = Debug('modeso:lidl-lib-order-fe:OrderEffects');

@Injectable()
export class OrderEffects {
  /**
   *
   * @param actions$
   * @param service
   */
  constructor(
    private actions$: Actions, private service: OrderService) { }
  /**
   * Effect of post delivery address.
   */
  postOrder$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.postOrder.type),
      mergeMap(
        payload => {
          return this.service.postOrder(payload)
            .pipe(
              retry(1),
              map(
                response => (fromActions.onOrderPostedSuccessfully({ payload: response.order })),
              ),
              catchError((error) => of(fromActions.onOrderPostingFailed()))
            );
        }
      )
    )
  );

  loadById$ = createEffect(
    () => this.actions$.pipe(
      ofType<fromActions.ActionWithOrderId>(fromActions.getById.type),
      mergeMap(
        (action) => {
          debug(`order should be loaded for ${action.orderId}`);
          return this.service.getById(action.orderId)
            .pipe(
              retry(1),
              tap(
                (response) => this.log(response)
              ),
              map(
                response => (fromActions.onByIdLoadedSuccessfully({ payload: response.order }))
              ),
              catchError((error) => of(fromActions.onByIdLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  sendDigitalConfirmEmails$ = createEffect(
    () => this.actions$.pipe(
      ofType<fromActions.ActionWithOrderId>(fromActions.sendDigitalConfirmEmails.type),
      mergeMap(
        (action) => {
          return this.service.sendDigitalConfirmEmailsByOrderId(action.orderId)
            .pipe(
              map(
                () => (fromActions.onSendDigitalConfirmEmailsSuccessful())
              ),
              catchError(() => EMPTY)
            );
        }
      )
    )
  );

  errorOnLoadById$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onByIdLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllContentErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllContentErrors(error) {
    debug(error);
    return error;
  }

  log(data) {
    debug(data);
  }

}
