import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'lidl-lib-cart-fe-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss']
})
export class CartIconComponent implements OnInit {

  @Input() src: string;
  @Input() srcset: string;
  @Input() cartItemsAmount: number;

  constructor() { }

  ngOnInit(): void {
    this.cartItemsAmount = 0;
  }
}
