import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DatePipe } from '@angular/common';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { OrderConfig, OrderConfigService } from './order.config';
import { OrderTitleComponent } from './components/order-title/order-title.component';
import { ShortEmailDetailsComponent } from './components/short-email-details/short-email-details.component';
import { OrderSubTitleComponent } from './components/order-sub-title/order-sub-title.component';
import { OrderCheckoutComponent } from './components/order-checkout/order-checkout.component';
import { OrderInformationComponent } from './components/order-information/order-information.component';
import * as fromAddress from './reducers/order.reducer';
import { OrderEffects } from './effects/order.effects';
import { OrderService } from './services/order.service';

@NgModule({
  declarations: [OrderTitleComponent, ShortEmailDetailsComponent, OrderSubTitleComponent, OrderCheckoutComponent,
    OrderInformationComponent],
  providers: [OrderService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddress.featureKey, fromAddress.reducer),
    EffectsModule.forFeature([OrderEffects]),
    InjectedTextFeautureModule
  ],
  exports: [OrderCheckoutComponent, OrderInformationComponent]
})
export class OrderModule {

  static forRoot(config: OrderConfig): ModuleWithProviders {
    return {
      ngModule: OrderModule,
      providers: [
        OrderService,
        {
          provide: OrderConfigService,
          useValue: config
        },
      ]
    };
  }
}
