import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModesoLocalizationConfigService } from './../localization.config';
import { LocalizationModel } from '../models/localization.model';

export interface LocalizationResponse {
    localizations: Array<LocalizationModel>;
}

export interface LocalizationDetailResponse {
    localization: LocalizationModel;
}


@Injectable({
    providedIn: 'root',
})
export class LocalizationService {

    private server = '';

    private microserviceName = 'localization';
    private readonly route = '/';
    private readonly detailRoute = ':id';
    private cacheHeader =  new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      });

    constructor(private http: HttpClient, @Inject(ModesoLocalizationConfigService) private config) {
        this.server = config.apiGateway;
    }

    getLocalizations() {
        const url = `${this.server}${this.microserviceName}${this.route}`;
        return this.http.get<LocalizationResponse>(url, {headers: this.cacheHeader});
    }

    getLocalizationByKey(key: string) {
        const keyId = this.detailRoute.replace(':id', key);
        const url = `${this.server}${this.microserviceName}${this.route}${keyId}`;
        return this.http.get<LocalizationDetailResponse>(url, {headers: this.cacheHeader});
    }

}

