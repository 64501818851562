import { NgModule } from '@angular/core';
import { ProductsModule } from '@modeso/giftcardshop-lib-products-fe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    RouterModule,
    ProductsModule,
  ],
  exports: []
})
export class AppProductsModule {
}
