import { createAction, props, Action } from '@ngrx/store';
import { ContentModel } from '../models/content.model';

export const getAllContent = createAction('[Content] getAllContent');
export const onAllContentLoadedSuccessfully = createAction(
    '[Content] all content loaded successfully',
    props<{payload: Array<ContentModel>}>()
);
export const onAllContentLoadingFailed = createAction(
    '[Content] all content loading failed',
    props<{payload: any}>()
);
export const getContentById = createAction(
    '[Content] getContentById',
    props<{contentId: string}>()
);
export const onContentByIdLoadedSuccessfully = createAction(
    '[Content] contentById loaded successfully',
    props<{payload: ContentModel}>()
);
export const onContentByIdLoadingFailed = createAction(
    '[Content] contentById loading failed',
    props<{payload: any}>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}

export interface ActionWithContentId extends Action {
    contentId: string;
}
