<div class="address-container">
  <!--start title -->
  <div class="row">
    <modeso-title [titleText]="('modeso_address_address_page-title' | localize | async)"></modeso-title>
  </div>
  <!-- start steps -->
  <div class="row">
    <modeso-checkout-steps [activeStep]="1"></modeso-checkout-steps>
  </div>


  <lidl-lib-address-fe #addressComponent src="assets/lidl-lib-address-fe/times-light/times-light.svg">
    <modeso-compact-cart [additionalCharges]="selectedDeliveryOption.price">
      <tr afterProductList>
        <td id="shippingTitle" class="shipping-title"> {{ ("modeso_shop_page_"+selectedDeliveryOption.key+"_name") | localize | async }} </td>
        <td id="shippingPrice" class="price"> CHF {{ selectedDeliveryOption.price | number : '1.2' }}</td>
      </tr>
      <!-- save button -->
      <div afterTotal class="save-button">
        <button class="btn-cta" id="saveAddressButton" [disabled]="addressComponent.buttonStatus()" (click)="addressComponent.submit()">{{ "modeso_address_address_go-to-payment" | localize | async }}</button>
      </div>
    </modeso-compact-cart>
  </lidl-lib-address-fe>

</div>
