/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cart.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@modeso/giftcardshop-lib-delivery-fe/modeso-giftcardshop-lib-delivery-fe.ngfactory";
import * as i3 from "@modeso/giftcardshop-lib-delivery-fe";
import * as i4 from "@modeso/modeso-lib-localization-fe";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../../node_modules/@modeso/giftcardshop-lib-cart-fe/modeso-giftcardshop-lib-cart-fe.ngfactory";
import * as i7 from "@modeso/giftcardshop-lib-cart-fe";
import * as i8 from "@angular/common";
import * as i9 from "./cart.page";
import * as i10 from "@angular/router";
var styles_CartPage = [i0.styles];
var RenderType_CartPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CartPage, data: {} });
export { RenderType_CartPage as RenderType_CartPage };
function View_CartPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lidl-lib-delivery-fe-select-option", [], null, [[null, "selectedDeliveryOption"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDeliveryOption" === en)) {
        var pd_0 = (_co.updateSelectedDeliveryOption($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵdid(1, 638976, null, 0, i3.ɵa, [], { deliveryOptions: [0, "deliveryOptions"], currentSelectedRadioButton: [1, "currentSelectedRadioButton"] }, { selectedDeliveryOption: "selectedDeliveryOption" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deliveryOptions; var currVal_1 = _co.selectedDeliveryOptionIndex; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CartPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.ɵa, [i5.Store]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "lidl-lib-cart-fe", [], null, [[null, "cartIdToCartPAge"], [null, "cartProductsAmount"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cartIdToCartPAge" === en)) {
        var pd_0 = (_co.emitCurrentDeliveryOption($event) !== false);
        ad = (pd_0 && ad);
    } if (("cartProductsAmount" === en)) {
        var pd_1 = (_co.checkIsCartEmpty($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ɵj_0, i6.RenderType_ɵj)), i1.ɵdid(2, 114688, null, 0, i7.ɵj, [i5.Store, i7.ɵf], { additionalCharges: [0, "additionalCharges"], deliveryRef: [1, "deliveryRef"] }, { cartIdToCartPAge: "cartIdToCartPAge", cartProductsAmount: "cartProductsAmount" }), (_l()(), i1.ɵand(16777216, [["deliveryOptionsTemplate", 2]], 0, 1, null, View_CartPage_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, 1, 10, "div", [["afterTotal", ""], ["class", "action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "proceed-button action-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn-cta"], ["id", "ProceedToCheckout"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToCheckout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 1), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "continue-shopping-button action-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "btn-cta btn-cta-secondary"], ["id", "continueShopping"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToProducts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵppd(14, 1), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.priceOfSelectedDeliveryOption; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.deliveryOptions.length !== 0); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (!_co.selectedDeliveryOption || _co.isCartEmpty); _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), "modeso_cart_go-to-checkout")))); _ck(_v, 8, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 15).transform(i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), "modeso_cart_continue-shopping")))); _ck(_v, 13, 0, currVal_5); }); }
export function View_CartPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-cart", [], null, null, null, View_CartPage_0, RenderType_CartPage)), i1.ɵdid(1, 245760, null, 0, i9.CartPage, [i10.ActivatedRoute, i1.Injector, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CartPageNgFactory = i1.ɵccf("app-page-cart", i9.CartPage, View_CartPage_Host_0, {}, {}, []);
export { CartPageNgFactory as CartPageNgFactory };
