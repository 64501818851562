import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ServerSideStorage } from '../storages/serverside.storage';
import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor(platformId) {
        this.platformId = platformId;
        this.serviceSideStorage = new ServerSideStorage();
    }
    /**
     * get current language from localStorage
     */
    getCurrentLanguage() {
        const currentLanguage = this.getLocalStorage().getItem(LocalStorageService.currentLanguageKey);
        return currentLanguage ? currentLanguage : environment.defaultLanguage;
    }
    /**
     * set current language to localStorage
     */
    setCurrentLanguage(lang) {
        this.getLocalStorage().setItem(LocalStorageService.currentLanguageKey, lang);
    }
    /**
     * check if current language is saved in localStorage
     */
    isSetCurrentLanguage() {
        const currentLanguage = this.getLocalStorage().getItem(LocalStorageService.currentLanguageKey);
        return currentLanguage ? true : false;
    }
    getLocalStorage() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage;
        }
        else {
            return this.serviceSideStorage;
        }
    }
    setSelectedDeliveryOption(selectedDeliveryOption) {
        this.getLocalStorage().setItem(LocalStorageService.selectedDeliveryOption, selectedDeliveryOption);
    }
    getSelectedDeliveryOption() {
        return this.getLocalStorage().getItem(LocalStorageService.selectedDeliveryOption);
    }
    setSelectedDeliveryOptionIndex(selectedDeliveryOptionIndex) {
        this.getLocalStorage().setItem(LocalStorageService.selectedDeliveryOptionIndex, selectedDeliveryOptionIndex);
    }
    getSelectedDeliveryOptionIndex() {
        return this.getLocalStorage().getItem(LocalStorageService.selectedDeliveryOptionIndex);
    }
}
LocalStorageService.currentLanguageKey = 'currentLanguage';
LocalStorageService.userTokenKey = 'UID';
LocalStorageService.selectedDeliveryOption = 'selectedDeliveryOption';
LocalStorageService.selectedDeliveryOptionIndex = 'selectedDeliveryOptionIndex';
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.ɵɵinject(i0.PLATFORM_ID)); }, token: LocalStorageService, providedIn: "root" });
