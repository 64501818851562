import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressUtils } from '../../utils/address-utils';
import { AddressModel } from './../../models/address.model';


@Component({
  selector: 'modeso-compact-address-details',
  templateUrl: './compact-address-details.component.html',
  styleUrls: ['./compact-address-details.component.scss']
})
export class CompactAddressDetailsComponent implements OnInit {
  @Input() address: Observable<AddressModel>;
  @Input() addressType: string;
  @Input() isBillingAddress: boolean;

  constructor() { }

  ngOnInit() {
    this.address.subscribe(addressData => {
      if (addressData) {
        addressData.countryName = AddressUtils.getCountryNameByCode(addressData.country);
      }
    });
  }

}
