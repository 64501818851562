import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../reducers/product-details.reducer';
import * as fromFeatureProducts from './../reducers/products.reducer';
import { ProductDetailsModel } from './../models/product-details.model';
import { NamedString, InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import * as productsActions from './../actions/products.actions';
import { Observable } from 'rxjs';

const strings = [
    { 'modeso_cart_add-to-cart_total_exceeded_error': 'modeso_cart_add-to-cart_total_exceeded_error' }
  ];

@Injectable({
    providedIn: 'root'
})
export class ProductStoreProvider {

    constructor(public store: Store<fromFeature.AppState | fromFeatureProducts.AppState >,
                private injectedTextProvider: InjectedTextProvider) {
        const defaultStrings = new Array<NamedString>();
        strings.forEach((element) => {
            const x = new NamedString(Object.keys(element)[0], element[Object.keys(element)[0]]);
            defaultStrings.push(x);
          });
        this.setInjectedText(defaultStrings);
    }

    public getProduct$(productId: string): Observable<ProductDetailsModel> {
        if (productId) {
            const ret = this.store.pipe(select(fromFeature.selectEntitiesByID, { id: productId }));
            //this.store.dispatch(getProductById({productId}));
            return ret;
        } else {
            return undefined;
        }
    }
    private setInjectedText(injectedText: Array<NamedString>) {
        this.injectedTextProvider.setInjectedText(injectedText);
      }

    public getProductsByCategory$(productCategory: string): Observable<ProductDetailsModel[]> {
        this.store.dispatch(productsActions.getProductsByCategory({ productCategory }));
        return this.store.pipe(
            select(fromFeatureProducts.selectProductsByCategory)
        );
    }
}
