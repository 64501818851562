<header>
    <div class="header-top">
        <div class="container">
            <div class="row">
                <div class="my-auto">
                    <span class="operator-url">{{ "modeso_shop_header_operator-url" | localize | async }}</span>
                </div>
                <div class="ml-auto my-auto">
                    <modeso-language-switcher [availableLanguages]="availableLanguages"></modeso-language-switcher>
                </div>
            </div>
        </div>
    </div>
    <div class="container header-middle">
        <div class="row">
            <div class="col-auto">
                <a routerLink="{{getCurrentLanguage()}}">
                    <img src="assets/skeleton/logo/logo-lidl-ch.png" class="logo">
                </a>
            </div>
            <div class="col teaser-text my-auto">
                {{ "modeso_shop_header_operator-slogan" | localize | async }}
            </div>
            <div class="col-auto my-auto" (click)="onCartIconClick()">
                <span class="cart-icon-wrapper">
                    <lidl-lib-cart-fe-icon src="assets/lidl-lib-cart-fe/cart-icon.svg" [cartItemsAmount]="itemsCounter">
                        {{ "modeso_cart_cart-icon-label" | localize | async }}
                    </lidl-lib-cart-fe-icon>
                </span>
            </div>
            <div class="col-auto my-auto menu-icon" (click)="toggleNavigationDrawer()">
                <svg _ngcontent-serverapp-c7="" class="cart-icon" id="icon">
                    <use _ngcontent-serverapp-c7="" xlink:href="assets/skeleton/footer/icon__sprite.svg#burger"></use>
                </svg>
            </div>
        </div>
    </div>
    <div [attr.data-visible]="openMenu" class="container header-bottom">
        <nav class="row">
            <div class="header-nav" class="col" >
                <span class="header-nav-link"><a routerLinkActive="active" (click)="toggleNavigationDrawer()" routerLink="{{getCurrentLanguage()}}/products/physical">{{ "modeso_shop_header_navigation-link1" | localize | async }}</a></span>
                <span class="header-nav-link"><a routerLinkActive="active" (click)="toggleNavigationDrawer()" routerLink="{{getCurrentLanguage()}}/products/digital">{{ "modeso_shop_header_navigation-link2" | localize | async }}</a></span>
                <span class="header-nav-link"><a routerLinkActive="active" (click)="toggleNavigationDrawer()" routerLink="{{getCurrentLanguage()}}/content/faq">{{ "modeso_shop_header_navigation-link3" | localize | async }}</a></span>
                <span class="header-nav-link"><a routerLinkActive="active" (click)="toggleNavigationDrawer()" routerLink="{{getCurrentLanguage()}}/balance-check">{{ "modeso_shop_header_navigation-link4" | localize | async }}</a></span>
            </div>
        </nav>
    </div>
</header>
