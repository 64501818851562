import { ProductVariablePriceModel } from './product-variable-price.model';

export class CartProductModel {

  public _id?: string;
  public productId: string;
  public ean: string;
  public title: string;
  public image: string;
  public description: string;
  public price: number;
  public amount: number;
  public packagePrice: number;
  public variablePrice: ProductVariablePriceModel;
  public isPackagingSelected?: boolean;
  public category: string;
  public pdfTemplatePath: string;
  constructor(init?: Partial<CartProductModel>) { }
}
