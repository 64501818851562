import { Component, OnInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../../reducers/cart.reducer';
import * as cartActions from './../../actions/cart.actions';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CartResponseModel } from '../../models/response/cart-response.model';
import { DeliveryOptionModel } from '../../models/delivery-options.model';
import { CartLocalStorageService } from '../../services/cartLocalStorageService.service';

@Component({
  selector: 'lidl-lib-cart-fe',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  @Input() additionalCharges: number;

  carts: CartResponseModel;

  @Output() cartIdToCartPAge = new EventEmitter<string>();

  @Output() cartProductsAmount = new EventEmitter();

  @Input() deliveryRef: TemplateRef<any>;


  constructor(private store: Store<fromFeature.AppState>, private cartLocalStorageService: CartLocalStorageService) {

  }

  ngOnInit(): void {
    if (this.cartLocalStorageService.isSetCartId()) {
      this.store.dispatch(cartActions.onLoadCart());
    }
    this.store.pipe(select(fromFeature.featureCart)).subscribe(cart => {
      this.carts = cart;
      if (cart && cart.cart) {
        this.cartProductsAmount.emit(cart.cart.products);
      }
    });
  }

  emitCurrentCartId(cartId: string) {
    this.cartIdToCartPAge.emit(cartId);

  }
}
