import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { ModesoCartConfig, ModesoCartConfigService } from './cart.config';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { FormsModule } from '@angular/forms';
import { CartIconComponent } from './components/cart-icon/cart-icon.component';
import { StoreModule } from '@ngrx/store';
import * as fromCart from './reducers/cart.reducer';
import { CartService } from './services/cart.service';
import { EffectsModule } from '@ngrx/effects';
import { CartEffect } from './effects/cart.effect';
import { ProductPackageComponent } from './components/product-package/product-package.component';
import { CartComponent } from './components/cart/cart.component';
import { CartProductListComponent } from './components/cart-product-list/cart-product-list.component';
import { ModesoCounterComponent } from './components/modeso-counter/modeso-counter.component';
import { CartTotalComponent } from './components/cart-total/cart-total.component';
import { CompactCartComponent } from './components/compact-cart/compact-cart.component';
import { DatePipe } from '@angular/common';
import { RefreshCartStoreComponent } from './components/refreshCart/refresh-cart-store.component';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { CompactCartViewComponent } from './components/compact-cart-view/compact-cart-view.component';

@NgModule({
  declarations: [AddToCartComponent, CartIconComponent, CompactCartComponent,
    ProductPackageComponent, CartComponent, CartProductListComponent,
    ModesoCounterComponent, CartTotalComponent, RefreshCartStoreComponent,
    CompactCartViewComponent],
  providers: [CartService, DatePipe],
  imports: [
    CommonModule,
    FormsModule,
    InjectedTextFeautureModule,
    StoreModule.forFeature(fromCart.cartFeatureKey, fromCart.reducer),
    EffectsModule.forFeature([CartEffect]),
  ],
  exports: [AddToCartComponent, CartIconComponent, ProductPackageComponent, CartComponent,
    ModesoCounterComponent, CartTotalComponent, CompactCartComponent, RefreshCartStoreComponent,
    CompactCartViewComponent]
})
export class ModesoCartModule {
  static forRoot(config: ModesoCartConfig): ModuleWithProviders {
    return {
      ngModule: ModesoCartModule,
      providers: [
        CartService,
        {
          provide: ModesoCartConfigService,
          useValue: config
        }
      ]
    };
  }
}
