import { ContentComponent } from '@modeso/giftcardshop-lib-content-fe';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromFeature from '@modeso/giftcardshop-lib-content-fe/lib/reducers/content.reducer';

/**
 * to make overwriteting component work, we need to add the original template and style files.
 *
 * with the "local" scss file, we can overwrite the styles.
 */
@Component({
  selector: 'app-static-content',
  templateUrl: './static-sub-content.component.html',
  styleUrls: [
        '../content/contentMicroservice.component.scss',
        './static-sub-content.component.scss'
    ]
})
export class ShopStaticSubContentComponent extends ContentComponent {

  constructor(public store: Store<fromFeature.AppState>) {
    super(store);
  }


}
