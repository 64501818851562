import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddressService } from '../services/address.service';
import * as fromActions from './../actions/address.action';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AddressLocalStorageService } from '../services/addressLocalStorageService.service';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-address-fe:AddressEffects');

@Injectable()
export class AddressEffects {
  /**
   *
   * @param actions$
   * @param service
   * @param addressLocalStorageService
   */
  constructor(
    private actions$: Actions, private service: AddressService,
    private addressLocalStorageService: AddressLocalStorageService) { }


  errorOnPostDeliveryAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onDeliveryAddressPostingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });

  errorOnPostBillingAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onBillingAddressPostingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });


  /**
   * Effect of load delivery address error.
   */
  errorOnLoadDeliveryAddressById$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onAddressDeliveryByIdLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });


  /**
   * Effect of load billing address error.
   */
  errorOnLoadBillingAddressById$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onAddressDeliveryByIdLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });

  /**
   * Effect of put delivery address error.
   */
  errorOnPutDeliveryAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onPutDeliveryAddressFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });

  /**
   * Effect of put billing address error.
   */
  errorOnPutBillingAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onPutBillingAddressFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });


  /**
   * Effect of delete billing address error.
   */
  errorOnDeleteBillingAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onDeleteAddressBillingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleAddresddErrors(action.payload)
      )
    )
    , { dispatch: false });

  /**
   * log address error and return error.
   * @param error
   */
  handleAddresddErrors(error) {
    debug(error);
    return error;
  }

  /**
   * log data.
   * @param data
   */
  log(data) {
    debug(data);
  }
}
