/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../content/contentMicroservice.component.scss.shim.ngstyle";
import * as i1 from "./static-sub-content.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./static-sub-content.component";
import * as i5 from "@ngrx/store";
var styles_ShopStaticSubContentComponent = [i0.styles, i1.styles];
var RenderType_ShopStaticSubContentComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ShopStaticSubContentComponent, data: {} });
export { RenderType_ShopStaticSubContentComponent as RenderType_ShopStaticSubContentComponent };
export function View_ShopStaticSubContentComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "lidl-lib-content-fe"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), i2.ɵpid(131072, i3.AsyncPipe, [i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(4, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i2.ɵpid(131072, i3.AsyncPipe, [i2.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i2.ɵunv(_v, 2, 0, i2.ɵnov(_v, 3).transform(_co.content$))) == null) ? null : tmp_0_0.title); _ck(_v, 2, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i2.ɵunv(_v, 4, 0, i2.ɵnov(_v, 5).transform(_co.content$))) == null) ? null : tmp_1_0.text); _ck(_v, 4, 0, currVal_1); }); }
export function View_ShopStaticSubContentComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-static-content", [], null, null, null, View_ShopStaticSubContentComponent_0, RenderType_ShopStaticSubContentComponent)), i2.ɵdid(1, 573440, null, 0, i4.ShopStaticSubContentComponent, [i5.Store], null, null)], null, null); }
var ShopStaticSubContentComponentNgFactory = i2.ɵccf("app-static-content", i4.ShopStaticSubContentComponent, View_ShopStaticSubContentComponent_Host_0, { contentId: "contentId", requestDataFromBackend: "requestDataFromBackend" }, {}, []);
export { ShopStaticSubContentComponentNgFactory as ShopStaticSubContentComponentNgFactory };
