import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../reducers/order.reducer';
import * as orderActions from './../actions/order.action';
import { NamedString, InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import { Order } from '../models/order.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Debug from 'debug';

const debug = Debug('modeso:lidl-lib-order-fe:OrderStoreProvider');

const strings = [
  {
    'modeso_order_order-checkout_order-not-saved-successfully':
      'Bestellung wurde nicht korrekt gespeichert. Bitte versuchen Sie es erneut.'
  },
  { 'modeso_order_order-checkout_order-not-sent': 'Bestellung konnte nicht abgesendet werden!' },
  { 'modeso_order_order-checkout_order-saved': 'Bestellung erfolgreich abgeschlossen' },
  { 'modeso_order_order-checkout_thank-you': 'Vielen Dank für Ihre Bestellung.' },
  { 'modeso_order_short-email-details_you-will-receive-email': 'In Kürze erhalten Sie eine Bestätigung per E-Mail.' },
  { 'modeso_order_short-email-details_order-referece-caption': 'Bestellreferenz:' },
  { 'modeso_order_short-email-details_order-delviery-date-caption': 'Voraussichtlicher Liefertermin:' },
];

@Injectable({
  providedIn: 'root'
})
export class OrderStoreProvider {

  constructor(public store: Store<fromFeature.AppState>, private injectedTextProvider: InjectedTextProvider) {
    const defaultStrings = new Array<NamedString>();
    strings.forEach((element) => {
      const x = new NamedString(Object.keys(element)[0], element[Object.keys(element)[0]]);
      defaultStrings.push(x);
    });

    this.setInjectedText(defaultStrings);
  }

  private setInjectedText(injectedText: Array<NamedString>) {
    this.injectedTextProvider.setInjectedText(injectedText);
  }

  /**
   * createNewOrder
   */
  public createNewOrder(order: Order) {
    debug('CREATE ORDER with...');
    debug(order);
    //TODO validate ORDER
    this.store.dispatch(orderActions.postOrder(order));
  }

  // TODO add a method to access the store property by key, which generates an observable which can be used in a pipe
  // Is this the solution?
  public getNewOrder$(): Observable<Order> {
    return this.store.pipe(
      select(fromFeature.selectFeatureOrder),
      map((state: fromFeature.FeatureState) => {
        debug('got order');
        debug(state);
        if (state.order) {
          return state.order;
        } else {
          debug('state does not contain an order');
          return undefined;
        }
      }));
  }

  public getOrderById$(orderId: string): Observable<Order> {
    return this.store.pipe(select(fromFeature.selectOrderByID, { id: orderId }));
  }

  public sendDigitalConfirmEmails(orderId: string) {
    this.store.dispatch(orderActions.sendDigitalConfirmEmails({ orderId }));
  }
}
