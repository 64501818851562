import { Component, OnChanges, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ContentModel } from './../../models/content.model';
import { Observable } from 'rxjs';
import * as contentActions from './../../actions/content.actions';
import * as fromFeature from './../../reducers/content.reducer';

@Component({
  selector: 'lidl-lib-content-fe',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnChanges {

  @Input() contentId;
  @Input() requestDataFromBackend = true;
  content$: Observable<ContentModel>;

  constructor(public store: Store<fromFeature.AppState>) { }

  ngOnChanges() {
    if (this.contentId === undefined) {
      console.log('contentId is not defined');
      return;
    } else {
      this.content$ = this.store.pipe(select(fromFeature.selectEntitiesByID, { id: this.contentId }));
    }
    if (this.requestDataFromBackend) {
      this.store.dispatch(contentActions.getContentById({ contentId: this.contentId }));
    }
  }


}
