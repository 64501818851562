import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as cartActions from './../actions/cart.actions';
import { CartProductModel } from '../models/cart-product.model';
import { CartResponseModel } from '../models/response/cart-response.model';
import { DeliveryOptionModel } from '../models/delivery-options.model';

export const cartFeatureKey = 'modesoCartMicroservice';

// State Declarations - START

export interface FeatureState {
  cart: CartResponseModel;
  product: CartProductModel;
  deliveryOption: DeliveryOptionModel;
  status: string;
}

export interface AppState {
  modesoCartMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoCartMicroservice;
export const featureCart = createSelector(
  selectFeature,
  (state: FeatureState) => state.cart
);

export const featureDeliveryOptionsOfCart = createSelector(
  selectFeature,
  (state: FeatureState) => state.cart.cart.deliveryOptions
);
export const featureDeliveryOption = createSelector(
  selectFeature,
  (state: FeatureState) => state.deliveryOption
);

export const featureStatusOfOperationsOnCart = createSelector(
  selectFeature,
  (state: FeatureState) => state.status
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  product: new CartProductModel(),
  cart: new CartResponseModel(),
  deliveryOption: new DeliveryOptionModel('', '', '', 0, '', 0, '', false),
  status: undefined
};

const cartReducer = createReducer(
  initialState,
  on(cartActions.addToCart, (state, action) => ({ ...state, product: action.payload })),
  on(cartActions.onAddToCartSuccess, (state, action) => ({ ...state, cart: action.payload, status: '' })),
  on(cartActions.onAddToCartFail, (state, action) => ({ ...state, cart: action.payload, status: 'addToCartFaild' })),
  on(cartActions.onChangeProductPackage, (state) => ({ ...state })),
  on(cartActions.onChangeProductPackageSuccess, (state, action) => ({ ...state, cart: action.payload })),
  on(cartActions.onChangeProductAmount, (state) => ({ ...state })),
  on(cartActions.onChangeProductAmountSuccess, (state, action) => ({ ...state, cart: action.payload, status: '' })),
  on(cartActions.onChangeProductAmountFail, (state) => ({ ...state, status: 'changeProductAmountFaild' })),
  on(cartActions.onLoadCart, (state) => ({ ...state })),
  on(cartActions.onLoadCartSuccess, (state, action) => ({ ...state, cart: action.payload })),
  on(cartActions.onRemoveProduct, (state) => ({ ...state })),
  on(cartActions.onRemoveProductSuccess, (state, action) => ({ ...state, cart: action.payload })),
  on(cartActions.onChangeCardDeliveryOptions, (state, action) => ({ ...state, deliveryOption: action.payload })),
  on(cartActions.addNewCart, (state) => ({ ...state })),
  on(cartActions.onNewToCartSuccess, (state, action) => ({ ...state, cart: action.payload })),

  on(cartActions.addSelectedDeliveryOption, (state) => ({ ...state })),
  on(cartActions.onAddSelectedDeliveryOptionSuccessfully, (state, action) => ({ ...state, deliveryOption: action.payload })),
  on(cartActions.onAddSelectedDeliveryOptionFailed, (state) => ({ ...state }))
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return cartReducer(state, action);
}
// Reducer Declarations - END
