import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductsConfigService } from './../products.config';
import { ProductDetailsModel } from './../models/product-details.model';

export interface ProductDetailResponse {
  product: ProductDetailsModel;
}

@Injectable({
  providedIn: 'root',
})

export class ProductDetailsService {

  private server = '';
  private microserviceName = 'products';
  private readonly productsRoute = '/';
  private readonly singleProductRoute = ':id';

  /**
   *
   * @param http
   * @param config
   */
  constructor(private http: HttpClient, @Inject(ProductsConfigService) private config) {
    this.server = config.apiGateway;
  }

  /**
   * Service to return product details data from backend using http.
   * @param id
   */
  getProductById(id: string) {
    const proudctId = this.singleProductRoute.replace(':id', id);
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${proudctId}`;
    return this.http.get<ProductDetailResponse>(url);
  }
}

