import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';

@Injectable({
  providedIn: 'root',
})
export class CartLocalStorageService {
  private static cartIdKey = 'cartId';
  public static cartDeletedEvent = new BehaviorSubject<boolean>(false);

  constructor() { }


  /**
   * set current cart to localStorage.
   */
  public setCartId(cartId: string) {
    this.getLocalStorage().setItem(
      CartLocalStorageService.cartIdKey, cartId
    );
  }


  /**
   * check if current cartId is saved in localStorage.
   */
  public isSetCartId(): boolean {
    const cartId = this.getLocalStorage().getItem(
      CartLocalStorageService.cartIdKey
    );
    return cartId ? true : false;
  }

  /**
   * return cart id.
   */
  public getCartId(): string {
    const cartId = this.getLocalStorage().getItem(
      CartLocalStorageService.cartIdKey
    );
    return cartId;
  }


  /**
   * delete cart id.
   */
  public deleteCartId() {
    this.getLocalStorage().removeItem(CartLocalStorageService.cartIdKey);
    CartLocalStorageService.cartDeletedEvent.next(true);
  }

  /**
   * return local storage.
   */
  public getLocalStorage(): Storage {
    return localStorage;
  }

}
