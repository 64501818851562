import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromActions from '../actions/order.action';
import { Order } from '../models/order.model';


export const featureKey = 'modesoOrderMicroservice';

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  selectId: selectOrderId,
  sortComparer: sortById
});

export function selectOrderId(a: Order): string {
  return a._id;
}

export function sortById(a: Order, b: Order): number {
  return a._id.localeCompare(b._id);
}

export interface FeatureState extends EntityState<Order> {
  order: Order;
  status: 'initial' | 'postedSuccessfully' | 'postedFaild';
  sendDigitalConfirmEmailsStatus: boolean;
}

export interface AppState {
  modesoOrderMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoOrderMicroservice;

export const selectFeatureOrder = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const sendDigitalConfirmEmailsStatusFeature = createSelector(
  selectFeature,
  (state: FeatureState) => state.sendDigitalConfirmEmailsStatus
);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();

export const selectOrderEntities = createSelector(
  selectFeatureOrder,
  selectEntities
);

export const selectOrderAllEntities = createSelector(
  selectFeatureOrder,
  selectAll
);

export const selectOrderByID = createSelector(
  selectOrderEntities,
  (entities, props) => {
    return entities[props.id];
  }
);

// Selectors Declarations - END

// Reducer Declarations - START
const initialEntityState = adapter.getInitialState();
export const initialState: FeatureState = {
  ...initialEntityState,
  order: new Order(),
  status: 'initial',
  sendDigitalConfirmEmailsStatus: false
};

const orderReducer = createReducer(
  initialState,
  on(fromActions.postOrder, (state) => ({ ...state })),
  on(fromActions.onOrderPostedSuccessfully, (state, action) => ({
    ...state, order: action.payload, status: 'postedSuccessfully',
    sendDigitalConfirmEmailsStatus: false
  })),
  on(fromActions.onOrderPostingFailed, (state) => ({ ...state, status: 'postedFaild' })),
  on(fromActions.getById, state => ({ ...state })),
  on(fromActions.onByIdLoadedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, { ...state });
  }
  ),
  on(fromActions.onByIdLoadingFailed, state => ({ ...state })),
  on(fromActions.sendDigitalConfirmEmails, state => ({ ...state, sendDigitalConfirmEmailsStatus: true })),
  on(fromActions.onSendDigitalConfirmEmailsSuccessful, state => ({ ...state, sendDigitalConfirmEmailsStatus: true }))
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return orderReducer(state, action);
}
// Reducer Declarations - END
