import { Component, Input } from '@angular/core';

@Component({
  selector: 'lidl-lib-order-fe-title',
  templateUrl: './order-title.component.html',
  styleUrls: ['./order-title.component.scss']
})
export class OrderTitleComponent {
  @Input() titleText;

  constructor() { }

}
