import { createAction, props, Action } from '@ngrx/store';
import { PaymentOpionModel } from '../models/payment-option.model';
import { InitializePaymentModel } from '../models/initialize-payment-model';
import { InitializePaymentResponseModel } from '../models/payment-initialization-response.model';
import { PaymentProcessStatusResponse } from '../models/payment-process-status-response';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentStatusModel } from '../models/paymentStatus.model';

export const getAllPaymentOptions = createAction('[Payment options List] getAllPaymentOptions');

export const getPaymentStatus = createAction(
  '[Payment status] getPaymentStatus',
  props<IActionWithOrderId>()
);

export interface IActionWithOrderId {
  orderId: string;
}

export const intializePayment = createAction(
  '[Payment options List] intializePayment',
  props<{ payload: InitializePaymentModel }>()
);
/**
 * create an Action of get all payment options
 * check https://ngrx.io/guide/store/actions
 */
export const onAllPaymentOptionsLoadedSuccessfully = createAction(
  '[Payment options List] all payment options loaded successfully',
  props<{ payload: Array<PaymentOpionModel> }>()
);


export const onAllPaymentOptionsLoadingFailed = createAction(
  '[Payment options List] all Payment options loading failed',
  props<{ payload: any }>()
);

export const onPaymentOptionsValidSelected = createAction(
  '[Payment options List] payment options selected successfully',
  props<{ payload: PaymentOpionModel }>()
);


export const onTermsAndConditionsAccepted = createAction(
  '[Payment options List] all terms and conditions are accepted'
);

export const onTermsAndConditionsRejected = createAction(
  '[Payment options List] terms and conditions are accepted'
);

export const onGetPaymentStatusSuccess = createAction(
  '[Payment status] payment status request created successfully',
  props<{ payload: PaymentStatusModel }>()
);
export const onGetPaymentStatusReject = createAction(
  '[Payment status] payment status request could not be  created successfully'
  , props<{ payload: any }>()
);

export const onIntializePaymentSuccessfully = createAction(
  '[Payment options List] intializePayment succefully',
  props<{ payload: InitializePaymentResponseModel }>()
);

export const onIntializePaymentFailed = createAction(
  '[Payment options List] intializePayment failed',
  props<{ payload: any }>()
);

export const getPaymentProcessStatus = createAction(
  '[Payment] getPaymentProcessStatus',
  props<{ orderId: string }>()
);

export const onGetPaymentProcessStatusSuccessful = createAction(
  '[Payment] getPaymentProcessStatus successful',
  props<{ payload: PaymentProcessStatusResponse }>()
);

export const onGetPaymentProcessStatusFailed = createAction(
  '[Payment] getPaymentProcessStatus failed',
  props<{ payload: HttpErrorResponse }>()
);
export interface ActionWithPayload<T> extends Action {
  payload: T;
}
