import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContentResponse, ContentService } from '../services/content.service';
import * as fromActions from './../actions/content.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContentStoreProvider } from '../providers/contentStore.provider';

@Injectable()
export class ContentEffects {

  constructor(private actions$: Actions, private service: ContentService, private contentStoreProvider: ContentStoreProvider) { }

  loadAllContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getAllContent.type),
      mergeMap(
        () => {
          return this.service.getAllContent()
            .pipe(
              retry(1),
              tap(
                (response: ContentResponse) => {
                  // send to dependecies
                  this.contentStoreProvider.notifyObservers(response.content);
                }
              ),
              map(
                response => (fromActions.onAllContentLoadedSuccessfully({ payload: response.content }))
              ),
              catchError((error) => of(fromActions.onAllContentLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnLoadAllContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onAllContentLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllContentErrors(action.payload)
      )
    )
    , { dispatch: false });

  loadContentById$ = createEffect(
    () => this.actions$.pipe(
      ofType<fromActions.ActionWithContentId>(fromActions.getContentById.type),
      mergeMap(
        (action) => {
          console.log(`content should be loaded for ${action.contentId}`);
          return this.service.getContentById(action.contentId)
            .pipe(
              retry(1),
              map(
                response => (fromActions.onContentByIdLoadedSuccessfully({ payload: response.content }))
              ),
              catchError((error) => of(fromActions.onContentByIdLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnLoadContentById$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onContentByIdLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllContentErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllContentErrors(error) {
    console.log(error);
    return error;
  }

  log(data) {
    console.log(data);
  }


}
