import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, take } from 'rxjs/operators';
import { AuthProvider } from '../../provider/auth.provider';
import { RedirectionHandler } from '../services/redirection.service';
@Injectable()
export class AuthHandlerInterceptor implements HttpInterceptor {
  constructor(
    private redirectionHandler: RedirectionHandler,
    private authProvider: AuthProvider,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = req.clone({
      withCredentials: true,
    });
    return next.handle(modifiedRequest).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 440) { // Session Invalid
            this.authProvider.getNewSession();
            this.redirectionHandler.redirectUser();
          }
        }
        return throwError(err);
      }),
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error) => {
            if (error.status === 401) {
              this.authProvider.getNewAccsessToken();
              return of(error.status);
            }
            return throwError(error);
          }),
          take(2),
          delay(1000),
        );
      }),
    );
  }
}
