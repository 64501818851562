import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalizationService, LocalizationResponse } from '../services/localization.service';
import * as fromActions from './../actions/localization.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalizationStoreProvider } from '../providers/localizationStore.provider';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizationEffects');

@Injectable()
export class LocalizationEffects {

  constructor(
    private actions$: Actions,
    private service: LocalizationService,
    private localizationStoreProvider: LocalizationStoreProvider) { }

  loadLocalizations$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getLocalizations.type),
      mergeMap(
        () => {
          return this.service.getLocalizations()
            .pipe(
              retry(1),
              tap(
                (response: LocalizationResponse) => {
                  // send to dependecies
                  this.localizationStoreProvider.notifyObservers(response.localizations);
                }
              ),
              map(
                response => (fromActions.onLocalizationsLoadedSuccessfully({ payload: response.localizations }))
              ),
              catchError((error) => of(fromActions.onLocalizationsLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnLoadLocalizations$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLocalizationsLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadLocalizationsErrors(action.payload)
      )
    )
    , { dispatch: false });

  loadLocalizationByKey$ = createEffect(
    () => this.actions$.pipe(
      ofType<fromActions.ActionWithContentId>(fromActions.getLocalizationByKey.type),
      mergeMap(
        (action) => {
          debug(`content should be loaded for ${action.key}`);
          return this.service.getLocalizationByKey(action.key)
            .pipe(
              retry(1),
              tap(
                (response) => this.log(response)
              ),
              map(
                response => (fromActions.onLocalizationByKeyLoadedSuccessfully({ payload: response.localization }))
              ),
              catchError((error) => of(fromActions.onLocalizationByKeyLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnLoadLocalizationByKey$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLocalizationByKeyLoadingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadLocalizationsErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadLocalizationsErrors(error) {
    debug(error);
    return error;
  }

  log(data) {
    debug(data);
  }


}
