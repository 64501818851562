import { Injector } from '@angular/core';
import { PaymentStoreProvider } from '@modeso/giftcardshop-lib-payment-fe';
import { AppStateDispatcher } from './../state/app.state.dispatcher';
import { Order } from '@modeso/giftcardshop-lib-order-fe';
import { CartStoreProvider } from '@modeso/giftcardshop-lib-cart-fe';
import { LocalStorageService } from '../shared/services/localstorage.service';
import Debug from 'debug';
import * as i0 from "@angular/core";
const debug = Debug('lidl:shop:OrderOrchestrator');
export class OrderOrchestrator {
    constructor(injector) {
        this.appStateDispatcher = injector.get(AppStateDispatcher);
        this.paymentStoreProvider = injector.get(PaymentStoreProvider);
        this.cartStoreProvider = injector.get(CartStoreProvider);
        this.localStorageService = injector.get(LocalStorageService);
        this.selectedDeliveryOption = JSON.parse(this.localStorageService.getSelectedDeliveryOption());
        this.selectedPaymentOption$ = this.paymentStoreProvider.getSelectedPaymentOption$();
        this.deliveryAddress$ = this.appStateDispatcher.getDeliveryAddress$();
        this.billingAddress$ = this.appStateDispatcher.getBillingAddress$();
        this.cart$ = this.cartStoreProvider.getActiveCart$();
        this.cartTotal$ = this.cartStoreProvider.getActiveCartTotal$();
        debug('Set Orchestrator listeners');
        const that = this;
        this.selectedPaymentOption$.subscribe({
            next(paymentOption) {
                debug("payment changed");
                that.selectedPaymentOption = paymentOption;
            }
        });
        this.deliveryAddress$.subscribe({
            next(address) {
                debug("delivery address changed");
                that.deliveryAddress = address;
            }
        });
        this.billingAddress$.subscribe({
            next(address) {
                debug("billing address changed");
                that.billingAddress = address;
            }
        });
        this.cart$.subscribe({
            next(cart) {
                debug("cart changed");
                that.cart = cart;
            }
        });
        this.cartTotal$.subscribe({
            next(total) {
                debug("cart total changed");
                that.cartTotal = total;
            }
        });
    }
    constuctOrderObject() {
        this.userId = localStorage.getItem('UID');
        debug("OCHRESTRATOR: consturct order object");
        debug(this.userId);
        debug(this.selectedDeliveryOption);
        debug(this.selectedPaymentOption);
        debug(this.deliveryAddress);
        if (!this.billingAddress) {
            this.billingAddress = this.deliveryAddress;
        }
        debug(this.billingAddress);
        debug(this.cart);
        return this.constructOrderModel();
    }
    /**
     * construct order model as {
     *  userId: string;
     *  totalPrice: number;
     *  paymentMethod: PaymentModel;
     *  address: Address[];
     *  cart: Cart;
     * }
     */
    constructOrderModel() {
        const order = new Order();
        if (this.validateOrderInputs()) {
            if (this.billingAddress.email === undefined || this.billingAddress.email === null) {
                this.billingAddress.email = this.deliveryAddress.email;
            }
            const addresses = [this.deliveryAddress, this.billingAddress];
            order.setOrderModelNew(this.userId, this.selectedPaymentOption, this.cart, this.cartTotal, addresses, this.selectedDeliveryOption);
            return order;
        }
        else {
            debug("couldn't validate order inputs");
            return order;
        }
    }
    /**
     * check all compomnent inputs if defined or not
     */
    validateOrderInputs() {
        if (this.selectedPaymentOption && this.cart && this.billingAddress && this.deliveryAddress && this.selectedDeliveryOption) {
            return true;
        }
        else {
            return false;
        }
    }
}
OrderOrchestrator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderOrchestrator_Factory() { return new OrderOrchestrator(i0.ɵɵinject(i0.INJECTOR)); }, token: OrderOrchestrator, providedIn: "root" });
