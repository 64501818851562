import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modeso-checkout-steps',
  templateUrl: './checkout-steps.component.html',
  styleUrls: ['./checkout-steps.component.scss']
})
export class CheckoutStepsComponent {
  @Input() activeStep;

  constructor(private router: Router) { }

  goToAddress() {
    this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/address`);
  }
}
