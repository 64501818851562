import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateDispatcher } from './../../state/app.state.dispatcher';
import { OrderStoreProvider, Order } from '@modeso/giftcardshop-lib-order-fe';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { ActivatedRoute, Router } from '@angular/router';
declare global { interface Window { dataLayer:any; } }


@Component({
  selector: 'app-page-order',
  templateUrl: './orderConfirmation.page.html',
  styleUrls: ['./orderConfirmation.page.scss']
})
export class OrderConfirmationPage extends BasePageComponent implements OnInit {

  public appStateDispatcher: AppStateDispatcher;
  public orderStore: OrderStoreProvider;
  public orderId$: Observable<string>;
  public order$: Observable<Order> = undefined;
  public refreshCart = false;
  public currentOrderId: string;
  public isOrderPostedSuccessfully = false;
  /**
   *
   * @param injector
   */
  constructor(injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(injector);
    this.orderId$ = this.route.params.pipe(map(params => params.id));
    this.orderId$.subscribe((id: string) => this.currentOrderId = id);
    this.appStateDispatcher = injector.get(AppStateDispatcher);
    this.orderStore = injector.get(OrderStoreProvider);
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(this.orderId$.subscribe((orderId: string) => {
      if (orderId !== undefined) {
        this.order$ = this.orderStore.getOrderById$(orderId);
        this.checkForOrderStatus();
      }
    }));
  }

  getLanguage() {
    return this.getCurrentLanguage();
  }
  checkForOrderStatus() {
    this.subscriptions.push(
      this.order$.subscribe(order => {
        if (order) {
          if (!(order.status && order.status.toLowerCase() === 'confirmed')) {
            this.refreshCart = false;
            this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${order._id}/payment-failure`);
            this.isOrderPostedSuccessfully = false;
          } else {
            this.isOrderPostedSuccessfully = true;
            this.refreshCart = true;
            if (!order.isMarkedForDigitalConfirmationMailSent) {
              this.orderStore.sendDigitalConfirmEmails(this.currentOrderId);
            }
            setTimeout(() => {
              this.gtagConfirmPayment(order);
            }, 1000);
          }
        }
      })
    );
  }

  public gtagConfirmPayment(order: Order) {
    /*
    gtag('event', 'conversion', {
      'send_to': 'AW-10789076290/FX39CLOtqoADEML60Jgo',
      'value': order.totalPrice,
      'currency': 'CHF',
      'transaction_id': order._id
    });
    */
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': order._id,
            'affiliation': 'lidl-giftcards.ch',
            'revenue': order.totalPrice + order.deliveryOption.price,
            'tax': '0.00',
            'shipping': order.deliveryOption.price,
          },
          'products': order.cart.products.map(p => {
            return {
                'name': p.title,
                'id': p.productId,
                'price': p.price,
                'category': p.category,
                'quantity': p.amount,
              }
            })
        }
      }
    });
  }
}
