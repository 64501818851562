import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as productDetailsActions from './../../actions/product-details.actions';
import * as fromProductDetails from './../../reducers/product-details.reducer';
import { Observable } from 'rxjs';
import { ProductDetailsModel } from './../../models/product-details.model';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-products-fe:ProductDetailComponent');
declare global { interface Window { dataLayer:any; } }

@Component({
  selector: 'modeso-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {


  @Input() productId;

  product$: Observable<ProductDetailsModel>;

  /**
   *
   * @param store
   */
  constructor(private store: Store<fromProductDetails.AppState>) { }

  ngOnInit() {
    if (this.productId === undefined) {
      debug('productId is not defined');
      return;
    } else {
      this.product$ = this.store.pipe(select(fromProductDetails.selectEntitiesByID, { id: this.productId }));
      this.store.dispatch(productDetailsActions.getProductById({ productId: this.productId }));
      this.gtagDetailpage();
    }
  }

  public gtagDetailpage() {
    this.product$.subscribe((product: ProductDetailsModel) => {
      if (!product) return;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'EECproductDetailView',
        'ecommerce': {
          'detail': {
            'actionField': {},
            'products': [{
              'name': product.name,
              'id': product.productId,
              'category': product.category,
            }]
          }
        }
      });
    })
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('isProductAddedToCart');
  }
}
