import { Component, Input } from '@angular/core';

@Component({
  selector: 'modeso-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent {
  @Input() price;

  constructor() { }

}
