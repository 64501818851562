import { createAction, props, Action } from '@ngrx/store';
import { Order } from '../models/order.model';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */

/**
 * We can change the orders state using the actions.
 * This is action of post orders.
 */
export const postOrder = createAction('[Order] post Order', props<Order>());

/**
 * This is action of post order when it return successfully.
 */
export const onOrderPostedSuccessfully = createAction(
  '[Order] Order posted successfully',
  props<{ payload: any }>()
);

/**
 * This is action of post order when it failed.
 */
export const onOrderPostingFailed = createAction(
  '[Order]  Order posted failed'
);

export const sendDigitalConfirmEmails = createAction(
  '[Order]  sendDigitalConfirmEmails',
  props<{ orderId: string }>()
);

export const onSendDigitalConfirmEmailsSuccessful = createAction(
  '[Order]  sendDigitalConfirmEmails Successful',
);

/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export const getById = createAction(
  '[Order] getById',
  props<{ orderId: string }>()
);
export const onByIdLoadedSuccessfully = createAction(
  '[Order] ById loaded successfully',
  props<{ payload: Order }>()
);
export const onByIdLoadingFailed = createAction(
  '[Order] ById loading failed',
  props<{ payload: any }>()
);

export interface ActionWithOrderId extends Action {
  orderId: string;
}
