import { Component, Input } from '@angular/core';
import { Cart } from '../../models/cart.model';

@Component({
  selector: 'modeso-compact-cart-view',
  templateUrl: './compact-cart-view.component.html',
  styleUrls: ['./compact-cart-view.component.scss']
})
export class CompactCartViewComponent {

  @Input() additionalCharges: number;
  @Input() cart: Cart;
  @Input() totalPrice: number;

  constructor() { }

}
