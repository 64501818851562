import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AppStateDispatcher } from './../../state/app.state.dispatcher';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { DeliveryOptionComponent } from '@modeso/giftcardshop-lib-delivery-fe/lib/components/delivery-option/delivery-option.component';
import { AddDeliveryToCartRequest } from '@modeso/giftcardshop-lib-cart-fe';

@Component({
  selector: 'app-page-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss']
})
export class CartPage extends BasePageComponent implements OnInit, OnDestroy {
  public cartId: Observable<string>;
  public appStateDispatcher: AppStateDispatcher;
  // public selectedDeliveryOption$: Observable<DeliveryOptionModel>;
  currentCartId: string;
  public selectedDeliveryOption: DeliveryOptionModel;
  public deliveryOptions: DeliveryOptionModel[] = [];
  public priceOfSelectedDeliveryOption: number;
  isCartEmpty = false;

  public selectedDeliveryOptionIndex: number;
  constructor(private route: ActivatedRoute, injector: Injector, private router: Router) {
    super(injector);

    this.cartId = this.route.params.pipe(map(params => params.id));
    this.appStateDispatcher = injector.get(AppStateDispatcher);

    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
    this.selectedDeliveryOption = {} as DeliveryOptionModel;

    setTimeout(() => {
      this.emitCurrentDeliveryOption('');
      this.updateSelectedDeliveryOption(+this.getSelectedDeliveryOptionIndex());
    }, 500);

  }

  ngOnInit() {
    super.ngOnInit();
    // TODO if deliveryOptions are loaded, set the first one to the selected if no selected is set.
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateToCheckout() {
    // save selected deliveryOption to local storage
    const DeliveryOptionToBeSelected: AddDeliveryToCartRequest = {
      key: this.selectedDeliveryOption.key
    };

    this.appStateDispatcher.addSelectedDeliveryOption$(DeliveryOptionToBeSelected);
    this.saveSelectedDeliveryOption(JSON.stringify(this.selectedDeliveryOption));
    this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}/address`);
  }

  navigateToProducts() {
    this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}`);
  }

  emitCurrentDeliveryOption(cartId: string) {

    this.subscriptions.push(
      this.appStateDispatcher.getDeliveryOptions$()
        .subscribe((deliveryOptions: DeliveryOptionModel[]) => {

          if (deliveryOptions.length === 0) {
            this.priceOfSelectedDeliveryOption = 0;
            this.deliveryOptions = [];

          } else {
            this.selectedDeliveryOptionIndex = +this.getSelectedDeliveryOptionIndex();
            this.deliveryOptions = deliveryOptions;
            this.selectedDeliveryOption = deliveryOptions[this.selectedDeliveryOptionIndex];
          }

        })
    );

  }

  updateSelectedDeliveryOption(selectedDeliveryOptionIndex: number) {

    if (selectedDeliveryOptionIndex || selectedDeliveryOptionIndex === 0) {
      this.selectedDeliveryOptionIndex = selectedDeliveryOptionIndex;

    } else {
      this.selectedDeliveryOptionIndex = +this.getSelectedDeliveryOptionIndex();

    }


    this.priceOfSelectedDeliveryOption = (this.deliveryOptions[this.selectedDeliveryOptionIndex]) ?
      this.deliveryOptions[this.selectedDeliveryOptionIndex].price : 0;

    this.setSelectedDeliveryOptionIndex(this.selectedDeliveryOptionIndex.toString());
    this.selectedDeliveryOption = this.deliveryOptions[this.selectedDeliveryOptionIndex];
  }

  checkIsCartEmpty(products) {
    if (products.length === 0) {
      this.isCartEmpty = true;
    }
  }
}
