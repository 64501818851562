/**
 * A class which represent a named string.
 * Can be used for a localized string for instance
 * 
 */
export class NamedString {
    constructor(
      public key: string,
      public text: string
    ) { }
}
