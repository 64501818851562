/**
 * Address Model.
 */
export class AddressModel {
  public zipCode: string;
  public city: string;
  public country: string;
  public countryName?: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public street: string;
  public streetNumber: string;
  public company?: string;
  public addressSupplement?: string;
  public userId?: string;
  public _id?: string;


  constructor() { }
}
