import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressLocalStorageService {
  private static billingAddressKey = 'BillingAddressId';
  private static deliveryAddressKey = 'DeliveryAddressId';
  private static billingSameAsDelivery = 'BillingSameAsDelivery';

  constructor() { }

  /**
   * return local storage.
   */
  public getLocalStorage(): Storage {
    return localStorage;
  }

  /**
   * return session storage.
   */
  public getSessionStorage(): Storage {
    return sessionStorage;
  }

  /**
   * set when Billing Address is the same as the Delivery Address for an order.
   */
   public setBillingAddressSameAsDeliveryAddress(equal: boolean) {
    this.getSessionStorage().setItem(
      AddressLocalStorageService.billingSameAsDelivery, JSON.stringify(equal)
    );
  }

  /**
   * check if Billing Address is the same as the Delivery Address for an order.
   */
  public isBillingAddressSameAsDeliveryAddress(): boolean {
    return JSON.parse(this.getSessionStorage().getItem(
      AddressLocalStorageService.billingSameAsDelivery
    ));
  }

  /**
   * remove the check if Billing Address is the same as the Delivery Address for an order.
   */
   public removeBillingAddressSameAsDeliveryAddressFlag() {
    this.getSessionStorage().removeItem(AddressLocalStorageService.billingSameAsDelivery);
  }

}
