import { ProductDetailsModel } from '../models/product-details.model';
import { createAction, props, Action } from '@ngrx/store';

export const getAllProducts = createAction('[Product List] getAllProducts');



export const onAllProductsLoadedSuccessfully = createAction(
    '[Product List] all products loaded successfully',
    props<{ payload: Array<ProductDetailsModel> }>()
);

/**
 * create an Action of get product by id
 * check https://ngrx.io/guide/store/actions
 * @param1 handle of the action with ngrx convention
 * @param2 props method is used to define any additional metadata 
 *         needed for the handling of the action
 */
export const getProductById = createAction(
    '[Product List] getProductById',
    props<{ productId: string }>()
)

export const onAllProductsLoadingFailed = createAction(
    '[Product List] all products loading failed',
    props<{ payload: any }>()
);

export const getProductsByCategory = createAction(
    '[Product List] getProductsByCategory',
    props<{ productCategory: string }>()
);
export const onGetProductByCategorySuccessfully = createAction(
    '[Product List] get product by category succefully',
    props<{ payload: Array<ProductDetailsModel> }>()
);

export const onGetProductByCategoryFailed = createAction(
    '[Product List] get product by category failed',
    props<{ payload: any }>()
);


export interface ActionWithPayload<T> extends Action {
    payload: T;
}

export interface ActionWithProductCategory extends Action {
    productCategory: string;
}
