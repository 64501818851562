import { Component, OnInit, Input } from '@angular/core';
import { CartProductModel } from '../../models/cart-product.model';
import { Store, select } from '@ngrx/store';
import * as fromFeature from '../../reducers/cart.reducer';
import { CartResponseModel } from '../../models/response/cart-response.model';
import { Observable } from 'rxjs';
import * as cartActions from './../../actions/cart.actions';
import { CartLocalStorageService } from '../../services/cartLocalStorageService.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'modeso-compact-cart',
  templateUrl: './compact-cart.component.html',
  styleUrls: ['./compact-cart.component.scss']
})
export class CompactCartComponent implements OnInit {

  @Input() additionalCharges: number;

  cartVisible = false;
  carts$: Observable<CartResponseModel>;

  constructor(private store: Store<fromFeature.AppState>, private cartLocalStorageService: CartLocalStorageService) { }

  ngOnInit() {
    if (this.cartLocalStorageService.isSetCartId()) {
      this.store.dispatch(cartActions.onLoadCart());
    }
    this.carts$ = this.store.pipe(select(fromFeature.featureCart));
  }

  toggleResponsiveCartView(event) {
    this.cartVisible = !this.cartVisible;
    event.stopPropagation();
  }

}
