/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@modeso/giftcardshop-lib-cart-fe/modeso-giftcardshop-lib-cart-fe.ngfactory";
import * as i3 from "@modeso/giftcardshop-lib-cart-fe";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "@modeso/modeso-lib-localization-fe";
import * as i7 from "../../microservices/lidl-lib-content-fe/components/content/content.component.ngfactory";
import * as i8 from "../../microservices/lidl-lib-content-fe/components/content/content.component";
import * as i9 from "../../../../node_modules/@modeso/giftcardshop-lib-products-fe/modeso-giftcardshop-lib-products-fe.ngfactory";
import * as i10 from "@modeso/giftcardshop-lib-products-fe";
import * as i11 from "@angular/router";
import * as i12 from "./home.page";
import * as i13 from "../../orchestrator/cart.orchestrator";
import * as i14 from "@modeso/modeso-lib-auth-fe";
var styles_HomePage = [i0.styles];
var RenderType_HomePage = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePage, data: {} });
export { RenderType_HomePage as RenderType_HomePage };
function View_HomePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "lidl-lib-cart-fe-add-to-cart", [], null, [[null, "productNotificationEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("productNotificationEvent" === en)) {
        var pd_0 = (_co.handleProductNotificationEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵdid(1, 49152, null, 0, i3.ɵa, [i4.Store, i3.ɵf], { caption: [0, "caption"], product: [1, "product"] }, { productNotificationEvent: "productNotificationEvent" }), i1.ɵppd(2, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "modeso_cart_add-to-cart-button_caption")))); var currVal_1 = _co.cartOrchestrator.constructCartProductFromProduct(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomePage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.ɵa, [i4.Store]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-shop-content-block", [], null, null, null, i7.View_ShopContentComponent_0, i7.RenderType_ShopContentComponent)), i1.ɵdid(2, 573440, null, 0, i8.ShopContentComponent, [i4.Store], { contentId: [0, "contentId"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["addToCartButton", 2]], null, 0, null, View_HomePage_1)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "modeso-product-list", [], null, [[null, "redirectToCartEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("redirectToCartEvent" === en)) {
        var pd_0 = (_co.handleRedirectToCartEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ProductListComponent_0, i9.RenderType_ProductListComponent)), i1.ɵdid(6, 114688, null, 0, i10.ProductListComponent, [i11.Router, i11.ActivatedRoute, i10.ProductStoreProvider], { templateRef: [0, "templateRef"], showDetailsButtonCaption: [1, "showDetailsButtonCaption"], productNotificationSetter: [2, "productNotificationSetter"] }, { redirectToCartEvent: "redirectToCartEvent" }), i1.ɵppd(7, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categoryContentRef; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "modeso_products_product-list_show-details-button-caption")))); var currVal_3 = _co.productNotificationModel; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_HomePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-home", [], null, null, null, View_HomePage_0, RenderType_HomePage)), i1.ɵdid(1, 245760, null, 0, i12.HomePage, [i1.Injector, i11.ActivatedRoute, i11.Router, i13.CartOrchestrator, i14.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomePageNgFactory = i1.ɵccf("app-page-home", i12.HomePage, View_HomePage_Host_0, {}, {}, []);
export { HomePageNgFactory as HomePageNgFactory };
