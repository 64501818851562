import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../shared/services/localstorage.service';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent implements OnInit {
  public currentLanguage: string;
  constructor(
    private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.currentLanguage = this.localStorageService.getCurrentLanguage();
  }
}
