import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'modeso-counter',
  templateUrl: './modeso-counter.component.html',
  styleUrls: ['./modeso-counter.component.scss']
})
export class ModesoCounterComponent implements OnInit, OnChanges {
  @Input() counterError: boolean;
  @Input() amount: number;
  @Output() changeAmountEvent: EventEmitter<number>;
  currentAmount = 1;
  maxAllowedAmount;

  constructor() {
    this.changeAmountEvent = new EventEmitter<number>();
  }

  ngOnInit() {
    this.currentAmount = this.amount;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (this.counterError) {
          case true: {
            this.currentAmount = this.maxAllowedAmount = this.amount;
          }
        }
      }
    }
  }

  increment() {
    this.currentAmount++;
    this.emitAmount(this.currentAmount);

    // this line fixes the amount when the error shows as it keeps it at the previous number before error
    this.currentAmount--;
  }

  decrement() {
    if (this.currentAmount > 1) {
      this.currentAmount--;
      this.emitAmount(this.currentAmount);
    }
  }

  checkValue() {
    if (this.counterError) {
      this.currentAmount = this.maxAllowedAmount;
    } else {
      this.currentAmount = +this.currentAmount < 1 ? 1 : +this.currentAmount;
      this.emitAmount(this.currentAmount);
    }
  }

  private emitAmount(amount: number) {
    this.changeAmountEvent.emit(amount);
  }
}
