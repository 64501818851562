/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading-spinner.component";
var styles_LoadingSpinnerComponent = [i0.styles];
var RenderType_LoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
export function View_LoadingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "loading-spinner__spinner d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-border text-primary"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modeso-loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoadingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingSpinnerComponentNgFactory = i1.ɵccf("modeso-loading-spinner", i2.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, {}, {}, []);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };
