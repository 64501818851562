import { CartProductModel } from '@modeso/giftcardshop-lib-cart-fe';
import * as i0 from "@angular/core";
export class CartOrchestrator {
    constructor() {
    }
    constructCartProductFromProduct(product) {
        if (product === undefined) {
            return undefined;
        }
        const cartProduct = new CartProductModel();
        cartProduct.productId = product.productId;
        cartProduct.title = product.name;
        cartProduct.image = product.image;
        cartProduct.description = product.shortDescription;
        cartProduct.price = product.price;
        cartProduct.amount = 1;
        cartProduct.packagePrice = product.packagePrice;
        cartProduct.isPackagingSelected = false;
        cartProduct.variablePrice = product.variablePrice;
        cartProduct.category = product.category;
        cartProduct.ean = product.ean;
        cartProduct.pdfTemplatePath = product.pdfTemplatePath;
        return cartProduct;
    }
}
CartOrchestrator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartOrchestrator_Factory() { return new CartOrchestrator(); }, token: CartOrchestrator, providedIn: "root" });
