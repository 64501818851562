<div class="intro-content">
  <p>{{'lidl-intro-home-text' | content:'text' | async }}</p>
  <h1>{{'lidl-intro-home-title' | content:'text' | async }}</h1>
  <!-- <app-home-intro-content-list [filter]="'home-intro'"></app-home-intro-content-list> -->
  <div class="row">
    <div class="intro-products-categories col-lg">
      <div class="intro-products-categories__block">
        <h2 [innerHTML]="'lidl-intro-home-category-physical-title' | content:'text' | async "></h2>
        <svg class="intro-products-categories__icon">
          <use xlink:href="../../../assets/images/sprite_icons.svg#Lidl_giftcard_Icons-svg2"></use>
        </svg>
        <ul>
          <li>{{'lidl-intro-home-category-physical-list-1' | content:'text' | async }}</li>
          <li>{{'lidl-intro-home-category-physical-list-2' | content:'text' | async }}</li>
          <li>{{'lidl-intro-home-category-physical-list-3' | content:'text' | async }}</li>
        </ul>
      </div>
      <div class="intro-products-categories__btn">
        <a class="btn-cta"
          [routerLink]="['/' + currentLang +'/products/physical']">
          {{'lidl-intro-home-category-button' | content:'text' | async }}
        </a>
      </div>
    </div>
    <div class="intro-products-categories col-lg">
      <div class="intro-products-categories__block">
        <h2 [innerHTML]="'lidl-intro-home-category-digital-title' | content:'text' | async "></h2>
        <svg class="intro-products-categories__icon">
          <use xlink:href="../../../assets/images/sprite_icons.svg#Lidl_giftcard_Icons-svg1"></use>
        </svg>
        <ul>
          <li>{{'lidl-intro-home-category-digital-list-1' | content:'text' | async }}</li>
          <li>{{'lidl-intro-home-category-digital-list-2' | content:'text' | async }}</li>
          <li>{{'lidl-intro-home-category-digital-list-3' | content:'text' | async }}</li>
        </ul>
      </div>
      <div class="intro-products-categories__btn">
        <a class="btn-cta"
          [routerLink]="['/' + currentLang +'/products/digital']">
          {{'lidl-intro-home-category-button' | content:'text' | async }}
        </a>
      </div>
    </div>
  </div>
</div>