import { BreadcrumbModel } from '../models/breadcrumb.model';
import { createSelector, createReducer, on, Action } from '@ngrx/store';
import * as featureActions from './../actions/breadcrumb.actions';

export const breadcrumbFeatureKey = 'breadcrumbs';

// State Declarations - START

export interface FeatureState {
    activeBreadcrumb: BreadcrumbModel;
}

export interface AppState {
    breadcrumbs: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.breadcrumbs;
export const selectFeatureBreadcrumbs = createSelector(
    selectFeature,
    (state: FeatureState) => state.activeBreadcrumb
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    activeBreadcrumb: BreadcrumbModel.createDefaultInstance()
};

const breadcrumbReducer = createReducer(
    initialState,
    on(featureActions.updateBreadcrumb, (state, action) => ({...state, activeBreadcrumb: action.payload}))
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return breadcrumbReducer(state, action);
}
// Reducer Declarations - END
