<lidl-lib-cart-fe [additionalCharges]="priceOfSelectedDeliveryOption" [deliveryRef]="deliveryOptionsTemplate"
 (cartIdToCartPAge)="emitCurrentDeliveryOption($event)" (cartProductsAmount)="checkIsCartEmpty($event)">

    <ng-template  #deliveryOptionsTemplate [ngIf]="deliveryOptions.length !== 0">
      <lidl-lib-delivery-fe-select-option [deliveryOptions]="deliveryOptions" [currentSelectedRadioButton]="selectedDeliveryOptionIndex" (selectedDeliveryOption)="updateSelectedDeliveryOption($event)">
    </lidl-lib-delivery-fe-select-option>
    </ng-template>



  <div afterTotal
    class="action-buttons">
    <div class="proceed-button action-button">
      <button class="btn-cta" id="ProceedToCheckout" (click)="navigateToCheckout()"
      [disabled]="!selectedDeliveryOption || isCartEmpty">{{'modeso_cart_go-to-checkout' | localize | async}}</button>
    </div>
    <div class="continue-shopping-button action-button">
      <button class="btn-cta btn-cta-secondary" id="continueShopping" (click)="navigateToProducts()">{{'modeso_cart_continue-shopping' | localize | async}}</button>
    </div>
  </div>
</lidl-lib-cart-fe>
