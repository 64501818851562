import { Component, Injector, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { BasePageComponent } from '../base.page';

@Component({
  selector: 'app-page-balance',
  templateUrl: './balance.page.html',
  styleUrls: ['./balance.page.scss']
})
export class BalancePage extends BasePageComponent implements OnInit {

  static urls = {
    'de-ch': 'https://balancechecks.tx-gate.com/balancechecks/balance.php?cid=64',
    'fr-ch': 'https://balancechecks.tx-gate.com/balancechecks/balance.php?cid=65',
    'it-ch': 'https://balancechecks.tx-gate.com/balancechecks/balance.php?cid=66'
  };
  balanceCheckUrl: SafeResourceUrl;

  constructor(injector: Injector, private sanitizer: DomSanitizer) {
    super(injector);
    this.balanceCheckUrl = sanitizer.bypassSecurityTrustResourceUrl(BalancePage.urls[this.getCurrentLanguage()]);
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-overview')
    .subscribe((breadcrumbTitle) =>
      this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}`, breadcrumbTitle, false));
  }
}
