import { Injectable } from '@angular/core';
import * as fromReducers from '../reducers/content.reducer';
import * as fromActions from '../actions/content.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentModel } from './../models/content.model';
import Debug from 'debug';
const debug = Debug('modeso:modeso-content:ContentStoreProvider');

@Injectable({
  providedIn: 'root'
})
export class ContentStoreProvider {
  private observers: Set<any>;

  constructor(public store: Store<fromReducers.AppState>) {
    this.observers = new Set<any>();
  }

  public loadContents() {
    this.store.dispatch(fromActions.getAllContent());
  }

  // to be used if needed
  // public getContentForKey(key: string): Observable<ContentModel> {
  //   return this.store.pipe(
  //     select(fromReducers.selectEntitiesByID, { id: key }),
  //     map((content: ContentModel) => {
  //       debug('got ' + key);
  //       debug(content);
  //       if (content) {
  //         debug('return ' + content.title);
  //         return content;
  //       } else {
  //         return undefined;
  //       }
  //     }));
  // }

  public notifyObservers(contents: Array<ContentModel>) {
    debug('noify observers');
    debug(this.observers.size);
    this.observers.forEach(observer => {
      observer.setInjectedText(contents);
    });
  }
}
