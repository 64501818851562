import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from '../actions/address.action';
import { AddressModel } from '../models/address.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';


export const featureKey = 'modesoAddressMicroservice';

// State Declarations - START

export const adapter: EntityAdapter<AddressModel> = createEntityAdapter<AddressModel>({
  selectId: selectAddressId,
  sortComparer: sortById,
});

export function selectAddressId(a: AddressModel): string {
  return a._id;
}

export function sortById(a: AddressModel, b: AddressModel): number {
  return a._id.localeCompare(b._id);
}

export interface FeatureState extends EntityState<AddressModel> {
  isMarked: boolean;
}

export interface AppState {
  modesoAddressMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoAddressMicroservice;

export const selectFeatureAddress = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();

export const selectAddressEntities = createSelector(
  selectFeatureAddress,
  selectEntities
);

export const selectAllAddresses = createSelector(
  selectFeatureAddress,
  (entities) => entities
);

export const selectAddressAllEntities = createSelector(
  selectFeatureAddress,
  selectAll
);

export const selectEntitiesByID = createSelector(
  selectAddressEntities,
  (entities, props) => {
    return entities[props.id];
  }
);

export const selectEntitiesByFilter = createSelector(
  selectAddressAllEntities,
  (entities, props) => {
    return entities.filter(
      address => address.collection === props.filter
    );
  }
);

export const selectIsBillingAddress = createSelector(
  selectFeature,
  (state: FeatureState) => state.isMarked
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = adapter.getInitialState({
  isMarked: true,
});

const addressReducer = createReducer(
  initialState,
  on(fromActions.postDeliveryAddress, state => ({ ...state })),
  on(fromActions.onDeliveryAddressPostedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, state);
  }
  ),
  on(fromActions.onDeliveryAddressPostingFailed, (state) => ({ ...state })),
  on(fromActions.postBillingAddress, state => ({ ...state })),
  on(fromActions.onBillingAddressPostedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, state);
  }
  ),
  on(fromActions.onBillingAddressPostingFailed, (state) => ({ ...state })),
  on(fromActions.getDeliveryAddressById, state => ({ ...state })),
  on(fromActions.onAddressDeliveryByIdLoadedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, state);
  }
  ),
  on(fromActions.onAddressDeliveryByIdLoadingFailed, state => ({ ...state })),
  on(fromActions.getBillingAddressById, state => ({ ...state })),
  on(fromActions.onAddressBillingByIdLoadedSuccessfully, (state, action) => {
    return adapter.addOne(action.payload, state);
  }
  ),
  on(fromActions.onAddressBillingByIdLoadingFailed, state => ({ ...state })),
  on(fromActions.putDeliveryAddress, state => ({ ...state })),
  on(fromActions.onPutDeliveryAddressSuccessfully, (state, action) => {
    return adapter.updateOne({ id: action.payload._id, changes: action.payload }, state);
  }
  ),
  on(fromActions.onPutDeliveryAddressFailed, (state) => ({ ...state })),
  on(fromActions.putBillingAddress, state => ({ ...state })),
  on(fromActions.onPutBillingAddressSuccessfully, (state, action) => {
    return adapter.updateOne({ id: action.payload._id, changes: action.payload }, state);
  }
  ),
  on(fromActions.onPutBillingAddressFailed, (state) => ({ ...state })),
  on(fromActions.deleteBillingAddress, state => ({ ...state })),
  on(fromActions.onDeleteAddressBillingSuccessfully, (state, action) => {
    return adapter.removeOne(action.payload.id, state);
  }
  ),
  on(fromActions.onDeleteAddressBillingFailed, state => ({ ...state })),
  on(fromActions.onIsBillingAddressMarked, (state, action) => ({ ...state, isMarked: action.payload })),

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return addressReducer(state, action);
}
// Reducer Declarations - END
