import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationService } from '../services/authentication.service';
import * as fromActions from '../actions/auth.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { JWTService } from '../shared/services/jwt.service';
@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private service: AuthenticationService,
    private cookieService: CookieService,
    private jwtService: JWTService
  ) {}

  onGetNewAccessToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.onGetNewAccesToken.type),
      mergeMap(() => {
        return this.service.getNewAccessToken().pipe(
          map((reponse) => {
            return fromActions.onGetNewAccesTokenSuccess();
          }),
          catchError((err) => {
            return of(fromActions.onGetNewAccesTokenFailed());
          }),
        );
      }),
    );
  });

  onGetNewSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.onGetNewSession.type),
      mergeMap(() => {
        return this.service.getNewSessionToken().pipe(
          map((reponse) => {
            this.saveUserRole();
            return fromActions.onGetNewSessionSuccess();
          }),
          catchError((err) => {
            return of(fromActions.onGetNewSessionFailed());
          }),
        );
      }),
    );
  });

  onGetNewSessionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.onGetNewSessionSuccess.type),
        tap(() => {
          console.log('Session succeed to be retrieved');
        }),
      );
    },
    { dispatch: false },
  );

  onGetNewTokenSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.onGetNewAccesTokenSuccess.type),
        tap(() => {
          console.log('Token succeed to be retrieved');
        }),
      );
    },
    { dispatch: false },
  );
  onGetNewSessionFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.onGetNewSessionFailed.type),
        tap(() => {
          console.log('Session Failed to be retrieved');
        }),
      );
    },
    { dispatch: false },
  );
  onGetNewTokenFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.onGetNewAccesTokenFailed.type),
        tap(() => {
          console.log('Token Failed to be retrieved');
        }),
      );
    },
    { dispatch: false },
  );

  private saveUserRole(): void {
    const token = this.cookieService.get('token');
    const userRoles = this.jwtService.decodeJwt(token) as {roles: string[]};
    localStorage.setItem('roles', JSON.stringify(userRoles.roles));
  }
}

