import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NamedString } from './namedString.model';
import * as fromActions from './namedString.actions';
import * as fromReducers from './namedString.reducer';

@Injectable({
    providedIn: 'root'
})
export class InjectedTextProvider {

    constructor(private store: Store<fromReducers.AppState>) {
    }

    public setInjectedText(injectedText: Array<NamedString>) {
       this.store.dispatch(fromActions.onLocalizationsLoadedSuccessfully({
            payload: injectedText
        }));
    }

}
