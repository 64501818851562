import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LocalizationService } from './services/localization.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromLocalization from './reducers/localization.reducer';
import { LocalizationEffects } from './effects/localization.effects';
import { ModesoLocalizationConfig, ModesoLocalizationConfigService } from './localization.config';
import { LocalizePipe } from './pipes/localize.pipe';
import { LanguageSwitcherComponent } from './components/language-switcher/languageSwitcher.component';

@NgModule({
  declarations: [LocalizePipe, LanguageSwitcherComponent],
  providers: [LocalizationService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLocalization.featureKey, fromLocalization.reducer),
    EffectsModule.forFeature([LocalizationEffects])
  ],
  exports: [LocalizePipe, LanguageSwitcherComponent]
})
export class ModesoLocalizationModule {
  static forRoot(config: ModesoLocalizationConfig): ModuleWithProviders {
    return {
      ngModule: ModesoLocalizationModule,
      providers: [
        LocalizationService,
        {
          provide: ModesoLocalizationConfigService,
          useValue: config
        }
      ]
    };
  }
}
