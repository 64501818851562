import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from '../actions/auth.actions';
export const featureKey = 'modesoAuthMicroservice';
export interface FeatureState {
  chekInDate: Date;
}

export interface AppState {
  modesoAuthMicroservice: FeatureState;
}

export const selectFeature = (state: AppState) =>  state.modesoAuthMicroservice
export const selectFeatureSession = createSelector(
  selectFeature,
  (state: FeatureState) => state,
);
export const selectCheckInate = createSelector(
  selectFeature,
  (state: FeatureState) => state.chekInDate,
);
export const initialState: FeatureState = {
  chekInDate: null,
};

const authReducer = createReducer(
  initialState,
  on(fromActions.onGetNewAccesToken, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewSession, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewAccesTokenFailed, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewSessionFailed, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewSessionSuccess, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewAccesTokenSuccess, (state) => {
    return { ...state };
  }),
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return authReducer(state, action);
}
