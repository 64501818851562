import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './namedString.actions';
import { NamedString } from './namedString.model';

export const featureKey = 'namedStrings';

// State Declarations - START
export const adapter: EntityAdapter<NamedString> = createEntityAdapter<NamedString>({
    selectId: selectContentId,
    sortComparer: sortById
});

export function selectContentId(a: NamedString): string {
    return a.key;
}

export function sortById(a: NamedString, b: NamedString): number {
    return a.key.localeCompare(b.key);
}

export interface FeatureState extends EntityState<NamedString> {

}

export interface AppState {
    namedStrings: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.namedStrings;
export const selectFeatureContent = createSelector(
    selectFeature,
    (state: FeatureState) => state
);


export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

export const selectLocalizationEntities = createSelector(
    selectFeatureContent,
    selectEntities
);

export const selectContentAllEntities = createSelector(
    selectFeatureContent,
    selectAll
);

export const selectEntitiesByID = createSelector(
    selectLocalizationEntities,
    (entities, props) => {
        return entities[props.id];
    }
);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = adapter.getInitialState();

const contentReducer = createReducer(
    initialState,
    on(fromActions.getLocalizations, state => ({ ...state})),
    on(fromActions.onLocalizationsLoadedSuccessfully, (state, action) => {
        return adapter.upsertMany(action.payload, {...state});
    }
    ),
    on(fromActions.onLocalizationsLoadingFailed, state => ({ ...state})),
    on(fromActions.getLocalizationByKey, state => ({ ...state})),
    on(fromActions.onLocalizationByKeyLoadedSuccessfully, (state, action) => {
            return adapter.upsertOne(action.payload, {...state});
        }
    ),
    on(fromActions.onLocalizationByKeyLoadingFailed, state => ({ ...state})),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return contentReducer(state, action);
}
// Reducer Declarations - END


