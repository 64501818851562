
export class ProductUpdateRequestModel {
    public productId: string;
    public cartId: string;
    public amount: number;
    public isPackagingSelected: boolean;
    public _id: string;

    constructor(init?: Partial<ProductUpdateRequestModel>) { }
}
