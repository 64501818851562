import { Injector, Injectable } from '@angular/core';
import { PaymentStoreProvider } from '@modeso/giftcardshop-lib-payment-fe';
import { Observable } from 'rxjs';
import { AppStateDispatcher } from './../state/app.state.dispatcher';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { PaymentOpionModel } from '@modeso/giftcardshop-lib-payment-fe';
import { OrderStoreProvider, Order } from '@modeso/giftcardshop-lib-order-fe';
import { CartStoreProvider, Cart } from '@modeso/giftcardshop-lib-cart-fe';
import { AddressModel } from '@modeso/giftcardshop-lib-address-fe';
import { LocalStorageService } from '../shared/services/localstorage.service';
import Debug from 'debug';
const debug = Debug('lidl:shop:OrderOrchestrator');

@Injectable({
  providedIn: 'root'
})
export class OrderOrchestrator {
  private appStateDispatcher: AppStateDispatcher;
  private paymentStoreProvider: PaymentStoreProvider;
  private cartStoreProvider: CartStoreProvider;

  private selectedPaymentOption$: Observable<PaymentOpionModel>;
  private deliveryAddress$: Observable<AddressModel>;
  private billingAddress$: Observable<AddressModel>;
  private cart$: Observable<Cart>;
  private cartTotal$: Observable<number>;

  public selectedDeliveryOption: DeliveryOptionModel;
  public selectedPaymentOption: PaymentOpionModel;
  public deliveryAddress: AddressModel;
  public billingAddress: AddressModel;
  public cart: Cart;
  public cartTotal: number;
  public userId: string;
  private localStorageService: LocalStorageService;


  constructor(injector: Injector) {
    this.appStateDispatcher = injector.get(AppStateDispatcher);
    this.paymentStoreProvider = injector.get(PaymentStoreProvider);
    this.cartStoreProvider = injector.get(CartStoreProvider);
    this.localStorageService = injector.get(LocalStorageService);

    this.selectedDeliveryOption = JSON.parse(this.localStorageService.getSelectedDeliveryOption());
    this.selectedPaymentOption$ = this.paymentStoreProvider.getSelectedPaymentOption$();
    this.deliveryAddress$ = this.appStateDispatcher.getDeliveryAddress$();
    this.billingAddress$ = this.appStateDispatcher.getBillingAddress$();
    this.cart$ = this.cartStoreProvider.getActiveCart$();
    this.cartTotal$ = this.cartStoreProvider.getActiveCartTotal$();

    debug('Set Orchestrator listeners');
    const that = this;
    this.selectedPaymentOption$.subscribe({
      next(paymentOption: PaymentOpionModel) {
        debug("payment changed");
        that.selectedPaymentOption = paymentOption;
      }
    });

    this.deliveryAddress$.subscribe({
      next(address: AddressModel) {
        debug("delivery address changed");
        that.deliveryAddress = address;
      }
    });
    this.billingAddress$.subscribe({
      next(address: AddressModel) {
        debug("billing address changed");
        that.billingAddress = address;
      }
    });
    this.cart$.subscribe({
      next(cart: Cart) {
        debug("cart changed");
        that.cart = cart;
      }
    });
    this.cartTotal$.subscribe({
      next(total: number) {
        debug("cart total changed");
        that.cartTotal = total;
      }
    });
  }

  public constuctOrderObject(): Order {

    this.userId = localStorage.getItem('UID');
    debug("OCHRESTRATOR: consturct order object");
    debug(this.userId);
    debug(this.selectedDeliveryOption);
    debug(this.selectedPaymentOption);
    debug(this.deliveryAddress);
    if (!this.billingAddress) {
      this.billingAddress = this.deliveryAddress;
    }
    debug(this.billingAddress);
    debug(this.cart);

    return this.constructOrderModel();
  }

  /**
   * construct order model as {
   *  userId: string;
   *  totalPrice: number;
   *  paymentMethod: PaymentModel;
   *  address: Address[];
   *  cart: Cart;
   * }
   */
  constructOrderModel(): Order {
    const order: Order = new Order();

    if (this.validateOrderInputs()) {
      if (this.billingAddress.email === undefined || this.billingAddress.email === null) {
        this.billingAddress.email = this.deliveryAddress.email;
      }
      const addresses = [this.deliveryAddress, this.billingAddress];
      order.setOrderModelNew(this.userId, this.selectedPaymentOption, this.cart,
        this.cartTotal, addresses, this.selectedDeliveryOption);
      return order;
    } else {
      debug("couldn't validate order inputs");
      return order;
    }
  }

  /**
   * check all compomnent inputs if defined or not
   */
  validateOrderInputs() {
    if (this.selectedPaymentOption && this.cart && this.billingAddress && this.deliveryAddress && this.selectedDeliveryOption) {
      return true;
    } else {
      return false;
    }
  }

}

