import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAddress from './../../reducers/address.reducer';
import * as fromActions from './../../actions/address.action';
import { AddressModel } from './../../models/address.model';
import { AddressLocalStorageService } from '../../services/addressLocalStorageService.service';
import { AddressType } from '../../models/addressType.enum';
import { take } from 'rxjs/operators';
declare global { interface Window { dataLayer:any; } }


@Component({
  selector: 'lidl-lib-address-fe',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

  /**
   * src for x image for validation.
   */
  @Input() src: string;

  subscribers = [];

  /**
   * inputData: delivery data model.
   * inputBillingData:billing data model
   */
  address: AddressModel = new AddressModel();
  inputData: AddressModel = new AddressModel();
  inputBillingData: AddressModel = new AddressModel();

  /**
   * inputAccepted: boolean variable for delivery address validation.
   * inputAcceptedBilling: boolean variable for billing address validation.
   * marked:boolean variable for checkbox.
   * alertMsg:boolean variable for show alertMsg.
   */
  inputAccepted = false;
  inputAcceptedBilling = false;
  marked = true;
  alertMsg = false;

  deliveryAddress$: Observable<AddressModel>;
  billingAddress$: Observable<AddressModel>;

  /**
   *
   * @param store
   * @param service
   * @param addressLocalStorageService
   */
  constructor(
    private store: Store<fromAddress.AppState>,
    private addressLocalStorageService: AddressLocalStorageService) { }

  ngOnInit() {
    this.dispatchDeliveryAddress();
    if (!this.addressLocalStorageService.isBillingAddressSameAsDeliveryAddress()) {
      this.store.pipe(select(fromAddress.selectAddressAllEntities)).pipe(take(1)).subscribe( (data) => {
        if (data && data.length > 1) {
          this.dispatchBillingAddress();
        }
      });
    }
  }

  /**
   * dispatch delivery address.
   * get delivery address by id from store.
   */
  dispatchDeliveryAddress() {
      this.deliveryAddress$ = this.store.pipe(select(fromAddress.selectEntitiesByID,
        { id: AddressType.DELIVERYADDRESS }));
      this.store.dispatch(fromActions.getDeliveryAddressById({ _id: AddressType.DELIVERYADDRESS }));
  }

  /**
   * dispatch billing address.
   * get billing address by id from store.
   */
  dispatchBillingAddress() {
      this.marked = false;
      this.billingAddress$ = this.store.pipe(select(fromAddress.selectEntitiesByID,
        { id: AddressType.BILLINGADDRESS }));
      this.store.dispatch(fromActions.getBillingAddressById({ _id: AddressType.BILLINGADDRESS }));
  }

  /**
   * set delivery data.
   * @param inputData.
   */
  setDeliveryData(inputData: AddressModel) {
    this.inputData = inputData;
  }

  /**
   * set billing data.
   * @param inputBillingData.
   */
  setBillingData(inputBillingData: AddressModel) {
    this.inputBillingData = inputBillingData;
  }

  /**
   * set validation of delivery address.
   * @param inputAccepted.
   */
  setDeliveryValidation(inputAccepted: boolean) {
    this.inputAccepted = inputAccepted;
  }

  /**
   * set validation of billing address.
   * @param inputAcceptedBilling.
   */
  setBillingValidation(inputAcceptedBilling: boolean) {
    this.inputAcceptedBilling = inputAcceptedBilling;
  }

  /**
   * set there is input in address or not.
   * @param dirtyForm.
   */
  setAlertMsg(alertMsg: boolean) {
    this.alertMsg = alertMsg;

  }

  /**
   * check box status.
   * @param event.
   */
  showbillingAddress(event) {
    this.marked = event.target.checked;
    this.store.dispatch(fromActions.onIsBillingAddressMarked({ payload: this.marked }));
  }

  /**
   * if checkbox is marked , no billing address so call submitDeliveryAddress function .
   * if there is billing id so call deleteBillingAddressId function.
   * if checkbox in unmarked, call submitDeliveryAddress and this.submitBillingAddress functions.
   */
  submit() {
    if (this.marked === true) {
      this.submitDeliveryAddress();
    } else {
      this.submitDeliveryAddress();
      this.submitBillingAddress();
    }
    this.addressLocalStorageService.setBillingAddressSameAsDeliveryAddress(this.marked);
    setTimeout(() => {
      this.gtagEnterAddress();
    }, 100);
  }
  /**
   * submit delivery address function.
   * save delivery address in store.
   */
  submitDeliveryAddress() {
    this.inputData._id = AddressType.DELIVERYADDRESS;
    this.store.pipe(select(fromAddress.selectEntitiesByID,
      { id: AddressType.DELIVERYADDRESS })).pipe(take(1)).subscribe( (data) => {
        if (data) {
          this.store.dispatch(fromActions.onPutDeliveryAddressSuccessfully({ payload: this.inputData }))
        } else {
          this.store.dispatch(fromActions.onDeliveryAddressPostedSuccessfully({ payload: this.inputData }))
        }
      });
  }

  /**
   * submit billing address function.
   * save billing address in store.
   */
  submitBillingAddress() {
    this.inputBillingData._id = AddressType.BILLINGADDRESS;
    this.store.pipe(select(fromAddress.selectEntitiesByID,
      { id: AddressType.BILLINGADDRESS })).pipe(take(1)).subscribe( (data) => {
        if (data) {
          this.store.dispatch(fromActions.onPutBillingAddressSuccessfully({ payload: this.inputBillingData }))
        } else {
          this.store.dispatch(fromActions.onBillingAddressPostedSuccessfully({ payload: this.inputBillingData }))
        }
      });
  }

  /**
   * check condition of save button if it is disabled or not.
   */
  buttonStatus() {
    return !((this.marked && this.inputAccepted) || (!this.marked && this.inputAccepted && this.inputAcceptedBilling));
  }

  /**
   * alert msg appear when validation are not correct.
   */
  alertMsgStatus() {
    return this.alertMsg === false ? false : (!((this.marked && this.inputAccepted) ||
      (!this.marked && this.inputAccepted && this.inputAcceptedBilling)));
  }

  ngOnDestroy(): void {
    (this.subscribers || []).forEach(subscribtion => {
      subscribtion.unsubscribe();
    });
  }

  public gtagEnterAddress() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {'step': 1},
        }
      }
    });
  
  }
}
