export const CountriesJson = [
  { code: 'LI',
    name: {
      'de-ch':'Fürstentum Liechtenstein',
      'fr-ch':'Principauté du Liechtenstein',
      'it-ch':'Principato del Liechtenstein'
    }
  },
  { code: 'CH',
    name: {
    'de-ch':'Schweiz',
    'fr-ch':'Suisse',
    'it-ch':'Svizzera'
    },  
  }
];
