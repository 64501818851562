import * as tslib_1 from "tslib";
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AppStateDispatcher } from './../../state/app.state.dispatcher';
import { OrderOrchestrator } from '../../orchestrator/order.orchestrator';
import { OrderStoreProvider } from '@modeso/giftcardshop-lib-order-fe';
import { PaymentProcessStatus } from '@modeso/giftcardshop-lib-payment-fe';
import Debug from 'debug';
const debug = Debug('lidl:shop:PaymentPage');
export class PaymentPage extends BasePageComponent {
    /**
     *
     * @param route
     * @param injector
     */
    constructor(injector, router, paymentStoreProvider, addressProvider, route) {
        super(injector);
        this.router = router;
        this.paymentStoreProvider = paymentStoreProvider;
        this.addressProvider = addressProvider;
        this.route = route;
        this.hasError = false;
        this.buttonSpinner = false;
        this.disableSubmit = true;
        this.isPaymentValid = false;
        this.isLoading = false;
        this.isPaymentInitializedError = false;
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}/address`, 'Zurück zur Adressdaten', false);
        this.checkPaymentValidation();
        // Why destroy adress provider?
        this.addressProvider.destroy();
        this.appStateDispatcher = injector.get(AppStateDispatcher);
        this.orderStoreProvider = injector.get(OrderStoreProvider);
        this.orderOrchestrator = injector.get(OrderOrchestrator);
        this.selectedDeliveryOption = JSON.parse(this.getSelectedDeliveryOption());
        this.orderId$ = this.route.params.pipe(map(params => params.orderId));
        if (this.orderId$) {
            this.isPaymentDone = true;
        }
        this.orderId$.subscribe((id) => this.currentOrderId = id);
        this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-address')
            .subscribe((breadcrumbTitle) => this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}/address`, breadcrumbTitle, false));
    }
    ngOnInit() {
        super.ngOnInit();
        if (window.location.href.indexOf('payment-failure') > 0) {
            this.hasError = true;
            this.isPaymentValid = false;
            this.isLoading = false;
            this.isPaymentDone = false;
            this.disableSubmit = true;
        }
        if (window.location.href.indexOf('payment-success') > 0) {
            this.isLoading = true;
            this.checkPaymentProcessStatusFlow = setInterval(() => {
                this.checkPayemntProcessStatus();
            }, 20000);
        }
    }
    checkPayemntProcessStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.subscriptions.push(this.paymentStoreProvider.getPaymentProcessStatus$(this.currentOrderId).subscribe((status) => {
                // if status is started don't send another request
                if (status.paymentProcessStatus === PaymentProcessStatus.SUCCESSED) {
                    const validPayment = {
                        validPayment: true,
                        message: '',
                        paymentProcessStatus: PaymentProcessStatus.SUCCESSED,
                    };
                    this.handlePaymentStatus(validPayment);
                }
                // if status is failed display error
                if (status.paymentProcessStatus === PaymentProcessStatus.FAILED) {
                    const unvalidPayment = {
                        validPayment: false,
                        message: 'Failure in payment flow',
                        paymentProcessStatus: PaymentProcessStatus.FAILED,
                    };
                    this.handlePaymentStatus(unvalidPayment);
                }
            }));
        });
    }
    submit() {
        this.isLoading = true;
        this.paymentStoreProvider.isPaymentValid().pipe(take(1)).subscribe((isValid) => {
            if (isValid) {
                // Navigate to confirmation page
                this.orderStoreProvider.getNewOrder$().subscribe((order) => {
                    if (order && order._id) {
                        debug('Payment initialized...');
                        debug(order);
                        this.gtagSelectPayment();
                        const paymentInitailzeRequest = {
                            orderReference: order._id,
                            paymentOption: order.paymentMethod.paymentId
                        };
                        this.sendInitializePayment(paymentInitailzeRequest);
                        // this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${order._id}`);
                    }
                    else {
                        debug('Order is not set...');
                        debug(order);
                        this.isLoading = false;
                        this.buttonSpinner = false;
                    }
                });
                // Create order and navigate to created orders
                // TODO create order object first setOrderModel(payment, cart, address, deliveryOption)
                const orderObject = this.orderOrchestrator.constuctOrderObject();
                this.orderStoreProvider.createNewOrder(orderObject);
            }
            else {
                const element = document.getElementById('continueButton');
                element.disabled = true;
                this.isLoading = false;
            }
        });
    }
    sendInitializePayment(payment) {
        this.buttonSpinner = true;
        this.paymentStoreProvider.initializePayment$(payment).subscribe(payment => {
            if (payment) {
                this.isPaymentInitializedError = false;
                window.location = payment;
            }
        }, error => {
            this.isLoading = false;
            this.isPaymentInitializedError = true;
        });
    }
    checkPaymentValidation() {
        this.subscriptions.push(this.paymentStoreProvider.isPaymentValid().subscribe((isValid) => {
            if (isValid) {
                this.buttonSpinner = false;
                this.disableSubmit = false;
            }
            else {
                this.disableSubmit = true;
            }
            this.isPaymentValid = isValid;
        }));
    }
    /**
     * Will navigate on edit address click.
     */
    onEditAddressClick() {
        this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/address`);
    }
    /**
     * Return to address page if need to change address.
     */
    // navigatetoAddressPage(language: string) {
    //   this.router.navigateByUrl(`/${language}/address`);
    //  }
    handlePaymentStatus(errorMessage) {
        let orderId;
        this.orderId$.subscribe(id => {
            orderId = id;
        });
        if (!errorMessage.validPayment && errorMessage.message !== '') {
            this.hasError = true;
            this.isLoading = false;
            this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${orderId}/payment-failure`);
        }
        else if (errorMessage.validPayment && errorMessage.paymentProcessStatus === PaymentProcessStatus.SUCCESSED) {
            this.hasError = false;
            this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${orderId}`);
        }
    }
    gtagSelectPayment() {
        /*try {
          gtag('event', 'conversion', {'send_to': 'AW-10789076290/csJzCOy8pYADEML60Jgo'});
        } catch (error) {
          console.log(error);
        }*/
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': { 'step': 2 },
                }
            }
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        clearInterval(this.checkPaymentProcessStatusFlow);
    }
}
