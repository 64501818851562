import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { AddressStoreProvider } from '@modeso/giftcardshop-lib-address-fe';
import { AppStateDispatcher } from './../../state/app.state.dispatcher';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';

@Component({
  selector: 'app-page-address',
  templateUrl: './address.page.html',
  styleUrls: ['./address.page.scss'],
  providers: [AddressStoreProvider]
})
export class AddressPage extends BasePageComponent implements OnInit, OnDestroy {
  public appStateDispatcher: AppStateDispatcher;
  public selectedDeliveryOption: DeliveryOptionModel;

  constructor(private router: Router, injector: Injector, private addressProvider: AddressStoreProvider) {
    super(injector);
    const url = '/' + localStorage.getItem('currentLanguage') + '/cart/' + localStorage.getItem('cartId');
    this.appStateDispatcher = injector.get(AppStateDispatcher);
    this.selectedDeliveryOption = JSON.parse(this.getSelectedDeliveryOption());
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-cart')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(url, breadcrumbTitle, false));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addressProvider.addressNavigation();
    this.canNavigat();

  }
  /**
   * subscribe canNavigate observable from address provider if it return true so navigate to
   * payent page.
   */
  canNavigat() {
    this.subscriptions.push(this.addressProvider.canNavigate.subscribe(canNavigate => {
      if (canNavigate) {
        this.navigateToPayment();
      }
    }));
  }

  /**
   * to navigate to payment page
   */
  navigateToPayment() {
    this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/payment`);
  }

  /**
   * call destroy function from provider to return all varibales to default value and unsubscribe
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    this.addressProvider.destroy();
  }

}
