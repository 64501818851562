import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromFeature from './../reducers/payment.reducer';
import { filter, map } from 'rxjs/operators';
import * as paymentActions from './../actions/payment.actions';
import { NamedString, InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import { PaymentOpionModel } from './../models/payment-option.model';
import Debug from 'debug';
import { PaymentProcessStatusResponse } from '../models/payment-process-status-response';
const debug = Debug('modeso:lidl-lib-payment-fe:PaymentStoreProvider');

const strings = [
  { 'modeso_payment_checkout-terms_accept-terms-text': 'Ich akzeptiere die <span>Allgemeinen Geschäftsbedingungen</span> der LIDL Schweiz AG.' },
  { 'modeso_payment_checkout-terms_accept-privacy-text': 'Die Hinweise zum Datenschutz habe ich zur Kenntnis genommen.' },
  { 'modeso_payment_checkout-payment-options_payment-options-caption': 'Zahlungsart' }
];
@Injectable({
  providedIn: 'root'
})
export class PaymentStoreProvider {

  constructor(public store: Store<fromFeature.AppState>, private injectedTextProvider: InjectedTextProvider) {
    const defaultStrings = new Array<NamedString>();
    strings.forEach((element) => {
      const x = new NamedString(Object.keys(element)[0], element[Object.keys(element)[0]]);
      defaultStrings.push(x);
    });
    debug('set default text - Payment');
    this.setInjectedText(defaultStrings);
  }

  public getSelectedPaymentOption$(): Observable<PaymentOpionModel> {
    return this.store.pipe(
      select(fromFeature.selectPaymentOptionModel),
    );
  }

  /**
   * check payment page validation
   */
  public isPaymentValid(): Observable<boolean> {
    return this.store.pipe(
      select(fromFeature.isPaymentValid),
      map((isValid: boolean) => {
        return isValid;
      }));
  }

  /**
   * re-inisialize store validation when order is done
   */
  public refreshStore(): void {
    this.store.dispatch(paymentActions.onPaymentOptionsValidSelected(
      { payload: null }
    ));
    this.store.dispatch(paymentActions.onTermsAndConditionsRejected());
  }

  private setInjectedText(injectedText: Array<NamedString>) {
    this.injectedTextProvider.setInjectedText(injectedText);
  }

  public initializePayment$(payment): Observable<any> {
    this.store.dispatch(paymentActions.intializePayment(payment));
    return this.store.pipe(
      select(fromFeature.paymentRedirectUrlFeature)
    );
  }

  // public getCurrentPaymentStatus$(orderId): Observable<any> {
  //   this.store.dispatch(paymentActions.getPaymentStatus(orderId))
  //   return this.store.pipe(select(fromFeature.paymentStatus));
  // }

  public getPaymentProcessStatus$(orderId: string) {
    this.store.dispatch(paymentActions.getPaymentProcessStatus({ orderId }));
    return this.store.pipe(select(fromFeature.paymentProcessStatusFeature),
      filter((status: PaymentProcessStatusResponse) => status.paymentProcessStatus !== undefined || 'paymentProcessStatus' in status));
  }
}
