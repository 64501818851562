import { Component } from '@angular/core';
import * as featureReducers from './../../shared/reducers/breadcrumb.reducer';
import { Store, select } from '@ngrx/store';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  breadcrumb$: Observable<BreadcrumbModel> = this.store.pipe(select(featureReducers.selectFeatureBreadcrumbs));

  constructor(private store: Store<featureReducers.AppState>) { }

}
