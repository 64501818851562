import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { CookieService } from 'ngx-cookie-service';
import { AuthProvider } from '@modeso/modeso-lib-auth-fe';
import { AuthenticationService } from '@modeso/modeso-lib-auth-fe';

@Component({
  selector: 'app-page-intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntroPage extends BasePageComponent {
  currentLang: string;
  errorMessage: string;
  hasError: boolean;

  constructor(
    injector: Injector,
    cookieService: CookieService,
    service: AuthenticationService,
    authProvider: AuthProvider) {
    super(injector);
    this.currentLang = localStorage.getItem('currentLanguage');
    this.setSelectedDeliveryOptionIndex('0');
  }

  ngOnInit(){
    super.ngOnInit();
    localStorage.setItem('checkInDate', new Date().toString());
  }
}
