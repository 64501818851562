import jwt_decode from 'jwt-decode';
import * as i0 from "@angular/core";
export class JWTService {
    decodeJwt(jwtToken) {
        return jwt_decode(jwtToken);
    }
    getUserFromJWT(jwtToken) {
        const decodedToken = this.decodeJwt(jwtToken);
        return decodedToken.userId;
    }
}
JWTService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JWTService_Factory() { return new JWTService(); }, token: JWTService, providedIn: "root" });
