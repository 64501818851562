import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil, skipWhile } from 'rxjs/operators';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { PaymentOpionModel } from '@modeso/giftcardshop-lib-payment-fe';
import { Cart, CartStoreProvider, AddDeliveryToCartRequest } from '@modeso/giftcardshop-lib-cart-fe';
import { AddressModel, AddressStoreProvider } from '@modeso/giftcardshop-lib-address-fe';
import { ProductStoreProvider, ProductDetailsModel } from '@modeso/giftcardshop-lib-products-fe';
import { onDeliveryOptionSeleced, onBillingAddressSet, onDeliveryAddressSet } from './app.state.actions';
import * as fromReducer from './app.state.reducer';
import Debug from 'debug';
const debug = Debug('lidl:shop:AppStateDispatcher');

@Injectable({
  providedIn: 'root'
})
export class AppStateDispatcher {

  constructor(
    private store: Store<fromReducer.AppState>,
    // private deliveryStoreProvider: DeliveryStoreProvider,
    private addressStore: AddressStoreProvider,
    private cartStore: CartStoreProvider,
    private productStore: ProductStoreProvider) {
    // this.readSelectedDeliveryOptionAndAddToStore();
  }

  // private readSelectedDeliveryOptionAndAddToStore() {
  //   this.store.pipe(select(fromReducer.selectedDeliveryOption)).pipe(take(1))
  //     .subscribe((deliveryOption: DeliveryOptionModel) => {
  //       if (deliveryOption === undefined || deliveryOption === null) {
  //         this.deliveryStoreProvider.getLoadedDeliveryOptions$()
  //           .pipe(
  //             skipWhile((deliveryOptions: DeliveryOptionModel[]) => deliveryOptions.length === 0),
  //             take(1))
  //           .subscribe((deliveryOptions: DeliveryOptionModel[]) => {
  //             debug('AppStateDispatcher read options...');
  //             debug(deliveryOptions);
  //             this.dispatchChangeSelectedDelvieryOption(deliveryOptions[0]);
  //           });
  //         debug('AppStateDispatcher SET default Delivery option');
  //       } else {
  //         debug('AppStateDispatcher SET selected Delivery option');
  //       }
  //     });
  // }

  public dispatchChangeSelectedDelvieryOption(deliveryOption: DeliveryOptionModel) {
    const data = { payload: deliveryOption };
    this.store.dispatch(onDeliveryOptionSeleced(data));
  }

  public dispatchChangeDeliveryAddress(address: AddressModel) {
    const data = { payload: address };
    this.store.dispatch(onDeliveryAddressSet(data));
  }

  public dispatchChangeBillingAddress(address: AddressModel) {
    const data = { payload: address };
    this.store.dispatch(onBillingAddressSet(data));
  }

  // public getSelectedDeliveryOption$(): Observable<DeliveryOptionModel> {
  //   debug('AppStateDispatcher getSelectedDeliveryOption$() is called');
  //   return this.store.pipe(select(fromReducer.selectedDeliveryOption));
  // }
  /*public getSelectedPaymentOption$(): Observable<PaymentOpionModel> {
      return this.store.pipe(select(fromReducer.selectedPaymentOption));
  }*/
  public getDeliveryAddress$(): Observable<AddressModel> {
    return this.addressStore.getDeliveryAddress$();
  }
  public getBillingAddress$(): Observable<AddressModel> {
    return this.addressStore.getBillingAddress$();
  }

  public loadCart() {
    this.cartStore.loadCart();
  }

  public addNewCart() {
    this.cartStore.addNewCart();
  }


  public getCart$(): Observable<Cart> {
    return this.cartStore.getActiveCart$();
  }

  public addSelectedDeliveryOption$(deliveryOptionToBeAdded: AddDeliveryToCartRequest) {
    this.cartStore.addSelectedDeliveryOption$(deliveryOptionToBeAdded);
  }

  public getDeliveryOptions$(): Observable<DeliveryOptionModel[]> {
    return this.cartStore.getDeliveryOptions$();
  }

  public getProductDetailsById$(productId: string): Observable<ProductDetailsModel> {
    return this.productStore.getProduct$(productId);
  }
}
