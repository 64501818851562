import { createAction, props, Action } from '@ngrx/store';
import { AddressModel } from '../models/address.model';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */

/**
 * We can change the addresss state using the actions.
 * This is action of post delivery addresss.
 */
export const postDeliveryAddress = createAction('[Address] postDeliveryAddress',
  props<AddressModel>());

/**
 * This is action of post delivery address when it return successfully.
 */
export const onDeliveryAddressPostedSuccessfully = createAction(
  '[Address] delivery address posted successfully',
  props<{ payload: any }>()
);

/**
 * This is action of post delivery address when it failed.
 */
export const onDeliveryAddressPostingFailed = createAction(
  '[Address]  delivery address loading failed',
  props<{ payload: any }>()
);

/**
 * We can change the addresss state using the actions.
 * This is action of post billing addresss.
 */
export const postBillingAddress = createAction('[Address] postBillingAddress',
  props<AddressModel>());

/**
 * This is action of post billing address when it return successfully.
 */
export const onBillingAddressPostedSuccessfully = createAction(
  '[Address] billing address posted successfully',
  props<{ payload: any }>()
);

/**
 * This is action of posy billing address when it failed.
 */
export const onBillingAddressPostingFailed = createAction(
  '[Address]  billing address posting failed',
  props<{ payload: any }>()
);

/**
 * We can change the Address state using the actions.
 * This is action of get delivery address by id.
 */
export const getDeliveryAddressById = createAction('[Address] getDeliveryAddressById', props<{ _id: string }>());

/**
 * This is action of get delivery address by id when it return successfully.
 */
export const onAddressDeliveryByIdLoadedSuccessfully = createAction(
  '[Address]  addressById loaded successfully',
  props<{ payload: AddressModel }>()
);

/**
 * This is action of get delivery address by id when it failed.
 */
export const onAddressDeliveryByIdLoadingFailed = createAction(
  '[Address]  addressById loading failed',
  props<{ payload: any }>()
);

/**
 * We can change the Address state using the actions.
 * This is action of get billing address by id.
 */
export const getBillingAddressById = createAction('[Address] getBillingAddressById', props<{ _id: string }>());

/**
 * This is action of get billing address by id when it return successfully.
 */
export const onAddressBillingByIdLoadedSuccessfully = createAction(
  '[Address]  addressById loaded successfully',
  props<{ payload: AddressModel }>()
);

/**
 * This is action of get billing address by id id when it failed.
 */
export const onAddressBillingByIdLoadingFailed = createAction(
  '[Address]  addressById loading failed',
  props<{ payload: any }>()
);

/**
 * We can change the addresss state using the actions.
 * This is action of put delivery addresss.
 */
export const putDeliveryAddress = createAction('[Address] putDeliveryAddress',
  props<AddressModel>());

/**
 * This is action of put delivery addresss when it return successfully.
 */
export const onPutDeliveryAddressSuccessfully = createAction(
  '[Address] delivery address edited successfully',
  props<{ payload: any }>()
);

/**
 * This is action of put delivery addresss when it failed.
 */
export const onPutDeliveryAddressFailed = createAction(
  '[Address]  delivery address edit failed',
  props<{ payload: any }>()
);

/**
 * We can change the addresss state using the actions.
 * This is action of put billing addresss.
 */
export const putBillingAddress = createAction('[Address] putBillingAddress',
  props<AddressModel>());

/**
 * This is action of put billing addresss when it return successfully.
 */
export const onPutBillingAddressSuccessfully = createAction(
  '[Address] billing address edited successfully',
  props<{ payload: any }>()
);

/**
 * This is action of put billing addresss when it failed.
 */
export const onPutBillingAddressFailed = createAction(
  '[Address]  billing address edited failed',
  props<{ payload: any }>()
);

/**
 * We can change the Address state using the actions.
 * This is action of get billing address by id.
 */
export const deleteBillingAddress = createAction('[Address] deleteBillingAddress', props<{ _id: string }>());

/**
 * This is action of get billing address by id when it return successfully.
 */
export const onDeleteAddressBillingSuccessfully = createAction(
  '[Address] delete address successfully',
  props<{ payload: any }>()
);

/**
 * This is action of get billing address by id id when it failed.
 */
export const onDeleteAddressBillingFailed = createAction(
  '[Address] delete address failed',
  props<{ payload: any }>()
);

/**
 * This is action of is billing address.
 */
export const onIsBillingAddressMarked = createAction(
  '[Address] is Billing address marked',
  props<{ payload: any }>()
);

/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

/**
 * action delivery with address id.
 */
export interface ActionDeliveryWithAddressId extends Action {
  _id: string;
}

/**
 * action billing with address id.
 */
export interface ActionBillingWithAddressId extends Action {
  _id: string;
}
