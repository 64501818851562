import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { addToCart, addNewCart } from '../../actions/cart.actions';
import * as fromFeature from '../../reducers/cart.reducer';
import { CartProductModel } from '../../models/cart-product.model';

@Component({
  selector: 'modeso-refresh-cart-store',
  templateUrl: './refresh-cart-store.component.html',
  styleUrls: ['./refresh-cart-store.component.scss']
})
export class RefreshCartStoreComponent implements OnInit {


  constructor(private store: Store<fromFeature.AppState>) { }
  ngOnInit(): void {
    this.store.dispatch(addNewCart());
  }
}
