import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductDetailsService } from '../services/product-details.service';
import * as productDetailsActions from './../actions/product-details.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-products-fe:ProductDetailsEffects');


@Injectable()
export class ProductDetailsEffects {

  constructor(private actions$: Actions, private service: ProductDetailsService) { }

  /**
   * An injectable Actions service that provides an observable stream of all actions dispatched after the latest state has been reduced.
   * Metadata is attached to the observable streams using the createEffect function. The metadata is used to register the streams that
   * are subscribed to the store.
   * Any action returned from the effect stream is then dispatched back to the Store.
   * Actions are filtered using a pipeable ofType operator. The ofType operator takes one or more action types as
   * arguments to filter on which actions to act upon.
   * Effects are subscribed to the Store observable.
   * Services are injected into effects to interact with external APIs and handle streams.
   */
  loadProductById$ = createEffect(
    () => this.actions$.pipe(
      ofType<productDetailsActions.ActionWithProductId>(productDetailsActions.getProductById.type),
      mergeMap(
        (action) => {
          debug(`product details should be loaded for ${action.productId}`);
          return this.service.getProductById(action.productId)
            .pipe(
              retry(1),
              tap(
                (response) => this.log(response)
              ),
              map(
                response => (productDetailsActions.onProductDetailsLoadedSuccessfully({ payload: response.product }))
              ),
              catchError((error) => of(productDetailsActions.onProductDetailsLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
   * ErrorOnLoadProductById$ effect handling errors when fetching product details.
   */
  errorOnLoadProductById$ = createEffect(
    () => this.actions$.pipe(
      ofType(productDetailsActions.onProductDetailsLoadingFailed.type),
      tap(
        (action: productDetailsActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllProductErrors(error) {
    debug(error);
    return error;
  }

  log(data) {
    debug('data: ', data);
  }
}
