import { Component, Injector } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';

@Component({
  selector: 'app-page-under-construction',
  templateUrl: './under-construction.page.html',
  styleUrls: ['./under-construction.page.scss']
})
export class UnderConstructionPage extends BasePageComponent {

  /**
   *
   * @param route
   * @param injector
   */
  constructor(private route: ActivatedRoute, injector: Injector) {
    super(injector);
    this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}/payment`, 'Zurück', false);
  }

}
