import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroPage } from './pages/intro/intro.page';
import { HomePage } from './pages/home/home.page';
import { ProductDetailPage } from './pages/products/product-detail.page';
import { CartPage } from './pages/cart/cart.page';
import { AddressPage } from './pages/address/address.page';
import { PaymentPage } from './pages/payment/payment-detail.page';
import { UnderConstructionPage } from './pages/under-construction/under-construction.page';
import { OrderConfirmationPage } from './pages/order/orderConfirmation.page';
import { BalancePage } from './pages/balance/balance.page';
import { StaticContentPage } from './pages/static-content/static-content.page';
// import { LoginPage } from './pages/login/login.page';
import { NavigationAuthGuard } from './shared/services/guards/navigation-auth.guard';

const routes: Routes = [
  {
    path: ':language',
    component: IntroPage,
    data: { header: true }
  },
  // {
  //   path: ':language/login',
  //   component: LoginPage,
  // },
  {
    path: ':language/products/:cat',
    component: HomePage,
    data: { header: true }
  },
  {
    path: ':language/content/:contentFilter',
    component: StaticContentPage
  },
  {
    path: ':language/giftcard/:id',
    component: ProductDetailPage,
  },
  {
    path: ':language/cart/:id',
    component: CartPage,
    data: { header: false }
  },
  {
    path: ':language/address',
    component: AddressPage
  },
  {
    path: ':language/payment',
    component: PaymentPage,
    canActivate: [NavigationAuthGuard]
  },
  {
    path: ':language/order/:orderId/payment-failure',
    component: PaymentPage,
  },
  {
    path: ':language/order/:orderId/payment-success',
    component: PaymentPage,
  },
  {
    path: ':language/under-construction',
    component: UnderConstructionPage,
  },
  {
    path: ':language/product',
    loadChildren: './microservices/products/products.module#AppProductsModule',
  },
  {
    path: ':language/order/:id',
    component: OrderConfirmationPage,
  },
  {
    path: ':language/balance-check',
    component: BalancePage,
  },
  {
    path: '',
    redirectTo: '/de-ch',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: HomePage
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
