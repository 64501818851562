import { Action, createAction, props } from '@ngrx/store';
import { CartProductModel } from '../models/cart-product.model';
import { CartResponseModel } from '../models/response/cart-response.model';
import { ProductUpdateRequestModel } from '../models/request/product-update-request.model';
import { ProductRemoveRequestModel } from '../models/request/product-remove-request.model';
import { DeliveryOptionModel } from '../models/delivery-options.model';
import { AddDeliveryToCartRequest } from '../models/request/add-delivery-to-cart-request';
import { HttpErrorResponse } from '@angular/common/http';

export const addToCart = createAction(
  '[Home Page and Product Page] addToCart',
  props<{ payload: CartProductModel }>()
);

export const onAddToCartSuccess = createAction(
  '[Add to cart success response] onAddToCartSuccess',
  props<{ payload: CartResponseModel }>()
);

export const onAddToCartFail = createAction(
  '[Add to cart fail response] onAddToCartFail',
  props<{ payload: any }>()
);

export const onChangeProductPackage = createAction(
  '[change product package] onChangeProductPackage',
  props<{ payload: ProductUpdateRequestModel }>()
);

export const onRemoveProduct = createAction(
  '[ remove product from cart ] onRemoveProduct',
  props<{ payload: ProductRemoveRequestModel }>()
);

export const onRemoveProductSuccess = createAction(
  '[ remove product from cart success response] onRemoveProductSuccess',
  props<{ payload: CartResponseModel }>()
);
export const onChangeProductPackageSuccess = createAction(
  '[change product package] onChangeProductPackage Success',
  props<{ payload: CartResponseModel }>()
);

export const onChangeProductAmount = createAction(
  '[change product Amount] onChangeProductAmount',
  props<{ payload: ProductUpdateRequestModel }>()
);

export const onChangeProductAmountSuccess = createAction(
  '[change product Amount success response] onChangeProductAmount Success',
  props<{ payload: CartResponseModel }>()
);

export const onChangeProductAmountFail = createAction(
  '[change product Amount fail response] onChangeProductAmount Fail',
);

export const onLoadCart = createAction(
  '[Cart Page init] get cart data'
);

export const onLoadCartSuccess = createAction(
  '[Cart Page data loaded successfully] get cart data details successfully',
  props<{ payload: any }>()
);

export const onChangeCardDeliveryOptions = createAction(
  '[cart delivery option] cart data delivery options success',
  props<{ payload: DeliveryOptionModel }>()
);


export const addNewCart = createAction(
  '[Home Page and Product Page] addNewCart'
);

export const onNewToCartSuccess = createAction(
  '[Add new cart success response] onNewToCartSuccess',
  props<{ payload: CartResponseModel }>()
);

export const addSelectedDeliveryOption = createAction(
  '[Cart] addSelectedDeliveryOption',
  props<{ payload: AddDeliveryToCartRequest }>()
);

export const onAddSelectedDeliveryOptionSuccessfully = createAction(
  '[Cart] addSelectedDeliveryOption successfully',
  props<{ payload: DeliveryOptionModel }>()
);

export const onAddSelectedDeliveryOptionFailed = createAction(
  '[Cart] addSelectedDeliveryOption failed',
  props<{ payload: HttpErrorResponse }>()
);

export interface IActionWithPayload<T> extends Action {
  payload: T;
}
