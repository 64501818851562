import { Injectable } from '@angular/core';
import { ProductDetailsModel } from '@modeso/giftcardshop-lib-products-fe';
import { CartProductModel } from '@modeso/giftcardshop-lib-cart-fe';

@Injectable({
  providedIn: 'root'
})
export class CartOrchestrator {

  constructor() {
  }

  public constructCartProductFromProduct(product: ProductDetailsModel): CartProductModel {
    if (product === undefined) { return undefined; }
    const cartProduct = new CartProductModel();
    cartProduct.productId = product.productId;
    cartProduct.title = product.name;
    cartProduct.image = product.image;
    cartProduct.description = product.shortDescription;
    cartProduct.price = product.price;
    cartProduct.amount = 1;
    cartProduct.packagePrice = product.packagePrice;
    cartProduct.isPackagingSelected = false;
    cartProduct.variablePrice = product.variablePrice;
    cartProduct.category = product.category;
    cartProduct.ean = product.ean;
    cartProduct.pdfTemplatePath = product.pdfTemplatePath;
    return cartProduct;
  }
}
