<lidl-lib-order-fe-information [orderId$]="orderId$" [deliveryDate]="(order$ | async)?.deliveryOption?.description" [setOrderPostedStatus]="isOrderPostedSuccessfully"
  [language]="getLanguage()">
  <!--
    cart: should contain products
    address: should contain 2 addresses or one
    selectedPaymentOption: should contain the payment option
      make an address model, which defines, which is invoice and which is delivery?
    selected delivery option: should contain the delivery option
  -->
  <modeso-compact-address-view address-content [deliveryAddress]="(order$ | async)?.address[0]"
    [billingAddress]="(order$ | async)?.address[1]">
  </modeso-compact-address-view>
  <lidl-lib-payment-fe-payment-selected-view [paymentOption]="(order$ | async)?.paymentMethod" payment-content>
  </lidl-lib-payment-fe-payment-selected-view>
  <modeso-compact-cart-view cart-content [additionalCharges]="(order$ | async)?.deliveryOption?.price"
    [totalPrice]="(order$ | async)?.totalPrice" [cart]="(order$ | async)?.cart">
    <tr afterProductList>
      <td id="shippingTitle" class="shipping-title"> {{ (order$ | async)?.deliveryOption?.name }} </td>
      <td id="shippingPrice" class="price"> CHF {{ (order$ | async)?.deliveryOption?.price | number : '1.2' }}</td>
    </tr>
  </modeso-compact-cart-view>
  <ng-container *ngIf="refreshCart">
    <modeso-refresh-cart-store></modeso-refresh-cart-store>
  </ng-container>
  <!-- <modeso-refresh-cart-store ???-content></modeso-refresh-cart-store> -->
</lidl-lib-order-fe-information>
