import { Component, Input } from '@angular/core';
import { AddressModel } from './../../models/address.model';

@Component({
  selector: 'modeso-compact-address-view',
  templateUrl: './compact-address-view.component.html',
  styleUrls: ['./compact-address-view.component.scss']
})
export class CompactAddressViewComponent {
  @Input() deliveryAddress: AddressModel;
  @Input() billingAddress: AddressModel;

  constructor() { }

}
