import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductsService } from '../services/products.service';
import * as productsActions from './../actions/products.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-products-fe:ProductsEffects');

@Injectable()
export class ProductsEffects {

  constructor(private actions$: Actions, private service: ProductsService) { }

  loadAllProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.getAllProducts.type),
      mergeMap(
        () => {
          return this.service.getAllProducts()
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                productsResponse => (productsActions.onAllProductsLoadedSuccessfully({ payload: productsResponse.products }))
              ),
              // tslint:disable-next-line:object-literal-shorthand
              catchError((error) => of(productsActions.onAllProductsLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  loadAllProductsByCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType<productsActions.ActionWithProductCategory>(productsActions.getProductsByCategory.type),
      mergeMap(
        (action) => {
          debug(`product details should be loaded for ${action.productCategory}`);
          return this.service.getProductsByCategory(action.productCategory)
          .pipe(
            map(
              (productsByCategoryResponse) =>
              (productsActions.onGetProductByCategorySuccessfully({ payload: productsByCategoryResponse.products }))
            ),
            catchError((error) => of(productsActions.onGetProductByCategoryFailed({ payload: error })))
          );
        }
      )
    )
  );

  errorOnLoadByCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.onGetProductByCategoryFailed.type),
      tap(
        (action: productsActions.ActionWithPayload<any>) => this.handleOnLoadAllProductsErrors(action.payload)
      )
    )
    , { dispatch: false });

  errorOnLoadAllProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.onAllProductsLoadingFailed.type),
      tap(
        (action: productsActions.ActionWithPayload<any>) => this.handleOnLoadAllProductsErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllProductsErrors(error) {
    debug(error);
    return error;
  }
}
