import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CartProductModel } from '../../models/cart-product.model';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { addToCart, addNewCart } from '../../actions/cart.actions';
import * as fromFeature from '../../reducers/cart.reducer';
import { CartLocalStorageService } from '../../services/cartLocalStorageService.service';
import { ProductVariablePriceModel } from '../../models/product-variable-price.model';
import { ProductNotificationModel } from '../../models/product-notification.model';
declare global { interface Window { dataLayer:any; } }


@Component({
  selector: 'lidl-lib-cart-fe-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent {

  // TODO check this input attributes... are they really needed?
  @Input() caption: string;
  @Output() productNotificationEvent: EventEmitter<ProductNotificationModel> = new EventEmitter<ProductNotificationModel>();
  addToCartState$: Observable<any> = this.store.pipe(select(fromFeature.featureCart));
  _product: CartProductModel;
  isFixed: boolean;
  isProductCard = false;
  productDetails: CartProductModel;

  private productId: string;
  private title: string;
  private image: string;
  private description: string;
  public price: number = 0;
  private packagePrice: number;
  public variablePrice: ProductVariablePriceModel;
  public category: string;
  public amount: number;
  public ean: string;
  public pdfTemplatePath: string;


  cartProduct$: Observable<CartProductModel>;

  get product(): CartProductModel {
    return this._product;
  }

  @Input('product')
  set product(value: CartProductModel) {

    this._product = value;
    this.productDetails = value;
    if (value !== undefined) {
      this.productId = value.productId;
      this.title = value.title;
      this.image = value.image;
      this.description = value.description;
      this.packagePrice = value.packagePrice;
      this.variablePrice = value.variablePrice;
      this.isFixed = value.price ? true : false;
      this.category = value.category;
      this.ean = value.ean;
      this.pdfTemplatePath = value.pdfTemplatePath;
      if (!this.isProductCard) {
        this.price = value.price ? value.price : value.variablePrice.defaultPrice;
      }
      this.isProductCard = true;

    } else {
      this.productId = undefined;
      this.title = undefined;
      this.image = undefined;
      this.description = undefined;
      this.price = undefined;
      this.packagePrice = undefined;
      this.category = undefined;
      this.ean = undefined;
      this.pdfTemplatePath = undefined;
    }
  }



  constructor(private store: Store<fromFeature.AppState>, private cartLocalStorageService: CartLocalStorageService) {
  }

  addToCart() {
    if (!this.isPriceWithinRange()) {
      this.productNotificationEvent.emit({
        message: 'modeso_cart_add-to-cart_price_error',
        isError: true,
        productId: this.productId
      });
    } else if(!this.isValidAmount()) {
      this.productNotificationEvent.emit({
        message: 'modeso_cart_add-to-cart_amount_error',
        isError: true,
        productId: this.productId
      });
    } else {
      if (!this.cartLocalStorageService.isSetCartId()) {
        this.store.dispatch(addNewCart());
      } else {
        this.dispatchAddToCartAction();
        this.checkAddToCartSuccessfully();
        setTimeout(() => {
          this.gtagAddToCart();
        }, 1000);
      }
    }
  }

  private isValidAmount(): boolean {
    return this.amount >= 1;
  }


  private isPriceWithinRange(): boolean {
    if ((this.price < this.variablePrice.from) || (this.price > this.variablePrice.to)) {
      return false;
    }
    return true;
  }

  dispatchAddToCartAction() {
    const data = { payload: this.createActionPayload() };
    this.store.dispatch(addToCart(data));
  }

  private createActionPayload(): CartProductModel {
    const { title, price, productId, amount, description, image, variablePrice, packagePrice, category, ean, pdfTemplatePath } = this;
    return { title, price, productId, amount: +amount, description, image, variablePrice, packagePrice: +packagePrice, category, ean, pdfTemplatePath };
  }

  changeVariablePrice() {
    this.fireDataChangedEvent();
  }

  changeAmount() {
    this.fireDataChangedEvent();
  }

  fireDataChangedEvent() {
    this.productNotificationEvent.emit(undefined);
  }
  checkAddToCartSuccessfully() {
    this.addToCartState$.pipe(skip(1)).subscribe((status) => {
      if (status.error) {
        this.productNotificationEvent.emit({
          message: 'modeso_cart_add-to-cart_total_exceeded_error',
          isError: true,
          productId: this.productId
        });
      } else {
        this.productNotificationEvent.emit({
          message: 'modeso_cart_added-to-cart_title',
          isError: false,
          productId: this.productId
        });
      }
    });
  }

  public gtagAddToCart() {
    //gtag('event', 'conversion', {'send_to': 'AW-10789076290/36mFCMitqoADEML60Jgo'});
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'EECaddToCart',
      'ecommerce': {
        'add': {
          'products': [{
            'name': this.title,
            'id': this.productId,
            'price': this.price,
            'category': this.category,
            'quantity': this.amount,
          }]
        }
      }
    });
    
  }
}
