import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartProductModel } from '../../models/cart-product.model';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../../reducers/cart.reducer';
import * as cartActions from './../../actions/cart.actions';
import { CartResponseModel } from '../../models/response/cart-response.model';
import { Observable } from 'rxjs';
import { ProductRemoveRequestModel } from '../../models/request/product-remove-request.model';
import { ProductUpdateRequestModel } from '../../models/request/product-update-request.model';
import { CartLocalStorageService } from '../../services/cartLocalStorageService.service';
declare global { interface Window { dataLayer:any; } }

@Component({
  selector: 'lidl-lib-cart-fe-product-list',
  templateUrl: './cart-product-list.component.html',
  styleUrls: ['./cart-product-list.component.scss']
})
export class CartProductListComponent {
  carts$: Observable<CartResponseModel>;
  @Input() products: CartProductModel[];

  @Output() currentCartId = new EventEmitter<string>();
  ChangeProductAmountFaild = false;
  productId: string;
  currentProductPrice: number;
  ChangeProductAmountState$: Observable<string> = this.store.pipe(select(fromFeature.featureStatusOfOperationsOnCart));

  constructor(private store: Store<fromFeature.AppState>, private cartLocalStorageService: CartLocalStorageService) { }

  deleteFromCart(product: CartProductModel) {
    const request = new ProductRemoveRequestModel();
    request.productId = product.productId;
    request._id = product._id;
    request.cartId = this.getCartIdFromLocalStorage();
    this.store.dispatch(cartActions.onRemoveProduct({ payload: request }));
    this.carts$ = this.store.pipe(select(fromFeature.featureCart));
    this.currentCartId.emit(this.getCartIdFromLocalStorage());
    setTimeout(() => {
      this.gtagRemoveFromCart(product);
    }, 1000);
  }

  onChangeAmount(amount: number, product: CartProductModel) {
    this.store.dispatch(cartActions.onChangeProductAmount({ payload: this.constructProductRequestUpdate(amount, product) }));
    this.checkChangeProductAmountSuccessfully(product);
    this.carts$ = this.store.pipe(select(fromFeature.featureCart));
    this.currentCartId.emit(this.getCartIdFromLocalStorage());

  }

  private constructProductRequestUpdate(amount: number, product: CartProductModel) {
    const request = new ProductUpdateRequestModel();
    request.productId = product.productId;
    request._id = product._id;
    request.cartId = this.getCartIdFromLocalStorage();
    request.amount = amount;
    request.isPackagingSelected = product.isPackagingSelected;
    return request;
  }

  private getCartIdFromLocalStorage() {
    return this.cartLocalStorageService.getCartId();
  }

  checkChangeProductAmountSuccessfully(product: CartProductModel) {
    this.ChangeProductAmountState$.subscribe((status: string) => {
      if (status === 'changeProductAmountFaild') {
        this.ChangeProductAmountFaild = true;
        this.productId = product.productId;
        this.currentProductPrice = product.price;
      } else if (status === '') {
        this.ChangeProductAmountFaild = false;
        this.productId = '';
        this.currentProductPrice = undefined;
      }
    });
  }

  public gtagRemoveFromCart(product: CartProductModel) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'EECremoveFromCart',
      'ecommerce': {
        'remove': {
          'products': [{
            'name': product.title,
            'id': product.productId,
            'price': product.price,
            'category': product.category,
            'quantity': product.amount,
          }]
        }
      }
    });
  }
}
