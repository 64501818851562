import { createAction, props } from '@ngrx/store';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { PaymentOpionModel } from '@modeso/giftcardshop-lib-payment-fe';
import { AddressModel } from '@modeso/giftcardshop-lib-address-fe';

export const onDeliveryOptionSeleced = createAction(
    '[shop] onDeliveryOptionSeleced', props<{ payload: DeliveryOptionModel }>()
);
export const onPaymentOptionSeleced = createAction(
    '[shop] onPaymentOptionSeleced', props<{ payload: PaymentOpionModel }>()
);
export const onDeliveryAddressSet = createAction(
    '[shop] onDeliveryAddress set', props<{ payload: AddressModel }>()
);
export const onBillingAddressSet = createAction(
    '[shop] onBillingAddress set', props<{ payload: AddressModel }>(),
);



