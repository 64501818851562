import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbModel } from '../../shared/models/breadcrumb.model';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppStateDispatcher } from './../../state/app.state.dispatcher';
import { OrderOrchestrator } from '../../orchestrator/order.orchestrator';
import { AddressStoreProvider } from '@modeso/giftcardshop-lib-address-fe';
import { DeliveryOptionModel } from '@modeso/giftcardshop-lib-delivery-fe';
import { OrderStoreProvider } from '@modeso/giftcardshop-lib-order-fe';
import {
  IPaymentStatusResponse, PaymentStoreProvider,
  PaymentProcessStatusResponse, PaymentProcessStatus
} from '@modeso/giftcardshop-lib-payment-fe';
import Debug from 'debug';
declare global { interface Window { dataLayer:any; } }

const debug = Debug('lidl:shop:PaymentPage');


@Component({
  selector: 'app-page-payment',
  templateUrl: './payment-detail.page.html',
  styleUrls: ['./payment-detail.page.scss']
})
export class PaymentPage extends BasePageComponent implements OnInit, OnDestroy {
  public appStateDispatcher: AppStateDispatcher;
  public selectedDeliveryOption: DeliveryOptionModel;
  private orderStoreProvider: OrderStoreProvider;
  private orderOrchestrator: OrderOrchestrator;

  hasError = false;
  buttonSpinner = false;
  disableSubmit = true;
  isPaymentValid = false;
  isLoading = false;
  isPaymentInitializedError = false;
  orderId$: Observable<string>;
  paymentSuccess$: Observable<string>;
  isPaymentDone: boolean;
  currentOrderId: string;
  checkPaymentProcessStatusFlow: any;
  /**
   *
   * @param route
   * @param injector
   */
  constructor(
    injector: Injector, private router: Router,
    private paymentStoreProvider: PaymentStoreProvider,
    private addressProvider: AddressStoreProvider, private route: ActivatedRoute) {
    super(injector);
    this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}/address`, 'Zurück zur Adressdaten', false);
    this.checkPaymentValidation();
    // Why destroy adress provider?
    this.addressProvider.destroy();
    this.appStateDispatcher = injector.get(AppStateDispatcher);
    this.orderStoreProvider = injector.get(OrderStoreProvider);
    this.orderOrchestrator = injector.get(OrderOrchestrator);
    this.selectedDeliveryOption = JSON.parse(this.getSelectedDeliveryOption());
    this.orderId$ = this.route.params.pipe(map(params => params.orderId));
    if (this.orderId$) {
      this.isPaymentDone = true;
    }

    this.orderId$.subscribe((id: string) => this.currentOrderId = id)
    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_back-to-address')
      .subscribe((breadcrumbTitle) =>
        this.breadcrumb = new BreadcrumbModel(`/${localStorage.getItem('currentLanguage')}/address`, breadcrumbTitle, false));
  }

  ngOnInit() {
    super.ngOnInit();
    if (window.location.href.indexOf('payment-failure') > 0) {
      this.hasError = true;
      this.isPaymentValid = false;
      this.isLoading = false;
      this.isPaymentDone = false;
      this.disableSubmit = true;
    }

    if (window.location.href.indexOf('payment-success') > 0) {
      this.isLoading = true;
      this.checkPaymentProcessStatusFlow = setInterval(() => {
        this.checkPayemntProcessStatus();
      }, 20000);
    }

  }

  async checkPayemntProcessStatus() {

    this.subscriptions.push(
      this.paymentStoreProvider.getPaymentProcessStatus$(this.currentOrderId).subscribe((status: PaymentProcessStatusResponse) => {

        // if status is started don't send another request
        if (status.paymentProcessStatus === PaymentProcessStatus.SUCCESSED) {

          const validPayment: IPaymentStatusResponse = {
            validPayment: true,
            message: '',
            paymentProcessStatus: PaymentProcessStatus.SUCCESSED,
          };
          this.handlePaymentStatus(validPayment);

        }

        // if status is failed display error
        if (status.paymentProcessStatus === PaymentProcessStatus.FAILED) {

          const unvalidPayment: IPaymentStatusResponse = {
            validPayment: false,
            message: 'Failure in payment flow',
            paymentProcessStatus: PaymentProcessStatus.FAILED,
          };
          this.handlePaymentStatus(unvalidPayment);

        }
      })
    );


  }

  submit() {
    this.isLoading = true;
    this.paymentStoreProvider.isPaymentValid().pipe(
      take(1)
    ).subscribe((isValid: boolean) => {
      if (isValid) {
        // Navigate to confirmation page

        this.orderStoreProvider.getNewOrder$().subscribe((order: any) => {

          if (order && order._id) {
            debug('Payment initialized...');
            debug(order);
            this.gtagSelectPayment();

            const paymentInitailzeRequest = {
              orderReference: order._id,
              paymentOption: order.paymentMethod.paymentId
            };

            this.sendInitializePayment(paymentInitailzeRequest);

            // this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${order._id}`);
          } else {
            debug('Order is not set...');
            debug(order);
            this.isLoading = false;
            this.buttonSpinner = false;
          }
        });
        // Create order and navigate to created orders
        // TODO create order object first setOrderModel(payment, cart, address, deliveryOption)
        const orderObject = this.orderOrchestrator.constuctOrderObject();
        this.orderStoreProvider.createNewOrder(orderObject);
      } else {
        const element = document.getElementById('continueButton') as HTMLInputElement;
        element.disabled = true;
        this.isLoading = false;
      }
    });

  }

  sendInitializePayment(payment) {
    this.buttonSpinner = true;
    this.paymentStoreProvider.initializePayment$(payment).subscribe(payment => {
      if (payment) {
        this.isPaymentInitializedError = false;
        window.location = payment;
      }

    }, error => {
      this.isLoading = false;
      this.isPaymentInitializedError = true;
    });
  }

  checkPaymentValidation() {
    this.subscriptions.push(this.paymentStoreProvider.isPaymentValid().subscribe((isValid: boolean) => {
      if (isValid) {
        this.buttonSpinner = false;
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
      this.isPaymentValid = isValid;
    }));
  }

  /**
   * Will navigate on edit address click.
   */
  onEditAddressClick() {
    this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/address`);
  }

  /**
   * Return to address page if need to change address.
   */
  // navigatetoAddressPage(language: string) {
  //   this.router.navigateByUrl(`/${language}/address`);
  //  }

  handlePaymentStatus(errorMessage) {
    let orderId: string;
    this.orderId$.subscribe(id => {
      orderId = id;
    });

    if (!errorMessage.validPayment && errorMessage.message !== '') {
      this.hasError = true;
      this.isLoading = false;
      this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${orderId}/payment-failure`);
    } else if (errorMessage.validPayment && errorMessage.paymentProcessStatus === PaymentProcessStatus.SUCCESSED) {

      this.hasError = false;
      this.router.navigateByUrl(`${localStorage.getItem('currentLanguage')}/order/${orderId}`);
    }
  }

  public gtagSelectPayment() {
    /*try {
      gtag('event', 'conversion', {'send_to': 'AW-10789076290/csJzCOy8pYADEML60Jgo'});
    } catch (error) {
      console.log(error);
    }*/
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {'step': 2},
        }
      }
    });
    
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this.checkPaymentProcessStatusFlow);
  }
}
