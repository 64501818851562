<app-shop-content-block [contentId]="categoryContentRef"></app-shop-content-block>
<div>
  <ng-template #addToCartButton let-default>
    <lidl-lib-cart-fe-add-to-cart
    (productNotificationEvent)="handleProductNotificationEvent($event)"  [product]="cartOrchestrator.constructCartProductFromProduct(default)"
    [caption]="'modeso_cart_add-to-cart-button_caption' | localize | async">
    </lidl-lib-cart-fe-add-to-cart>
  </ng-template>
  <modeso-product-list [productNotificationSetter]="productNotificationModel" (redirectToCartEvent)="handleRedirectToCartEvent()" [templateRef]="addToCartButton" [showDetailsButtonCaption]="'modeso_products_product-list_show-details-button-caption' | localize | async">
  </modeso-product-list>
</div>
