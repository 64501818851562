import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHandlerInterceptor } from './shared/interceptors/authentication.interceptor';
import { ModesoAuthConfig, ModesoAuthConfigService } from './Auth.config';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './reducers/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effect';
@NgModule({
  providers: [AuthHandlerInterceptor, CookieService],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    StoreModule.forFeature(fromAuth.featureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [],
})
export class ModesoAuthModule {
  static forRoot(
    config: ModesoAuthConfig,
  ): ModuleWithProviders<ModesoAuthModule> {
    return {
      ngModule: ModesoAuthModule,
      providers: [
        AuthHandlerInterceptor,

        {
          provide: ModesoAuthConfigService,
          useValue: config,
        },
      ],
    };
  }
}
