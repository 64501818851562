import { Component, Injector, OnInit } from '@angular/core';
import { LocalizationStoreProvider } from '@modeso/modeso-lib-localization-fe';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  visible = false;
  showCookie= false;
  private localizationStoreProvider: LocalizationStoreProvider;

  constructor(
    injector: Injector,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.localizationStoreProvider = injector.get(LocalizationStoreProvider);

    if (environment.production) {
      const navEnd = router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      );

      navEnd.subscribe((event: NavigationEnd) => {
        // console.log(event);
        gtag('config', 'UA-66134079-7', {
          page_path: event.urlAfterRedirects,
          page_title : event.urlAfterRedirects
        });
      });
    }

  }

  ngOnInit(): void {

    // Whenever the path(route) changes, the subscription to the events property of the reference router(this.router.events) fires.

    this.router.events.pipe(
      // 1. First we are checking if the NavigationEnd event is triggered.
      // This event is triggered when the navigation to the route ends successfully.
      // NavigationEnd {id: 1, url: “/compA”, urlAfterRedirects: “/compA”}
      filter(events => events instanceof NavigationEnd),
      // 2. Next we are mapping the result of the filter to the ActivatedRoute reference.
      map(evt => this.activatedRoute),
      map(route => {
        // 3. we are checking if the current active route has any child route.
        // the route details will give us insight on the outlet and data property of this route.
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(
        // 4. We are checking if the outlet property of CompAComponent is primary. If yes,we are extracting the data property of the route.
        // this will help incase we have more than one router-outlet
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
        // 5.Finally, we are subscribing to the result
        // checking if the header property of data object is true or not.
        // If true,we are setting visibility as true and the header will be visible else it wont be visible.
      ).subscribe(x => x.header === true ? this.visible = true : this.visible = false);

      this.router.events.subscribe((val) => {
        if (val instanceof ActivationEnd) {
          if (val.snapshot.params.contentFilter == undefined) {
            this.showCookie = true;
          }
        }
      });

    this.localizationStoreProvider.getLocalizedStringForKey('modeso_shop_page_title')
      .subscribe((title) => this.titleService.setTitle(title));
  }
}
