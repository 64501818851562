import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { ServerSideStorage } from '../storages/serverside.storage';
import { IAuthorizationProvider } from '../interceptors/authorization.interceptor';
import { ILanguageProvider } from '@modeso/modeso-lib-core-fe';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements IAuthorizationProvider, ILanguageProvider {
  private static currentLanguageKey = 'currentLanguage';
  private static userTokenKey = 'UID';
  private static selectedDeliveryOption = 'selectedDeliveryOption';
  private static selectedDeliveryOptionIndex = 'selectedDeliveryOptionIndex';
  private serviceSideStorage = new ServerSideStorage();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  /**
   * get current language from localStorage
   */
  public getCurrentLanguage(): string {
    const currentLanguage = this.getLocalStorage().getItem(
      LocalStorageService.currentLanguageKey
    );
    return currentLanguage ? currentLanguage : environment.defaultLanguage;
  }

  /**
   * set current language to localStorage
   */
  public setCurrentLanguage(lang: string) {
    this.getLocalStorage().setItem(
      LocalStorageService.currentLanguageKey, lang
    );
  }

  /**
   * check if current language is saved in localStorage
   */
  public isSetCurrentLanguage(): boolean {
    const currentLanguage = this.getLocalStorage().getItem(
      LocalStorageService.currentLanguageKey
    );
    return currentLanguage ? true : false;
  }

  public getLocalStorage(): Storage {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage;
    } else {
      return this.serviceSideStorage;
    }
  }



  public setSelectedDeliveryOption(selectedDeliveryOption: string) {
    this.getLocalStorage().setItem(LocalStorageService.selectedDeliveryOption, selectedDeliveryOption);
  }

  public getSelectedDeliveryOption(): string {
    return this.getLocalStorage().getItem(LocalStorageService.selectedDeliveryOption);
  }

  public setSelectedDeliveryOptionIndex(selectedDeliveryOptionIndex: string) {
    this.getLocalStorage().setItem(LocalStorageService.selectedDeliveryOptionIndex, selectedDeliveryOptionIndex)
  }

  public getSelectedDeliveryOptionIndex(): string {
    return this.getLocalStorage().getItem(LocalStorageService.selectedDeliveryOptionIndex);
  }
}
