import { Pipe, PipeTransform } from '@angular/core';
import * as fromReducers from './../reducers/localization.reducer';
import { LocalizationModel } from './../models/localization.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizePipe');

@Pipe({ name: 'localize' })
export class LocalizePipe implements PipeTransform {

  constructor(private store: Store<fromReducers.AppState>) {
    debug('LocalizePipe constructed');
  }

  transform(key: string): Observable<string> {
    return this.store.pipe(
      select(fromReducers.selectEntitiesByID, { id: key }),
      map((localization: LocalizationModel) => {
        if (localization) {
          return localization.text;
        } else {
          // TODO this must return the undefined
          // key in case we are in development mode.
          // TODO In production it should always
          // return "" if the key is not defined.
          // or return a default value if possible
          // return 'UNDEFINED KEY: ' + key;
          return undefined;
        }
      }),
      filter((text: string) => text !== undefined));
  }
}
