import { Product } from './product.model';
import { DeliveryOption } from './DeliveryOption.model';
import Debug from 'debug';
const debug = Debug('modeso:lidl-lib-order-fe:Cart');

export class Cart {
  _id?: string;
  products: Product[];
  deliveryOption: DeliveryOption;

  constructor() { }

  setCartModel(cart: any) {
    this._id = cart._id;
    this.products = [];
    cart.products.forEach(product => {
      const productObj = new Product();
      productObj.setProductModel(product);
      this.products.push(productObj);
    });
    if (cart.deliveryOption) {
      this.deliveryOption = new DeliveryOption();
      this.deliveryOption.setDeliveryOptionModel(cart.deliveryOption);
    }
  }

  validateCartModel() {
    this.products.forEach(product => {
      if (!product.validateProductModel()) {
        return false;
      }
    });
    debug('cart deliveryOption:');
    debug(this.deliveryOption);
    //TODO validate delivery option in order
    //return (this.deliveryOption && this.deliveryOption.validateDeliveryOptionModel() && this.products.length >= 1) ? true : false ;
    return (this.products.length >= 1) ? true : false;
  }
}
