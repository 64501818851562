import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './../actions/content.actions';
import { ContentModel } from '../models/content.model';

export const featureKey = 'modesoContentMicroservice';

// State Declarations - START
export const adapter: EntityAdapter<ContentModel> = createEntityAdapter<ContentModel>({
    selectId: selectContentId,
    sortComparer: sortById
});

export function selectContentId(a: ContentModel): string {
    return a.contentId;
}

export function sortById(a: ContentModel, b: ContentModel): number {
    return a.contentId.localeCompare(b.contentId);
}

export interface FeatureState extends EntityState<ContentModel> {

}

export interface AppState {
    modesoContentMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoContentMicroservice;
export const selectFeatureContent = createSelector(
    selectFeature,
    (state: FeatureState) => state
);


export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

export const selectContentEntities = createSelector(
    selectFeatureContent,
    selectEntities
);

export const selectContentAllEntities = createSelector(
    selectFeatureContent,
    selectAll
);

export const selectEntitiesByID = createSelector(
    selectContentEntities,
    (entities, props) => {
        return entities[props.id];
    }
);

export const selectEntitiesByFilter = createSelector(
    selectContentAllEntities,
    (entities, props) => {
        return entities.filter(
            content => content.collection === props.filter
        );
    }
);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = adapter.getInitialState();

const contentReducer = createReducer(
    initialState,
    on(fromActions.getAllContent, state => ({ ...state})),
    on(fromActions.onAllContentLoadedSuccessfully, (state, action) => {
        return adapter.addMany(action.payload, {...state});
    }
    ),
    on(fromActions.onAllContentLoadingFailed, state => ({ ...state})),
    on(fromActions.getContentById, state => ({ ...state})),
    on(fromActions.onContentByIdLoadedSuccessfully, (state, action) => {
            return adapter.addOne(action.payload, {...state});
        }
    ),
    on(fromActions.onContentByIdLoadingFailed, state => ({ ...state})),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return contentReducer(state, action);
}
// Reducer Declarations - END


