import { Component, Input } from '@angular/core';

@Component({
  selector: 'modeso-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() titleText;

  constructor() { }

}
