import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cart-total-option',
  templateUrl: './cart-total.component.html',
  styleUrls: ['./cart-total.component.scss']
})
export class CartTotalComponent {

  @Input() cartTotalPrice: number;
  @Input() additionalCharges?: number;

  constructor() { }

  getTotalPrice() {
    if (this.additionalCharges === undefined || this.additionalCharges === null) {
      return +this.cartTotalPrice;
    } else {
      return +this.cartTotalPrice + +this.additionalCharges;
    }

  }
}
