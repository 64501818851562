import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modeso-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {
  @Input() errorMessage;

  constructor() { }

  ngOnInit() {
  }

}
