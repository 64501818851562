/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-detail.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "../../../../node_modules/@modeso/giftcardshop-lib-products-fe/modeso-giftcardshop-lib-products-fe.ngfactory";
import * as i5 from "@modeso/giftcardshop-lib-products-fe";
import * as i6 from "@angular/common";
import * as i7 from "../../../../node_modules/@modeso/giftcardshop-lib-cart-fe/modeso-giftcardshop-lib-cart-fe.ngfactory";
import * as i8 from "@modeso/giftcardshop-lib-cart-fe";
import * as i9 from "./product-detail.page";
import * as i10 from "@angular/router";
import * as i11 from "../../state/app.state.dispatcher";
import * as i12 from "../../orchestrator/cart.orchestrator";
var styles_ProductDetailPage = [i0.styles];
var RenderType_ProductDetailPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductDetailPage, data: {} });
export { RenderType_ProductDetailPage as RenderType_ProductDetailPage };
export function View_ProductDetailPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store]), (_l()(), i1.ɵeld(1, 0, null, null, 18, "modeso-product-detail", [], null, null, null, i4.View_ProductDetailComponent_0, i4.RenderType_ProductDetailComponent)), i1.ɵdid(2, 245760, null, 0, i5.ProductDetailComponent, [i3.Store], { productId: [0, "productId"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "lidl-lib-cart-fe-add-to-cart", [["add-to-cart", ""]], null, [[null, "productNotificationEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("productNotificationEvent" === en)) {
        var pd_0 = (_co.handleProductNotificationEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ɵa_0, i7.RenderType_ɵa)), i1.ɵdid(5, 49152, null, 0, i8.ɵa, [i3.Store, i8.ɵf], { caption: [0, "caption"], product: [1, "product"] }, { productNotificationEvent: "productNotificationEvent" }), i1.ɵppd(6, 1), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 1, 11, "div", [["class", "col-lg-12 notification-message"], ["notification-message", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "display-success": 0, "display-error": 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", ""])), i1.ɵppd(14, 1), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToCart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵppd(18, 1), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.productId$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "modeso_cart_add-to-cart-button_caption")))); var currVal_2 = _co.cartProduct; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "col-lg-12 notification-message"; var currVal_4 = _ck(_v, 11, 0, (!_co.hasError && _co.showNotification), (_co.hasError && _co.showNotification)); _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 15).transform(i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), _co.notificationMessage)))); _ck(_v, 13, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 19).transform(i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v, 0), "modeso_cart_added-to-cart_go-to-cart")))); _ck(_v, 17, 0, currVal_6); }); }
export function View_ProductDetailPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-product-detail", [], null, null, null, View_ProductDetailPage_0, RenderType_ProductDetailPage)), i1.ɵdid(1, 245760, null, 0, i9.ProductDetailPage, [i10.ActivatedRoute, i1.Injector, i10.Router, i11.AppStateDispatcher, i12.CartOrchestrator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductDetailPageNgFactory = i1.ɵccf("app-page-product-detail", i9.ProductDetailPage, View_ProductDetailPage_Host_0, {}, {}, []);
export { ProductDetailPageNgFactory as ProductDetailPageNgFactory };
