import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaymentService } from '../services/payment.service';
import * as paymentActions from './../actions/payment.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { PaymentStatusModel } from '../models/paymentStatus.model';
import { HttpErrorResponse } from '@angular/common/http';
const debug = Debug('modeso:lidl-lib-payment-fe:PaymentEffects');

@Injectable()
export class PaymentEffects {

  constructor(private actions$: Actions, private service: PaymentService) { }

  loadAllPaymentOptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.getAllPaymentOptions.type),
      mergeMap(
        () => {
          return this.service.getAllPaymentOptions()
            .pipe(
              retry(1),
              map(
                paymentResponse => (
                  paymentActions.onAllPaymentOptionsLoadedSuccessfully({ payload: paymentResponse.paymentOptions })
                )
              ),
              catchError((error) => of(paymentActions.onAllPaymentOptionsLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  getCurrentPaymentStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.getPaymentStatus.type),
      mergeMap(
        payload => {
          return this.service.getPaymentStatus(payload)
            .pipe(
              map(
                paymentStatusResponse => (
                  paymentActions.onGetPaymentStatusSuccess({ payload: paymentStatusResponse })
                )
              ),
              catchError((error) => {
                const errorWithStatus = new PaymentStatusModel();
                errorWithStatus.message = error.error.message;
                errorWithStatus.validPayment = error.error.validPayment;
                errorWithStatus.paymentProcessStatus = error.paymentProcessStatus;
                return of(paymentActions.onGetPaymentStatusReject({ payload: errorWithStatus }));
              })
            );
        }
      )
    )
  );

  initializePayment$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.intializePayment.type),
      mergeMap(
        payload => {
          return this.service.initializePayment({ payload })
            .pipe(
              map(
                paymentResponse => (
                  paymentActions.onIntializePaymentSuccessfully({ payload: paymentResponse })
                )
              ),
              catchError((error) => of(paymentActions.onIntializePaymentFailed({ payload: error })))
            );
        }
      )
    )
  );

  getPaymentProcessStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.getPaymentProcessStatus.type),
      mergeMap(
        (payload: any) => {
          return this.service.getPaymentProcessStatus(payload.orderId)
            .pipe(
              map(
                paymentProcessStatusResponse => (
                  paymentActions.onGetPaymentProcessStatusSuccessful({ payload: paymentProcessStatusResponse })
                )
              ),
              catchError((error: HttpErrorResponse) => of(paymentActions.onGetPaymentProcessStatusFailed({ payload: error })))
            );
        }
      )
    )
  );


  errorOnGetPaymentProcessStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.onGetPaymentProcessStatusFailed.type),
      tap(
        (action: paymentActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllOptionsErrors(action.payload)
      )
    )
    , { dispatch: false });

  errorOnInitalizePayment$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.onIntializePaymentFailed.type),
      tap(
        (action: paymentActions.ActionWithPayload<any>) => this.handleOnLoadAllOptionsErrors(action.payload)
      )
    )
    , { dispatch: false });

  errorOnLoadAllProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.onAllPaymentOptionsLoadingFailed.type),
      tap(
        (action: paymentActions.ActionWithPayload<any>) => this.handleOnLoadAllOptionsErrors(action.payload)
      )
    )
    , { dispatch: false });

  errorOnGetPaymentStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentActions.onGetPaymentStatusReject.type),
      tap(
        (action: paymentActions.ActionWithPayload<any>) => this.handleOnLoadAllOptionsErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllOptionsErrors(error) {
    debug(error);
    return error;
  }
}
