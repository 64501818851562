import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaymentConfigService } from '../payment.config';
import { PaymentOpionModel } from './../models/payment-option.model';
import Debug from 'debug';
import { InitializePaymentResponseModel } from '../models/payment-initialization-response.model';
import { InitializePaymentModel } from '../models/initialize-payment-model';
import { PaymentProcessStatusResponse } from '../models/payment-process-status-response';
import { IPaymentStatusResponse } from '../models/IPaymentStatusResponse';
const debug = Debug('modeso:lidl-lib-payment-fe:PaymentService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface PaymentOptionsResponse {
  paymentOptions: Array<PaymentOpionModel>;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  private server = '';
  private microserviceName = 'payment';
  private readonly paymentsRoute = '/';
  // private readonly singleProductRoute = ':id';

  constructor(private http: HttpClient, @Inject(PaymentConfigService) private config) {
    this.server = config.apiGateway;
    debug('PaymentServiceId: ' + config.serviceId);
  }

  getAllPaymentOptions() {
    debug('get all Payment options called');
    const url = `${this.server}${this.microserviceName}${this.paymentsRoute}`;
    return this.http.get<PaymentOptionsResponse>(url);
  }

  getPaymentStatus(payload: any) {
    debug('get payment status function called');
    const orderId = payload.orderId;
    const url = `${this.server}${this.microserviceName}${this.paymentsRoute}${orderId}/status`;
    return this.http.get<IPaymentStatusResponse>(url);
  }

  initializePayment(payment: { payload: InitializePaymentModel }) {
    debug('Initialize payment');
    const url = `${this.server}${this.microserviceName}${this.paymentsRoute}`;
    return this.http.post<InitializePaymentResponseModel>(url, payment.payload);
  }

  getPaymentProcessStatus(orderId: string) {
    debug('get payment process status');
    const url = `${this.server}${this.microserviceName}${this.paymentsRoute}${orderId}`;
    return this.http.get<PaymentProcessStatusResponse>(url);
  }
}

