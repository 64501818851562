

export class Product {
  title: string;
  description: string;
  image: string;
  productId: string;
  price: number;
  amount: number;
  packagePrice: number;
  isPackagingSelected: boolean;
  category: string;
  ean: string;

  constructor() { }

  setProductModel(product: any) {
    this.title = product.title;
    this.description = product.description;
    this.productId = product.productId;
    this.price = product.price;
    this.amount = product.amount;
    this.packagePrice = product.packagePrice;
    this.isPackagingSelected = product.isPackagingSelected;
    this.image = product.image;
    this.category = product.category;
    this.ean = product.ean;
  }

  validateProductModel() {
    return (this.title && this.amount && this.description &&
      this.productId && this.price && this.packagePrice &&
      this.category && this.ean);
  }
}

