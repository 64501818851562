import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModesoAuthConfigService } from '../Auth.config';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private server = '';
  private microserviceName = 'auth';
  constructor(
    private http: HttpClient,
    @Inject(ModesoAuthConfigService) private config,
  ) {
    this.server = config.apiGateway || 'http://localhost:3100/api';
  }

  getNewSessionToken() {
    const url = `${this.server}${this.microserviceName}/initializeAanonymousUserSession`;
    return this.http.post(url, null);
  }

  getNewAccessToken() {
    const url = `${this.server}${this.microserviceName}/refreshAanonymousUserToken`;
    return this.http.post(url, null);
  }
}
