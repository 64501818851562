import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { Router } from '@angular/router';
import { Cart } from '@modeso/giftcardshop-lib-cart-fe';
import * as env from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions = new Array<Subscription>();
  public availableLanguages = env.environment.availableLanguages;
  carts$: Observable<Cart>;
  itemsCounter: number;
  openMenu=false;

  constructor(private appStateDispatcher: AppStateDispatcher, private router: Router) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('cartId')) {
      this.appStateDispatcher.loadCart();
    }
    this.carts$ = this.appStateDispatcher.getCart$();
    this.subscriptions.push(this.carts$.subscribe((cart: Cart) => {
      if (cart) {
        this.itemsCounter = 0;
        cart.products.forEach((product) => this.itemsCounter += product.amount);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onCartIconClick() {
    this.openMenu = false;
    this.router.navigateByUrl(`/${this.getCurrentLanguage()}/cart/${localStorage.getItem('cartId')}`);
  }

  toggleNavigationDrawer() {
    this.openMenu = !this.openMenu;
  }

  getCurrentLanguage() {
    return localStorage.getItem('currentLanguage')
  }

  navigate(urlAdded: string) {
    this.router.navigateByUrl(`/${localStorage.getItem('currentLanguage')}${urlAdded}`);
  }

}
