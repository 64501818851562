<div id="shop-wrapper" [ngClass]="{'intro-background' : visible}">
  <app-header></app-header>
  <app-header-banner *ngIf="visible"></app-header-banner>
  <app-breadcrumb id="breadcrumb-header"></app-breadcrumb>
  <div class="container my-5">
    <app-content-area></app-content-area>
  </div>
  <app-breadcrumb id="breadcrumb-footer"></app-breadcrumb>
  <app-cookie-banner *ngIf="showCookie"></app-cookie-banner>
  <app-footer></app-footer>
</div>
