import { Injectable } from '@angular/core';
import * as fromReducer from './../../shared/reducers/breadcrumb.reducer';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbStoreProvider {

    constructor(public store: Store<fromReducer.AppState>) {}

}
